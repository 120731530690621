import { SearchParameters } from '../requests/ListingRequests';
import { Region } from '../locations.ts';
import { Company } from './CompanyState';

export enum ListingStatus {
  DRAFT = 0,
  PENDING_APPROVAL = 100,
  APPROVED = 200,
  REJECTED = 300,
  ACTIVE = 400,
  INACTIVE = 500,
  DELETED = 600,
}

export interface Listing {
  id: string;
  companyId: string;
  company: Company;
  name: string;
  shortDescription: string;
  description: string;
  categoryId: string;
  subCategoryId: string;
  categoryServiceIds: string[];
  subCategoryServiceIds: string[];
  price: number;
  cardImageUri: string;
  bannerImageUri: string;
  galleryImageIds: string[];
  videoLinks: string[];
  createdAt: string;
  updatedAt: string;
  status: ListingStatus;
  locations: Region[];
}

export interface ListingsState {
  listings: Listing[];
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
  currentListing: Listing | null;
  searchParams: SearchParameters;
}

// Helper function to get status text
export function getListingStatusText(status: ListingStatus): string {
  switch (status) {
    case ListingStatus.DRAFT:
      return 'Draft';
    case ListingStatus.PENDING_APPROVAL:
      return 'Pending Approval';
    case ListingStatus.APPROVED:
      return 'Approved';
    case ListingStatus.REJECTED:
      return 'Rejected';
    default:
      return 'Unknown';
  }
}
