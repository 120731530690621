import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorMessageProps {
  message: string;
  onRetry?: () => void;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, onRetry }) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        width: '100%',
        p: 3,
        textAlign: 'center'
      }}
    >
      <ErrorOutlineIcon 
        sx={{ 
          fontSize: 60, 
          color: 'error.main', 
          mb: 2 
        }} 
      />
      <Typography 
        variant="h6" 
        component="p" 
        color="error" 
        sx={{ mb: 2 }}
      >
        {message}
      </Typography>
      {onRetry && (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onRetry}
          sx={{ 
            mt: 2,
            backgroundColor: 'rgba(212, 175, 55, 0.8)',
            color: 'black',
            '&:hover': {
              backgroundColor: '#D4AF37',
            },
          }}
        >
          Try Again
        </Button>
      )}
    </Box>
  );
};

export default ErrorMessage;
