import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCompany,
  fetchCompanyById,
  selectCurrentCompany,
  selectStylePreference,
  updateCompany,
} from '../../../../store/slices/companySlice';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../store/store';
import { useSnackbar } from 'notistack';
import { ImageCropInput } from '../../../../components/inputs/ImageCropInput';

interface CompanyFormProps {
  initialData?: {
    id?: string;
    name?: string;
    shortDescription?: string;
    description?: string;
    logoImageUri?: string;
  };
  mode: 'create' | 'edit';
}

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    '& fieldset': {
      borderColor: 'rgba(212, 175, 55, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(212, 175, 55, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D4AF37',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-focused': {
      color: '#D4AF37',
    },
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: 1,
    },
  },
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: 'rgba(212, 175, 55, 0.1)',
  color: '#D4AF37',
  border: '1px solid rgba(212, 175, 55, 0.3)',
  '&:hover': {
    backgroundColor: 'rgba(212, 175, 55, 0.2)',
    borderColor: '#D4AF37',
  },
}));

interface CompanyFormDataType {
  name: string;
  shortDescription: string;
  description: string;
  logoImageUri: string;
  organizationNumber?: number;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  youtubeUrl?: string;
  phoneNumber?: string;
}

export const CompanyForm = ({
  mode,
}: Omit<CompanyFormProps, 'initialData'>) => {
  // ** Existing logic and setup remains the same **
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const styleVariant = useSelector(selectStylePreference);
  const currentCompany = useSelector(selectCurrentCompany);

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const companyId = searchParams.get('companyId');
  const [formData, setFormData] = useState<CompanyFormDataType>({
    name: '',
    shortDescription: '',
    description: '',
    logoImageUri: '',
    organizationNumber: undefined,
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    youtubeUrl: '',
    phoneNumber: undefined,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === 'create') {
      setFormData({
        name: '',
        shortDescription: '',
        description: '',
        logoImageUri: '',
        organizationNumber: undefined,
        websiteUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        youtubeUrl: '',
        phoneNumber: undefined,
      });
      return;
    }

    if (companyId) {
      if (!currentCompany || currentCompany.id !== companyId) {
        dispatch(fetchCompanyById(companyId));
      }
    }
  }, [mode, companyId, dispatch, currentCompany]);

  useEffect(() => {
    if (mode === 'edit' && currentCompany && currentCompany.id === companyId) {
      setFormData({
        name: currentCompany.name || '',
        shortDescription: currentCompany.shortDescription || '',
        description: currentCompany.description || '',
        logoImageUri: currentCompany.logoImageUri || '',
        organizationNumber: currentCompany.organizationNumber || undefined,
        websiteUrl: currentCompany.websiteUrl || '',
        facebookUrl: currentCompany.facebookUrl || '',
        instagramUrl: currentCompany.instagramUrl || '',
        twitterUrl: currentCompany.twitterUrl || '',
        youtubeUrl: currentCompany.youtubeUrl || '',
        phoneNumber: currentCompany.phoneNumber || undefined,
      });
    }
  }, [currentCompany, mode, companyId]);

  const isValidUrl = (url: string) => {
    try {
      return new URL(url).protocol.startsWith('http');
    } catch {
      return false;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOrganizationNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Only update if the value is empty or a valid number
    if (value === '' || /^\d+$/.test(value)) {
      setFormData((prev) => ({ ...prev, organizationNumber: value === '' ? undefined : Number(value) }));
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Allow empty values, digits, spaces, +, -, and parentheses
    if (value === '' || /^[0-9+\-\s()]*$/.test(value)) {
      setFormData((prev) => ({ ...prev, phoneNumber: value }));
    }
  };

  const handleImageChange = (newImageId: string | null) => {
    setFormData((prev) => ({
      ...prev,
      logoImageUri: newImageId || '',
    }));
  };

  const handleCancel = () => {
    setFormData({
      name: '',
      shortDescription: '',
      description: '',
      logoImageUri: '',
      organizationNumber: undefined,
      websiteUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      youtubeUrl: '',
      phoneNumber: undefined,
    });
    navigate('/companies');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const urlFields = [
      'websiteUrl',
      'facebookUrl',
      'instagramUrl',
      'twitterUrl',
      'youtubeUrl',
    ];

    for (const field of urlFields) {
      const url = formData[field as keyof CompanyFormDataType] as string;
      if (url && !isValidUrl(url)) {
        enqueueSnackbar(
          `${field.replace('Url', '').toUpperCase()} is not a valid URL`,
          { variant: 'error' }
        );
        setLoading(false);
        return;
      }
    }

    try {
      if (mode === 'create') {
        await dispatch(createCompany(formData)).unwrap();
        enqueueSnackbar('Company created successfully!', {
          variant: 'success',
        });
      } else {
        await dispatch(
          updateCompany({ ...formData, id: currentCompany?.id })
        ).unwrap();
        enqueueSnackbar('Company updated successfully!', {
          variant: 'success',
        });
      }
      navigate('/companies');
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to save company', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(18, 18, 18, 0.95)',
        padding: '2rem',
        borderRadius: '16px',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: '#D4AF37', textAlign: 'center', fontWeight: 500 }}
        >
          {mode === 'create' ? 'Create New Company' : 'Edit Company Details'}
        </Typography>

        {/* Logo */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <ImageCropInput
            initialImageId={formData.logoImageUri || ''}
            onImageChange={handleImageChange}
            aspectRatio={16 / 9}
          />
        </Box>

        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <StyledTextField
              required
              fullWidth
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              required
              fullWidth
              label="Short Description"
              name="shortDescription"
              value={formData.shortDescription}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              type="number"
              label="Organization Number"
              name="organizationNumber"
              value={formData.organizationNumber || ''}
              onChange={handleOrganizationNumberChange}
              inputProps={{ 
                pattern: '[0-9]*',
                inputMode: 'numeric'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              label="Website URL"
              name="websiteUrl"
              value={formData.websiteUrl}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Social Links */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: '#D4AF37' }}>
              Social Links
            </Typography>
          </Grid>
          {['facebookUrl', 'instagramUrl', 'twitterUrl', 'youtubeUrl'].map(
            (field, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label={`${field.replace('Url', '')} URL`}
                  name={field}
                  value={formData[field as keyof CompanyFormDataType]}
                  onChange={handleInputChange}
                />
              </Grid>
            )
          )}

          {/* Full Description */}
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Full Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber || ''}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: { xs: 'center', sm: 'flex-end' },
            flexWrap: 'wrap',
            mt: 2,
          }}
        >
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: '#D4AF37',
              color: '#000',
              '&:hover': { backgroundColor: loading ? '#D4AF37' : '#B4941F' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : mode === 'create' ? (
              'Create Company'
            ) : (
              'Save Changes'
            )}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
