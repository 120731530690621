import {
  Box,
  Container,
  Fab,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import CategoryHeader from '../../components/header/CategoryHeader.tsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCategories,
  selectCategories,
} from '../../store/slices/categorySlice.ts';
import { useSearchParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import FilterDialog from './FilterDialog';
import FilterSidebar from './FilterSidebar';
import {
  resetSearchParams,
  searchListings,
  selectListings,
  selectSearchParams,
} from '../../store/slices/listingSlice.ts';
import { AppDispatch } from '../../store/store.ts';
import { ListingCard } from './ListingCard.tsx';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../../store/slices/subCategorySlice.ts';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../store/slices/subCategoryServiceSlice.ts';
import Grid from '@mui/material/Grid2';
import { fetchLocations } from '../../store/slices/locationSlice.ts';
import ListingsHeader from './ListingsHeader';

const DRAWER_WIDTH = 320;
const COLLAPSED_WIDTH = 56;

const ListingsPage = () => {
  const [searchParams] = useSearchParams();
  const selectedCategoryId = searchParams.get('categoryId');
  const searchQuery = searchParams.get('search') || '';
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const category =
    categories.find((category: any) => category.id === selectedCategoryId) ||
    null;
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const headerRef = useRef<HTMLDivElement>(null);

  const listings = useSelector(selectListings);
  const searchParamsState = useSelector(selectSearchParams);

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      searchListings({
        ...searchParamsState,
        pageNumber: value,
      })
    );
    // Add offset to scroll position
    if (headerRef.current) {
      const offset = 100; // Much larger offset to position it lower
      const elementPosition =
        headerRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  const handlePageSizeChange = (event: SelectChangeEvent) => {
    const newPageSize = parseInt(event.target.value);
    dispatch(
      searchListings({
        ...searchParamsState,
        pageSize: newPageSize,
        pageNumber: 1, // Reset to first page when changing page size
      })
    );
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    let sortBy = '';
    let sortDirection = '';
    let seed = null;

    switch (event.target.value) {
      case 'newest':
        sortBy = 'createdAt';
        sortDirection = 'desc';
        break;
      case 'oldest':
        sortBy = 'createdAt';
        sortDirection = 'asc';
        break;
      case 'priceAsc':
        sortBy = 'price';
        sortDirection = 'asc';
        break;
      case 'priceDesc':
        sortBy = 'price';
        sortDirection = 'desc';
        break;
      case 'nameAsc':
        sortBy = 'name';
        sortDirection = 'asc';
        break;
      case 'nameDesc':
        sortBy = 'name';
        sortDirection = 'desc';
        break;
      case 'random':
        sortBy = 'random';
        sortDirection = 'asc';
        seed = crypto.randomUUID();
        break;
      default:
        sortBy = 'createdAt';
        sortDirection = 'desc';
    }

    dispatch(
      searchListings({
        ...searchParamsState,
        sortBy,
        sortDirection,
        seed,
        pageNumber: 1,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    dispatch(fetchSubCategoryServices());
    dispatch(fetchLocations());

    // Update search parameters with URL values
    dispatch(
      searchListings({
        ...searchParamsState,
        categoryId: selectedCategoryId || null,
        searchQuery: searchQuery,
        pageNumber: 1,
      })
    );

    return () => {
      dispatch(resetSearchParams());
    };
  }, [dispatch, selectedCategoryId, searchQuery]);

  return (
    <Box sx={{ display: 'flex' }}>
      <FilterSidebar
        selectedCategoryId={selectedCategoryId}
        open={sidebarOpen}
        onToggle={handleToggleSidebar}
      />

      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          zIndex: 0,
          ml: { xs: 0, md: `${COLLAPSED_WIDTH}px` },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          ...(sidebarOpen && {
            ml: { xs: 0, md: `${DRAWER_WIDTH}px` },
          }),
        }}
      >
        {category && (
          <CategoryHeader category={category} showBackButton={true} />
        )}
        <Container maxWidth="xl">
          <Box sx={{ py: 4 }}>
            <Container maxWidth="lg" sx={{ py: 4 }}>
              <ListingsHeader
                ref={headerRef}
                totalItems={searchParamsState.totalCount || 0}
                itemsPerPage={searchParamsState.pageSize || 12}
                currentPage={searchParamsState.pageNumber || 1}
                sortBy={
                  searchParamsState.sortBy === 'random'
                    ? 'random'
                    : searchParamsState.sortBy === 'createdAt' &&
                        searchParamsState.sortDirection === 'desc'
                      ? 'newest'
                      : searchParamsState.sortBy === 'createdAt' &&
                          searchParamsState.sortDirection === 'asc'
                        ? 'oldest'
                        : searchParamsState.sortBy === 'price' &&
                            searchParamsState.sortDirection === 'asc'
                          ? 'priceAsc'
                          : searchParamsState.sortBy === 'price' &&
                              searchParamsState.sortDirection === 'desc'
                            ? 'priceDesc'
                            : searchParamsState.sortBy === 'name' &&
                                searchParamsState.sortDirection === 'asc'
                              ? 'nameAsc'
                              : searchParamsState.sortBy === 'name' &&
                                  searchParamsState.sortDirection === 'desc'
                                ? 'nameDesc'
                                : 'newest'
                }
                onPageChange={handlePageChange}
                onSortChange={handleSortChange}
                onPageSizeChange={handlePageSizeChange}
              />
              <Box>
                <Grid container spacing={1}>
                  {listings.map((listing) => (
                    <Grid size={{ xs: 12, sm: 12, md: 4 }} key={listing.id}>
                      <ListingCard
                        listing={listing}
                        categories={categories}
                        subCategories={subCategories}
                        subCategoryServices={subCategoryServices}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </Box>
        </Container>

        {/* Mobile Filter Button */}
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            bottom: { xs: 16, sm: 24 },
            right: { xs: 16, sm: 24 },
            zIndex: theme.zIndex.speedDial,
          }}
        >
          <Fab
            onClick={handleOpenFilterDialog}
            sx={{
              bgcolor: 'rgba(212, 175, 55, 0.9)',
              color: '#000',
              '&:hover': {
                bgcolor: '#D4AF37',
              },
            }}
          >
            <TuneIcon />
          </Fab>
        </Box>

        {/* Mobile Filter Dialog */}
        <FilterDialog
          open={filterDialogOpen}
          onClose={handleCloseFilterDialog}
          selectedCategoryId={selectedCategoryId}
        />
      </Box>
    </Box>
  );
};

export default ListingsPage;
