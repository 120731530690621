import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  fetchEventsByCompanyId,
  selectEventError,
  selectEvents,
  selectEventStatus,
} from '../../../../store/slices/eventSlice';
import Grid from '@mui/material/Grid';
import { Company } from '../../../../types/states/CompanyState.ts';
import EventCard from './EventCard.tsx';
import { useNavigate } from 'react-router-dom';
import { SectionHeader } from '../../../../components/dashboard/SectionHeader.tsx';

interface CompanyEventsProps {
  company: Company;
}

const CompanyEvents: FC<CompanyEventsProps> = ({ company }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const events = useSelector(selectEvents);
  const status = useSelector(selectEventStatus);
  const error = useSelector(selectEventError);

  useEffect(() => {
    if (company.id) {
      dispatch(fetchEventsByCompanyId(company.id));
    }
  }, [company.id, dispatch]);

  const handleOpenEvent = (eventId: string) => {
    navigate(`/company/event/${eventId}?companyId=${company.id}`);
  };

  if (status === 'pending') {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Typography>Loading events...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">Error loading events: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <SectionHeader title="Events that match my listings" icon={undefined} />
      <Box sx={{ p: 3 }}>
        {events.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No events currently match this company's listings.
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {events.map((event) => (
              <Grid xs={12} sm={6} md={4} key={event.id}>
                <EventCard event={event} onOpenEvent={handleOpenEvent} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CompanyEvents;
