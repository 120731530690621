import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  Typography,
  Stack,
  CardContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { PlannerEvent, EventStatusEnum } from '../../../../types/event';
import { approveEvent } from '../../../../store/slices/eventSlice';

const statusDisplayMap: Record<number, { text: string; gradient: string }> = {
  [EventStatusEnum.Draft]: {
    text: 'Draft',
    gradient: 'linear-gradient(45deg, #9E9E9E 30%, #BDBDBD 90%)',
  },
  [EventStatusEnum.AwaitingApproval]: {
    text: 'Awaiting Approval',
    gradient: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  },
  [EventStatusEnum.Approved]: {
    text: 'Approved',
    gradient: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
  },
  [EventStatusEnum.Rejected]: {
    text: 'Rejected',
    gradient: 'linear-gradient(45deg, #EF5350 30%, #E57373 90%)',
  },
  [EventStatusEnum.Completed]: {
    text: 'Completed',
    gradient: 'linear-gradient(45deg, #2196F3 30%, #64B5F6 90%)',
  },
  [EventStatusEnum.Cancelled]: {
    text: 'Cancelled',
    gradient: 'linear-gradient(45deg, #9C27B0 30%, #BA68C8 90%)',
  },
};

export interface EventCardProps {
  event: PlannerEvent;
  index: number;
}

export const EventApprovalsCard: React.FC<EventCardProps> = ({ event, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleView = () => {
    navigate(`/planner/event?id=${event.id}`);
  };

  const handleApprove = async () => {
    try {
      await dispatch(approveEvent({ id: event.id, approved: true }));
    } catch (error) {
      console.error('Failed to approve event:', error);
    }
  };

  const handleReject = async () => {
    try {
      await dispatch(approveEvent({ id: event.id, approved: false }));
    } catch (error) {
      console.error('Failed to reject event:', error);
    }
  };

  return (
    <Card
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        maxWidth: '320px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'rgba(0, 0, 0, 0.6)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        border: '2px solid rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        animation: `fadeSlideIn 0.6s ease-out ${index * 0.1}s both`,
        '@keyframes fadeSlideIn': {
          from: {
            opacity: 0,
            transform: 'translateY(20px)',
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
        '&:hover': {
          transform: 'translateY(-8px)',
          borderColor: '#D4AF37',
          boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
        },
      }}
    >
      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: '#D4AF37',
                fontWeight: 600,
                mb: 1,
              }}
            >
              {event.eventConfig.eventName}
            </Typography>
            <Chip
              label={statusDisplayMap[event.status]?.text || 'Unknown'}
              sx={{
                background: statusDisplayMap[event.status]?.gradient,
                color: '#fff',
                fontWeight: 500,
              }}
            />
          </Box>

          <Stack spacing={1}>
            <Typography
              variant="body2"
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              Type: {event.eventType}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              Attendees: {event.eventConfig.numberOfAttendees}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              Date: {new Date(event.eventConfig.startDate).toLocaleDateString()}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>

      <CardActions sx={{ p: 2, pt: 0, gap: 1 }}>
        <Button
          size="small"
          color="primary"
          onClick={() => navigate(`/admin/approvals/events/${event.id}?id=${event.id}`)}
        >
          Review Event
        </Button>
      </CardActions>
    </Card>
  );
};
