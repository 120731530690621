import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LocationState } from '../../types/locations.ts';
import axiosInstance from '../../utilis/axios.ts';
import { apiUrl } from '../../main';
import {
  CreateRegionRequest,
  UpdateRegionRequest,
} from '../../types/requests/locations';

const initialState: LocationState = {
  regions: [],
  status: 'idle',
};

// Async thunk for fetching locations
export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/locations`);
    return response.data;
  }
);

// Async thunk for creating a region
export const createRegion = createAsyncThunk(
  'locations/createRegion',
  async (request: CreateRegionRequest) => {
    const response = await axiosInstance.post(`${apiUrl}/locations`, request);
    return response.data;
  }
);

// Async thunk for updating a region
export const updateRegion = createAsyncThunk(
  'locations/updateRegion',
  async ({ id, request }: { id: string; request: UpdateRegionRequest }) => {
    const response = await axiosInstance.put(
      `${apiUrl}/locations/${id}`,
      request
    );
    return response.data;
  }
);

// Async thunk for deleting a region
export const deleteRegion = createAsyncThunk(
  'locations/deleteRegion',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/locations/${id}`);
    return id;
  }
);

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearLocationState: (state) => {
      state.regions = [];
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch locations
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.regions = action.payload || [];
      })
      .addCase(fetchLocations.rejected, (state) => {
        state.status = 'rejected';
        state.regions = [];
      })
      // Create region
      .addCase(createRegion.fulfilled, (state, action) => {
        state.regions.push(action.payload);
      })
      // Update region
      .addCase(updateRegion.fulfilled, (state, action) => {
        const index = state.regions.findIndex(
          (r) => r.id === action.payload.id
        );
        if (index !== -1) {
          state.regions[index] = action.payload;
        }
      })
      // Delete region
      .addCase(deleteRegion.fulfilled, (state, action) => {
        // action.payload contains the ID with 'regions/' prefix, so we need to find the region with that prefix
        state.regions = state.regions.filter(
          (r) => r.id !== `regions/${action.payload}`
        );
      });
  },
});

export const { clearLocationState } = locationSlice.actions;

export const selectLocations = (state: { locations: LocationState }) =>
  state.locations;

export const selectRegions = (state: { locations: LocationState }) =>
  state.locations.regions || [];

export default locationSlice.reducer;
