import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './context/ThemeContext';

import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/ErrorPages/NotFound';
import Forbidden from './pages/ErrorPages/Forbidden';
import Error from './pages/ErrorPages/Error';

import Layout from './components/layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import AdminCenter from './pages/AdminCenter';
import AdminCategories from './pages/AdminCenter/AdminCategories';
import RegisterPage from './pages/RegisterPage';
import CompaniesPage from './pages/CompanyPage';
import CompanyDetails from './pages/CompanyPage/CompanyDetails';
import AdminCategoryServices from './pages/AdminCenter/AdminCategoryServices';
import AdminSubCategories from './pages/AdminCenter/AdminSubCategories';
import AdminSubCategoryServices from './pages/AdminCenter/AdminSubCategoryServices';
import ProductsPage from './pages/Products/ProductsPage';
import CompanyListingPage from './pages/CompanyPage/CompanyListings/CompanyListingPage';
import ListingsPage from './pages/ListingsPage';
import ListingItemPage from './pages/ListingItemPage';
import { CheckoutConfirmation } from './pages/CompanyPage/CompanyDetails/components/CheckoutConfirmation.tsx';
import CompanyApprovals from './pages/AdminCenter/Approvals/Companies';
import CompanyOverviewPage from './pages/CompanyOverviewPage';
import ListingApprovals from './pages/AdminCenter/Approvals/Listings';
import PlannerEventPage from './pages/planner/event';
import PlannerPage from './pages/planner';
import MessagesPage from './pages/MessagesPage';
import AdminRegions from './pages/AdminCenter/AdminRegions';
import SearchPage from './pages/SearchPage';
import EventApprovalsPage from './pages/AdminCenter/Approvals/Events';
import EventApprovalPage from './pages/AdminCenter/Approvals/Events/EventApprovalPage';
import CompanyEventPage from './pages/CompanyPage/CompanyEvent/CompanyEventPage';
import FavoritesPage from './pages/FavoritesPage';
import CompanyStatisticsPage from './pages/CompanyStatisticsPage';

function App() {
  return (
    <ThemeProvider>
      <Routes>
        {/* Layout Route */}
        <Route path="/" element={<Layout />}>
          {/* Index Route */}
          <Route index element={<HomePage />} />

          <Route path="/listings/*" element={<ListingsPage />} />
          <Route path="/listing/:id" element={<ListingItemPage />} />
          <Route path="/search" element={<SearchPage />} />

          {/* Auth Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          {/* Protected Company Routes */}
          <Route
            path="/messages"
            element={
              <ProtectedRoute requireAuth>
                <MessagesPage />
              </ProtectedRoute>
            }
          />
          {/* Favorites Route */}
          <Route
            path="/favorites"
            element={
              <ProtectedRoute requireAuth>
                <FavoritesPage />
              </ProtectedRoute>
            }
          />
          {/* Protected Company Routes */}
          <Route
            path="/planner"
            element={
              <ProtectedRoute requireAuth>
                <PlannerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/planner/event/"
            element={
              <ProtectedRoute requireAuth>
                <PlannerEventPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/companies"
            element={
              <ProtectedRoute requireAuth>
                <CompaniesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company/*"
            element={
              <ProtectedRoute requireAuth>
                <CompanyDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company/listing/*"
            element={
              <ProtectedRoute requireAuth>
                <CompanyListingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company/order-complete/*"
            element={
              <ProtectedRoute requireAuth>
                <CheckoutConfirmation />
              </ProtectedRoute>
            }
          />
          <Route path="/company-overview/*" element={<CompanyOverviewPage />} />
          <Route
            path="/company/event/:eventId"
            element={<CompanyEventPage />}
          />
          <Route path="/event/:eventId" element={<CompanyEventPage />} />
          <Route path="/company-statistics" element={<CompanyStatisticsPage />} />

          {/* Protected Admin Routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminCenter />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/regions"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminRegions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/approvals/companies"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <CompanyApprovals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/approvals/listings"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <ListingApprovals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/categories"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminCategories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/subcategories"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminSubCategories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/category-services"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminCategoryServices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/subcategory-services"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <AdminSubCategoryServices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/products"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <ProductsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/approvals/events"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <EventApprovalsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/approvals/events/:id"
            element={
              <ProtectedRoute requireAuth requireAdmin>
                <EventApprovalPage />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* Error Routes */}
        <Route path="/403" element={<Forbidden />} />
        <Route path="/500" element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
