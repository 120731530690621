import { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import { useSelector } from 'react-redux';
import { selectSubCategories } from '../../store/slices/subCategorySlice';

export const FilterIcon = <CategoryIcon />;

interface SubcategoryFilterSectionProps {
  selectedCategoryId: string | null;
  selectedSubcategories: string[];
  onSubcategorySelect: (subcategoryId: string) => void;
  isCollapsed?: boolean;
}

const SubcategoryFilterSection: FC<SubcategoryFilterSectionProps> = ({
  selectedCategoryId,
  selectedSubcategories,
  onSubcategorySelect,
  isCollapsed = false,
}) => {
  const allSubCategories = useSelector(selectSubCategories);
  const filteredSubCategories = selectedCategoryId
    ? allSubCategories.filter((sub) => sub.categoryId === selectedCategoryId)
    : [];

  // If no category is selected or no subcategories found, don't render anything
  if (!selectedCategoryId || filteredSubCategories.length === 0) {
    return null;
  }

  if (isCollapsed) {
    return (
      <Tooltip title="Subcategories" placement="right">
        <IconButton
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            '&:hover': {
              color: '#D4AF37',
            },
          }}
        >
          <CategoryIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Accordion
        defaultExpanded={false}
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 56,
            color: '#D4AF37',
            '&.Mui-expanded': {
              minHeight: 56,
            },
          },
          '& .MuiAccordionSummary-content': {
            margin: '12px 0',
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '8px 16px 16px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#D4AF37' }} />}
          sx={{
            flexDirection: 'row',
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            },
          }}
        >
          <CategoryIcon sx={{ color: '#D4AF37' }} />
          <Typography>Subcategories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {filteredSubCategories.map((subcategory) => (
              <Chip
                key={subcategory.id}
                label={subcategory.name}
                onClick={() => onSubcategorySelect(subcategory.id)}
                variant={
                  selectedSubcategories.includes(subcategory.id)
                    ? 'filled'
                    : 'outlined'
                }
                sx={{
                  borderColor: '#D4AF37',
                  borderWidth: '1.5px',
                  color: selectedSubcategories.includes(subcategory.id)
                    ? '#000000'
                    : '#D4AF37',
                  backgroundColor: selectedSubcategories.includes(
                    subcategory.id
                  )
                    ? '#D4AF37'
                    : 'transparent',
                  height: '32px',
                  fontWeight: 500,
                  '& .MuiSvgIcon-root': {
                    color: selectedSubcategories.includes(subcategory.id)
                      ? '#000000'
                      : '#D4AF37',
                  },
                  '&:hover': {
                    backgroundColor: selectedSubcategories.includes(
                      subcategory.id
                    )
                      ? '#B49030'
                      : 'rgba(212, 175, 55, 0.08)',
                    borderColor: selectedSubcategories.includes(subcategory.id)
                      ? '#B49030'
                      : '#D4AF37',
                  },
                }}
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SubcategoryFilterSection;
