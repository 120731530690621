import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Category as CategoryIcon,
  LocationOn as LocationOnIcon,
  Notes as NotesIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PlannerEvent, EventPlannerTypeEnum } from '../../../../types/event';

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  border: '1px solid rgba(255, 215, 0, 0.3)',
  '& .MuiTypography-label': {
    color: '#FFD700',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  '& .MuiTypography-value': {
    color: '#fff',
    fontSize: '1rem',
  },
}));

interface EventOverviewStepProps {
  event: PlannerEvent;
}

const EventOverviewStep: React.FC<EventOverviewStepProps> = ({ event }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={3}>
        {/* Event Type */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  bgcolor: 'rgba(0, 0, 0, 0.8)',
                  border: '2px solid #FFD700',
                  borderRadius: 1,
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: '#FFD700', fontWeight: 600 }}
                >
                  {EventPlannerTypeEnum[event.eventType] || 'No Type Selected'}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Event Name
                </Typography>
                <Typography className="MuiTypography-value">
                  {event.eventConfig?.eventName || 'Untitled Event'}
                </Typography>
              </DetailItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Start Date
                </Typography>
                <Typography className="MuiTypography-value">
                  {event.eventConfig?.startDate
                    ? new Date(event.eventConfig.startDate).toLocaleDateString()
                    : 'No Date Set'}
                </Typography>
              </DetailItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  End Date
                </Typography>
                <Typography className="MuiTypography-value">
                  {event.eventConfig?.endDate
                    ? new Date(event.eventConfig.endDate).toLocaleDateString()
                    : 'No Date Set'}
                </Typography>
              </DetailItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Number of Attendees
                </Typography>
                <Typography className="MuiTypography-value">
                  {event.eventConfig?.numberOfAttendees?.toString() || '0'}
                </Typography>
              </DetailItem>
            </Grid>

            {event.eventConfig?.budget && (
              <Grid item xs={12} sm={6}>
                <DetailItem>
                  <Typography className="MuiTypography-label">
                    Budget
                  </Typography>
                  <Typography className="MuiTypography-value">
                    ${event.eventConfig.budget.toLocaleString()}
                  </Typography>
                </DetailItem>
              </Grid>
            )}
          </Grid>
        </Paper>

        {/* Description */}
        {event.eventConfig?.description && (
          <Paper
            elevation={0}
            sx={{
              p: 3,
              bgcolor: 'rgba(0, 0, 0, 0.9)',
              border: '1px solid rgba(255, 215, 0, 0.2)',
              borderRadius: 2,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" mb={2}>
              <NotesIcon sx={{ color: '#FFD700' }} />
              <Typography variant="h6" sx={{ color: '#FFD700' }}>
                Description
              </Typography>
            </Stack>
            <Typography sx={{ color: '#fff' }}>
              {event.eventConfig.description}
            </Typography>
          </Paper>
        )}

        {/* Locations */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <LocationOnIcon sx={{ color: '#FFD700' }} />
            <Typography variant="h6" sx={{ color: '#FFD700' }}>
              Event Locations
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            {event.eventLocations?.map((region, index) => {
              const selectedCounties = region.counties?.filter(
                (county) => county.isSelected
              );
              if (!selectedCounties?.length && !region.isSelected) return null;

              return (
                <Grid item xs={12} md={6} lg={4} key={region.id || index}>
                  <Accordion
                    defaultExpanded={false}
                    sx={{
                      backgroundColor: 'rgba(255, 215, 0, 0.05)',
                      backgroundImage: 'none',
                      boxShadow: 'none',
                      border: '1px solid rgba(255, 215, 0, 0.2)',
                      borderRadius: '4px !important',
                      '&:before': {
                        display: 'none',
                      },
                      '& .MuiAccordionSummary-root': {
                        minHeight: 40,
                        color: '#FFD700',
                        '&.Mui-expanded': {
                          minHeight: 40,
                        },
                      },
                      '& .MuiAccordionSummary-content': {
                        margin: '10px 0',
                        '&.Mui-expanded': {
                          margin: '10px 0',
                        },
                      },
                      '& .MuiAccordionDetails-root': {
                        padding: '0 16px 16px',
                      },
                      mb: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: '#FFD700' }} />}
                      sx={{
                        flexDirection: 'row',
                        '& .MuiAccordionSummary-content': {
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        },
                      }}
                    >
                      <Chip
                        icon={<LocationOnIcon />}
                        label={`${region.name} ${
                          selectedCounties.length === region.counties?.length
                            ? '(All counties)'
                            : selectedCounties?.length > 0
                              ? `(${selectedCounties.length} counties)`
                              : ''
                        }`}
                        variant="outlined"
                        sx={{
                          borderColor: '#FFD700',
                          borderWidth: '1.5px',
                          color: '#FFD700',
                          backgroundColor: 'transparent',
                          height: '32px',
                          fontWeight: 500,
                          width: '100%',
                          '& .MuiChip-icon': {
                            color: '#FFD700',
                          },
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {selectedCounties.length === region.counties?.length ? (
                        <Typography sx={{ color: '#FFD700', fontStyle: 'italic', fontSize: '0.875rem' }}>
                          All counties in this region are selected
                        </Typography>
                      ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                          {selectedCounties.map((county, cIndex) => (
                            <Chip
                              key={county.id || cIndex}
                              label={county.name}
                              sx={{
                                bgcolor: 'rgba(255, 215, 0, 0.1)',
                                border: '1px solid rgba(255, 215, 0, 0.3)',
                                color: '#fff',
                                height: '24px',
                                fontSize: '0.75rem',
                                '& .MuiChip-label': {
                                  color: '#fff',
                                },
                              }}
                            />
                          ))}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
        </Paper>

        {/* Services */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <CategoryIcon sx={{ color: '#FFD700' }} />
            <Typography variant="h6" sx={{ color: '#FFD700' }}>
              Selected Services
            </Typography>
          </Stack>

          <Stack spacing={4}>
            {Object.entries(event.selectedServices || {}).map(
              ([categoryId, categoryData]) => {
                const { subCategories, categoryServices } = categoryData;
                const hasSelectedServices = categoryServices?.some(
                  (service) => service.isSelected
                );
                const hasSelectedSubCategories = subCategories?.some(
                  (sub) => sub.isSelected || sub.services?.some((service) => service.isSelected)
                );

                if (!hasSelectedServices && !hasSelectedSubCategories)
                  return null;

                const category = event.eventCategories?.find(
                  (cat) => cat.id === categoryId
                );

                return (
                  <Box
                    key={categoryId}
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(0, 0, 0, 0.8)',
                      border: '1px solid rgba(255, 215, 0, 0.3)',
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: '#FFD700',
                        fontWeight: 600,
                        mb: 3,
                        pb: 1,
                        borderBottom: '1px solid rgba(255, 215, 0, 0.2)',
                      }}
                    >
                      {category?.name || 'Unknown Category'}
                    </Typography>

                    <Stack spacing={2}>
                      {/* Category Services */}
                      {categoryServices?.some((service) => service.isSelected) && (
                        <Box>
                          <Typography
                            sx={{
                              color: '#FFD700',
                              fontWeight: 500,
                              mb: 1,
                            }}
                          >
                            General Services
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            flexWrap="wrap"
                            sx={{ gap: 1 }}
                          >
                            {categoryServices
                              ?.filter((service) => service.isSelected)
                              .map((service, sIndex) => (
                                <Chip
                                  key={sIndex}
                                  label={service.name}
                                  sx={{
                                    bgcolor: 'rgba(255, 215, 0, 0.1)',
                                    border: '1px solid rgba(255, 215, 0, 0.3)',
                                    color: '#fff',
                                    '& .MuiChip-label': {
                                      color: '#fff',
                                    },
                                    '&:hover': {
                                      bgcolor: 'rgba(255, 215, 0, 0.2)',
                                    },
                                  }}
                                />
                              ))}
                          </Stack>
                        </Box>
                      )}

                      {/* SubCategories and their services */}
                      {subCategories?.map((subCategory) => {
                        const selectedServices = subCategory.services?.filter(
                          (service) => service.isSelected
                        );
                        if (!subCategory.isSelected && !selectedServices?.length) return null;

                        return (
                          <Box key={subCategory.id}>
                            <Typography
                              sx={{
                                color: '#FFD700',
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              {subCategory.name}
                            </Typography>
                            {selectedServices && selectedServices.length > 0 && (
                              <>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  flexWrap="wrap"
                                  sx={{ gap: 1 }}
                                >
                                  {selectedServices.map((service, sIndex) => (
                                    <Chip
                                      key={sIndex}
                                      label={service.name}
                                      sx={{
                                        bgcolor: 'rgba(255, 215, 0, 0.1)',
                                        border: '1px solid rgba(255, 215, 0, 0.3)',
                                        color: '#fff',
                                        '& .MuiChip-label': {
                                          color: '#fff',
                                        },
                                        '&:hover': {
                                          bgcolor: 'rgba(255, 215, 0, 0.2)',
                                        },
                                      }}
                                    />
                                  ))}
                                </Stack>
                                <Box sx={{ mt: 2 }}>
                                  {selectedServices.map((service, sIndex) => (
                                    <Typography key={sIndex} sx={{ color: '#fff' }}>
                                      {service.description}
                                    </Typography>
                                  ))}
                                </Box>
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                  </Box>
                );
              }
            )}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
};

export default EventOverviewStep;
