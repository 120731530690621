import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Collapse,
  useTheme,
  styled,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  fetchListing,
  selectCurrentListing,
} from '../../store/slices/listingSlice';
import { AppDispatch } from '../../store/store';
import {
  fetchCategories,
  selectCategories,
} from '../../store/slices/categorySlice';
import { selectSubCategories } from '../../store/slices/subCategorySlice';
import {
  fetchCategoryServices,
  selectCategoryServices,
} from '../../store/slices/categoryServiceSlice';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../store/slices/subCategoryServiceSlice';
import { MultiSelectChips } from '../MultiSelectChips';
import parse from 'html-react-parser';
import { ImageCropInput } from '../inputs/ImageCropInput.tsx';
import { GalleryUpload } from '../inputs/GalleryUpload';
import { GalleryView } from '../inputs/GalleryView.tsx';
import { VideoInput } from '../inputs/VideoInput';
import { VideoDisplay } from './VideoDisplay';
import { LocationFilter } from '../filters/LocationFilter.tsx';
import { LocationDisplay } from '../filters/LocationDisplay.tsx';
import {
  getListingStatusText,
  ListingStatus,
} from '../../types/states/ListingState';
import { getStatusChipStyle } from '../../utils/styles';
import { SectionHeader } from '../dashboard/SectionHeader.tsx';
import { County, Region } from '../../types/locations';
import {
  fetchLocations,
  selectRegions,
} from '../../store/slices/locationSlice';

interface ListingViewProps {
  mode: 'view' | 'edit' | 'create';
  listingId?: string;
  companyId?: string;
  onListingChange?: (listing: any) => void;
  showImageFields: boolean;
  validationErrors: {
    categoryId: string;
    subCategoryId: string;
    name: string;
    shortDescription: string;
  };
  initialValues?: any; 
  onLinkClick?: (url: string) => void; 
  onChatStart?: () => void; 
}

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ theme, status }: { theme: any; status: ListingStatus }) => 
  getStatusChipStyle(theme, status)
);

const ListingView: React.FC<ListingViewProps> = ({
  mode,
  listingId,
  companyId,
  onListingChange,
  showImageFields,
  validationErrors,
  initialValues,
  onLinkClick,
  onChatStart,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useTheme();
  const currentListing = useSelector(selectCurrentListing);
  const categories = useSelector(selectCategories);
  const categoryServices = useSelector(selectCategoryServices);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const subCategories = useSelector(selectSubCategories);
  const regions = useSelector(selectRegions);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [selectedCategoryServices, setSelectedCategoryServices] = useState<
    string[]
  >([]);
  const [selectedSubCategoryServices, setSelectedSubCategoryServices] = useState<string[]>([]);
  const [formValues, setFormValues] = useState(
    initialValues || {
      name: '',
      shortDescription: '',
      cardImageUri: '',
      bannerImageUri: '',
      galleryImageIds: [],
      videoLinks: [],
      description: '',
      categoryId: '',
      subCategoryId: '',
      categoryServiceIds: [],
      subCategoryServiceIds: [],
      locations: [],
    }
  );
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<Region[]>([]);
  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(false);

  useEffect(() => {
    if (regions?.length > 0) {
      // Get existing selected locations from formValues
      const existingLocations = formValues.locations || [];

      const initialLocations = regions.map((region) => {
        // Find if this region exists in formValues.locations
        const existingRegion = existingLocations.find(
          (loc: Region) => loc.id === region.id
        );

        return {
          ...region,
          isSelected: existingRegion?.isSelected || false,
          counties: region.counties.map((county) => {
            // Find if this county exists in the existing region
            const existingCounty = existingRegion?.counties.find(
              (c: County) => c.id === county.id
            );
            return {
              ...county,
              isSelected: existingCounty?.isSelected || false,
            };
          }),
        };
      });
      setSelectedLocations(initialLocations);
    }
  }, [regions, formValues.locations]);

  const handleLocationSelect = useCallback(
    (region: Region, county?: County) => {
      const updatedLocations = selectedLocations.map((loc) => {
        if (loc.id === region.id) {
          return {
            ...region,
            counties: region.counties,
          };
        }
        return loc;
      });

      setSelectedLocations(updatedLocations);

      const updatedValues = {
        ...formValues,
        locations: updatedLocations,
      };
      setFormValues(updatedValues);
      onListingChange?.(updatedValues);
    },
    [selectedLocations, formValues, onListingChange]
  );

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    const updatedValues = {
      ...formValues,
      categoryId,
      subCategoryId: '', // Reset subcategory when category changes
      categoryServiceIds: [], // Reset category services when category changes
      subCategoryServiceIds: [], // Reset subcategory services when category changes
    };
    setFormValues(updatedValues);
    setSelectedSubCategory(''); // Reset subcategory selection
    setSelectedCategoryServices([]); // Reset category services selection
    setSelectedSubCategoryServices([]); // Reset subcategory services selection
    onListingChange?.(updatedValues);
  };

  const handleSubCategoryChange = (event: SelectChangeEvent<string>) => {
    const subCategoryId = event.target.value;
    setSelectedSubCategory(subCategoryId);
    const updatedValues = {
      ...formValues,
      subCategoryId,
      subCategoryServiceIds: [], // Reset subcategory services when subcategory changes
    };
    setFormValues(updatedValues);
    setSelectedSubCategoryServices([]); // Reset subcategory services selection
    onListingChange?.(updatedValues);
  };

  const handleCategoryServicesChange = (selectedServices: string[]) => {
    setSelectedCategoryServices(selectedServices);
    const updatedValues = {
      ...formValues,
      categoryServiceIds: selectedServices,
    };
    setFormValues(updatedValues);
    onListingChange?.(updatedValues);
  };

  const handleSubCategoryServicesChange = (selectedServices: string[]) => {
    setSelectedSubCategoryServices(selectedServices);
    const updatedValues = {
      ...formValues,
      subCategoryServiceIds: selectedServices,
    };
    setFormValues(updatedValues);
    onListingChange?.(updatedValues);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Name change:', e.target.value);
    const value = e.target.value.slice(0, 50); // Enforce max length
    const updatedValues = {
      ...formValues,
      name: value,
    };
    setFormValues(updatedValues);
    onListingChange?.(updatedValues);
  };

  const handleShortDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.slice(0, 150); // Enforce max length
    const updatedValues = {
      ...formValues,
      shortDescription: value,
    };
    setFormValues(updatedValues);
    onListingChange?.(updatedValues);
  };

  const handleImageChange = (imageId: string) => {
    const newFormValues = {
      ...formValues,
      cardImageUri: imageId || '',
    };
    setFormValues(newFormValues);
    onListingChange?.({
      cardImageUri: imageId || '',
    });
  };

  const handleEditorChange = (value: string) => {
    console.log('Editor change:', value);
    const changedFields = {
      ...formValues,
      description: value,
    };
    setFormValues(changedFields);
    onListingChange?.(changedFields);
  };

  const locationFilterProps = useMemo(
    () => ({
      selectedLocations,
      onLocationSelect: handleLocationSelect,
    }),
    [selectedLocations, handleLocationSelect]
  );

  useEffect(() => {
    console.log('Selected Locations:', selectedLocations);
  }, [selectedLocations]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLocations());
    // if (!companyId) {
    //   navigate('/');
    //   return;
    // }

    if (mode === 'edit' && listingId) {
      dispatch(fetchListing(listingId));
    }
  }, [companyId, listingId, mode, dispatch, navigate]);

  useEffect(() => {
    if (mode === 'edit' && currentListing && categories.length > 0) {
      setSelectedCategory(currentListing.categoryId);
      setSelectedSubCategory(currentListing.subCategoryId);
      setSelectedCategoryServices(currentListing.categoryServiceIds || []);
      setSelectedSubCategoryServices(
        currentListing.subCategoryServiceIds || []
      );

      if (currentListing.categoryId) {
        dispatch(fetchCategoryServices());
      }
      if (currentListing.subCategoryId) {
        dispatch(fetchSubCategoryServices());
      }
    }
  }, [mode, currentListing, categories, dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(fetchCategoryServices());
    }
  }, [selectedCategory, dispatch]);

  useEffect(() => {
    if (selectedSubCategory) {
      dispatch(fetchSubCategoryServices());
    }
  }, [selectedSubCategory, dispatch]);

  useEffect(() => {
    if (currentListing && mode !== 'create') {
      setFormValues({
        name: currentListing.name || '',
        shortDescription: currentListing.shortDescription || '',
        cardImageUri: currentListing.cardImageUri || '',
        bannerImageUri: currentListing.bannerImageUri || '',
        galleryImageIds: currentListing.galleryImageIds || [],
        videoLinks: currentListing.videoLinks || [],
        description: currentListing.description || '',
        categoryId: currentListing.categoryId || '',
        subCategoryId: currentListing.subCategoryId || '',
        categoryServiceIds: currentListing.categoryServiceIds || [],
        subCategoryServiceIds: currentListing.subCategoryServiceIds || [],
        locations: currentListing.locations || [],
      });
      setSelectedCategory(currentListing.categoryId || '');
      setSelectedSubCategory(currentListing.subCategoryId || '');
      setSelectedCategoryServices(currentListing.categoryServiceIds || []);
      setSelectedSubCategoryServices(
        currentListing.subCategoryServiceIds || []
      );
    }
  }, [currentListing, mode]);

  useEffect(() => {
    if (initialValues && mode === 'edit') {
      setFormValues(initialValues);
      setSelectedCategory(initialValues.categoryId || '');
      setSelectedSubCategory(initialValues.subCategoryId || '');
      setSelectedCategoryServices(initialValues.categoryServiceIds || []);
      setSelectedSubCategoryServices(initialValues.subCategoryServiceIds || []);
    }
  }, [initialValues, mode]);

  useEffect(() => {
    const checkIfTruncated = () => {
      const contentDiv = document.querySelector('.description-content');
      if (contentDiv) {
        const isTruncated = contentDiv.scrollHeight > contentDiv.clientHeight;
        setIsDescriptionTruncated(isTruncated);
      }
    };

    // Check initially and on window resize
    checkIfTruncated();
    window.addEventListener('resize', checkIfTruncated);

    return () => window.removeEventListener('resize', checkIfTruncated);
  }, [currentListing?.description]);

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false, // Prevents Quill from adding extra line breaks
    },
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  const handleShowMoreClick = () => {
    const isExpanding = !showFullDescription;

    if (isExpanding) {
      // Capture the current scroll position relative to the viewport
      const scrollY = window.scrollY;

      setShowFullDescription(true);

      requestAnimationFrame(() => {
        // Restore the scroll position
        window.scrollTo({
          top: scrollY,
          behavior: 'instant',
        });
      });
    } else {
      // When collapsing, scroll to button
      setShowFullDescription(false);

      requestAnimationFrame(() => {
        const button = document.querySelector('.show-more-button');
        button?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  };

  const handleVideoLinksChange = useCallback(
    (newLinks: string[]) => {
      const updatedValues = {
        ...formValues,
        videoLinks: newLinks,
      };
      setFormValues(updatedValues);
      onListingChange?.(updatedValues);
    },
    [formValues, onListingChange]
  );

  if (mode === 'edit' && !currentListing && categories.length === 0) {
    return <div>Loading...</div>;
  }

  if (mode === 'view') {
    return (
      <Box mt={5}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 4,
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="h4" component="h1">
                {currentListing?.name}
              </Typography>
              {mode === 'edit' && currentListing?.status !== undefined && (
                <StatusChip
                  label={getListingStatusText(currentListing.status)}
                  variant="filled"
                  status={currentListing.status}
                />
              )}
            </Box>
          </Box>

          <Box
            component={'div'}
            sx={{
              textAlign: 'left',
              '& .truncated': {
                display: '-webkit-box',
                WebkitLineClamp: '15',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              },
            }}
          >
            <SectionHeader title="Description" icon={undefined} />
            <div
              className={`description-content ${!showFullDescription ? 'truncated' : ''}`}
            >
              {parse(currentListing?.description || '')}
            </div>
            {currentListing?.description && isDescriptionTruncated && (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  className="show-more-button"
                  variant="outlined"
                  fullWidth
                  onClick={handleShowMoreClick}
                  sx={{
                    textTransform: 'none',
                    maxWidth: '400px',
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                    color: 'primary.main',
                    '&:hover': {
                      borderColor: 'rgba(212, 175, 55, 0.6)',
                      backgroundColor: 'rgba(212, 175, 55, 0.05)',
                    },
                    padding: '8px 24px',
                    borderRadius: '20px',
                  }}
                >
                  {showFullDescription ? 'Show Less' : 'Show More'}
                </Button>
              </Box>
            )}
          </Box>
          {currentListing?.locations && currentListing.locations.length > 0 && (
            <Box mt={5} mb={5} component={'div'}>
              <SectionHeader title="Locations" icon={undefined} />
              <LocationDisplay locations={currentListing.locations} />
            </Box>
          )}
          <Box mt={5} mb={5} component={'div'}>
            <SectionHeader title="Images" icon={undefined} />
            <GalleryView imageIds={currentListing?.galleryImageIds || []} />
          </Box>
          {(currentListing?.videoLinks?.length ?? 0) > 0 && (
            <Box mt={5} mb={5} component={'div'}>
              <SectionHeader title="Videos" icon={undefined} />
              <VideoDisplay 
                videoLinks={currentListing?.videoLinks || []} 
                onLinkClick={onLinkClick} 
              />
            </Box>
          )}
        </Container>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h4" component="h1">
              {currentListing?.name || 'New Listing'}
            </Typography>
            {currentListing?.status !== undefined && (
              <StatusChip
                label={getListingStatusText(currentListing.status)}
                variant="filled"
                status={currentListing.status}
              />
            )}
          </Box>
        </Box>

        <Collapse in={showImageFields}>
          <Box mt={5} mb={5} sx={{ width: '355px', height: '200px', mb: 4 }}>
            <ImageCropInput
              initialImageId={formValues.cardImageUri || ''}
              onImageChange={(imageId) => handleImageChange(imageId || '')}
              aspectRatio={16 / 9}
            />
          </Box>
          {mode === 'view' &&
            currentListing?.locations &&
            currentListing.locations.length > 0 && (
              <Box mb={4}>
                <SectionHeader title="Locations" icon={undefined} />
                <LocationDisplay locations={currentListing.locations} />
              </Box>
            )}
          <Box sx={{ mt: 4 }}>
            <SectionHeader title="Images" icon={undefined} />
            <GalleryUpload
              imageIds={formValues.galleryImageIds}
              onImagesChange={(newIds) => {
                const updatedValues = {
                  ...formValues,
                  galleryImageIds: newIds,
                };
                setFormValues(updatedValues);
                onListingChange?.(updatedValues);
              }}
            />
          </Box>

          <Box sx={{ mt: 4 }}>
            <VideoInput
              videoLinks={formValues.videoLinks}
              onVideoLinksChange={handleVideoLinksChange}
            />
          </Box>
          <VideoDisplay 
            videoLinks={formValues.videoLinks} 
            onLinkClick={onLinkClick} 
          />
        </Collapse>

        <Box component="div" sx={{ mt: 3 }}>
          <SectionHeader title="Details" icon={undefined} />

          <TextField
            required
            fullWidth
            error={!!validationErrors?.name}
            label="Name"
            value={formValues.name}
            onChange={handleNameChange}
            helperText={
              validationErrors?.name ||
              'Name should be between 3 and 50 characters'
            }
            inputProps={{ maxLength: 50 }}
            sx={{ mb: 2 }}
          />
          <TextField
            required
            fullWidth
            multiline
            rows={2}
            error={!!validationErrors?.shortDescription}
            label="Short Description"
            value={formValues.shortDescription}
            onChange={handleShortDescriptionChange}
            helperText={
              validationErrors?.shortDescription ||
              'Short description should be between 10 and 150 characters'
            }
            inputProps={{ maxLength: 150 }}
            sx={{ mb: 2 }}
          />

          <SectionHeader title="Description" icon={undefined} />

          <Box
            sx={{
              // mt: 2,
              '& .ql-container': {
                borderBottomLeftRadius: 1,
                borderBottomRightRadius: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : '#fff',
              },
              '& .ql-toolbar': {
                borderTopLeftRadius: 1,
                borderTopRightRadius: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : '#f8f8f8',
              },
              '& .ql-toolbar, & .ql-container': {
                border: '1px solid',
                borderColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.23)'
                    : 'rgba(0, 0, 0, 0.23)',
              },
              '& .ql-editor': {
                minHeight: '200px',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? '#fff' : 'inherit',
              },
              '& .ql-stroke': {
                stroke: (theme) =>
                  theme.palette.mode === 'dark' ? '#fff' : '#000',
              },
              '& .ql-fill': {
                fill: (theme) =>
                  theme.palette.mode === 'dark' ? '#fff' : '#000',
              },
              '& .ql-picker': {
                color: (theme) =>
                  theme.palette.mode === 'dark' ? '#fff' : '#000',
              },
              '& .ql-picker-options': {
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#424242' : '#fff',
                border: '1px solid',
                borderColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.23)'
                    : 'rgba(0, 0, 0, 0.23)',
              },
            }}
          >
            <ReactQuill
              theme="snow"
              value={formValues.description}
              onChange={handleEditorChange}
              modules={quillModules}
              formats={quillFormats}
              placeholder="Enter your listing description..."
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <SectionHeader title="Filters" icon={undefined} />

            <FormControl
              required
              fullWidth
              error={!!validationErrors?.categoryId}
              sx={{ mb: 1 }}
            >
              <InputLabel
                id="category-label"
                error={!!validationErrors?.categoryId}
              >
                Category
              </InputLabel>
              <Select
                labelId="category-label"
                value={formValues.categoryId}
                onChange={handleCategoryChange}
                label="Category"
                error={!!validationErrors?.categoryId}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {validationErrors?.categoryId || ' '}
              </FormHelperText>
            </FormControl>
            <FormControl
              required
              fullWidth
              error={!!validationErrors?.subCategoryId}
              sx={{ mb: 1 }}
              disabled={!formValues.categoryId}
            >
              <InputLabel
                id="subcategory-label"
                error={!!validationErrors?.subCategoryId}
              >
                Subcategory
              </InputLabel>
              <Select
                labelId="subcategory-label"
                value={formValues.subCategoryId}
                onChange={handleSubCategoryChange}
                label="Subcategory"
                error={!!validationErrors?.subCategoryId}
              >
                {subCategories
                  .filter(
                    (subCat) => subCat.categoryId === formValues.categoryId
                  )
                  .map((subCategory) => (
                    <MenuItem key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>
                {validationErrors?.subCategoryId || ' '}
              </FormHelperText>
            </FormControl>
            {selectedCategory &&
              (categoryServices.filter(
                (service) => service.categoryId === selectedCategory
              ).length > 0 ||
                selectedCategoryServices.length > 0) && (
                <MultiSelectChips
                  options={categoryServices.filter(
                    (service) => service.categoryId === selectedCategory
                  )}
                  selectedValues={selectedCategoryServices}
                  onChange={handleCategoryServicesChange}
                  label="Category Services"
                />
              )}
            {selectedSubCategory &&
              (subCategoryServices.filter(
                (service) => service.subCategoryId === selectedSubCategory
              ).length > 0 ||
                selectedSubCategoryServices.length > 0) && (
                <MultiSelectChips
                  options={subCategoryServices.filter(
                    (service) => service.subCategoryId === selectedSubCategory
                  )}
                  selectedValues={selectedSubCategoryServices}
                  onChange={handleSubCategoryServicesChange}
                  label="Subcategory Services"
                />
              )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <SectionHeader title="Locations" icon={undefined} />
        <LocationFilter {...locationFilterProps} />
      </Box>
      <Button onClick={onChatStart}>Start Chat</Button>
    </Container>
  );
};

export { ListingView };
