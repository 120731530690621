import { Region } from './locations';

export enum EventPlannerTypeEnum {
  None = -1,
  Wedding = 10,
  Birthday = 20,
  TeamBuilding = 30,
  BachelorParty = 40,
  Seminar = 50,
  Custom = 999,
}

export interface EventConfig {
  eventName: string;
  description?: string;
  startDate: string;
  endDate: string;
  budget?: number;
  numberOfAttendees: number;
}

export interface CategoryService {
  id: string;
  categoryId: string;
  name: string;
  groupName: string;
  shortDescription: string;
  isSelected: boolean;
}

export interface SubCategoryService {
  id: string;
  name: string;
  isSelected: boolean;
}

export interface CategoryData {
  subCategories: SubCategory[];
  categoryServices: CategoryService[];
}

export interface SubCategory {
  id: string;
  name: string;
  description: string;
  services: CategoryService[];
}

export interface Category {
  id: string;
  name: string;
  description: string;
  subCategories: SubCategory[];
}

export interface CategoryMap {
  [categoryUuid: string]: CategoryData;
}

export enum EventStatusEnum {
  Draft = 0,
  AwaitingApproval = 100,
  Approved = 200,
  Rejected = 300,
  Completed = 400,
  Cancelled = 500,
}

export interface PlannerEvent {
  id: string;
  eventType: EventPlannerTypeEnum;
  eventConfig: EventConfig;
  eventLocations: Region[];
  eventCategories: Category[];
  selectedServices: CategoryMap[];
  status: EventStatusEnum;
  lastUpdated: string;
  createdAt: string;
  rejectionMessage?: string;
  matchingListingIds?: string[];
  createdBy: string;
}

// Helper type for new events that don't have an ID yet
export type NewEvent = Omit<PlannerEvent, 'id' | 'createdAt'>;

// Helper function to create a new empty event
export const createEmptyEvent = (): NewEvent => ({
  eventType: EventPlannerTypeEnum.None,
  eventConfig: {
    eventName: '',
    startDate: '',
    endDate: '',
    numberOfAttendees: 0,
  },
  eventLocations: [],
  eventCategories: [],
  selectedServices: [],
  status: EventStatusEnum.Draft,
  lastUpdated: new Date().toISOString(),
  createdBy: '',
});
