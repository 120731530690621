import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

interface NoDataProps {
  title: string;
  message?: string;
  icon?: ReactNode;
  actionButton?: ReactNode;
}

const NoData: React.FC<NoDataProps> = ({
  title,
  message,
  icon = <SearchOffIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />,
  actionButton
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        width: '100%',
        p: 3,
        textAlign: 'center'
      }}
    >
      {icon}
      <Typography 
        variant="h6" 
        component="p" 
        gutterBottom
        sx={{ 
          color: 'text.primary',
          fontWeight: 500,
          mb: 1 
        }}
      >
        {title}
      </Typography>
      {message && (
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          {message}
        </Typography>
      )}
      {actionButton}
    </Box>
  );
};

export default NoData;
