import React, { forwardRef } from 'react';
import { SectionHeader } from '../../components/dashboard/SectionHeader';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';

interface ListingsHeaderProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  sortBy: string;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  onSortChange: (event: SelectChangeEvent) => void;
  onPageSizeChange: (event: SelectChangeEvent) => void;
}

const ListingsHeader = forwardRef<HTMLDivElement, ListingsHeaderProps>(
  (
    {
      totalItems,
      itemsPerPage,
      currentPage,
      sortBy,
      onPageChange,
      onSortChange,
      onPageSizeChange,
    },
    ref
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Common styles for Select components
    const selectStyles = {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(212, 175, 55, 0.3)',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(212, 175, 55, 0.5)',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D4AF37',
      },
      '& .MuiSelect-select': {
        color: '#D4AF37',
      },
      '& .MuiSvgIcon-root': {
        color: '#D4AF37',
      },
    };

    // Common styles for labels
    const labelStyles = {
      color: '#D4AF37',
      '&.Mui-focused': {
        color: '#D4AF37',
      },
    };

    const Actions = (
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        alignItems={isMobile ? 'stretch' : 'center'}
        width={isMobile ? '100%' : 'auto'}
        mt={isMobile ? 2 : 0}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
          }}
        >
          <FormControl size="small" sx={{ minWidth: isMobile ? '100%' : 150 }}>
            <InputLabel sx={labelStyles}>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={onSortChange}
              label="Sort By"
              sx={selectStyles}
            >
              <MenuItem value="newest">Newest First</MenuItem>
              <MenuItem value="oldest">Oldest First</MenuItem>
              <MenuItem value="priceAsc">Price (Low to High)</MenuItem>
              <MenuItem value="priceDesc">Price (High to Low)</MenuItem>
              <MenuItem value="nameAsc">Name (A to Z)</MenuItem>
              <MenuItem value="nameDesc">Name (Z to A)</MenuItem>
              <MenuItem value="random">Random</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: isMobile ? '100%' : 100 }}>
            <InputLabel sx={labelStyles}>Items</InputLabel>
            <Select
              value={itemsPerPage.toString()}
              onChange={onPageSizeChange}
              label="Items"
              sx={selectStyles}
            >
              <MenuItem value="12">12 per page</MenuItem>
              <MenuItem value="24">24 per page</MenuItem>
              <MenuItem value="48">48 per page</MenuItem>
              <MenuItem value="96">96 per page</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            width: '100%',
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={onPageChange}
            color="primary"
            size={isMobile ? 'small' : 'medium'}
            siblingCount={isMobile ? 0 : 1}
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#D4AF37',
                borderColor: 'rgba(212, 175, 55, 0.3)',
                '&:hover': {
                  backgroundColor: 'rgba(212, 175, 55, 0.08)',
                },
                '&.Mui-selected': {
                  backgroundColor: '#D4AF37',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#B49030',
                  },
                },
              },
            }}
          />
        </Box>
      </Stack>
    );

    return (
      <Box ref={ref}>
        <SectionHeader
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              <ListIcon sx={{ color: '#D4AF37' }} />
              <Box>
                <Typography variant="h6" sx={{ color: '#D4AF37' }}>
                  Listings
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {totalItems} {totalItems === 1 ? 'listing' : 'listings'} found
                </Typography>
              </Box>
            </Stack>
          }
          actions={Actions}
        />
      </Box>
    );
  }
);

export default ListingsHeader;
