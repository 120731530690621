import axiosInstance from '../utilis/axios';

export interface PowerBIEmbedConfig {
  reportId: string;
  embedUrl: string;
  embedToken: string;
  tokenType: string;
}

export const getPowerBIEmbedConfig = async (
  reportId: string
): Promise<PowerBIEmbedConfig> => {
  const response = await axiosInstance.get(`/powerbi/embed-config/${reportId}`);
  return response.data;
};
