import {
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store/store.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { register } from '../../store/slices/authSlice.ts';
import i18next from '../../utilis/i18next.ts';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo') || '/';
  useDocumentTitle(i18next.t('Views.RegisterPage.Title'));

  const auth = useSelector((state: RootState) => state.auth);

  const getGenericErrorMessage = (error: string) => {
    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.status === 'failed') {
      setErrorMessage(getGenericErrorMessage(auth.error || ''));
      setShowError(true);
    } else if (auth.status === 'succeeded') {
      navigate(redirectTo);
    }
  }, [auth, navigate, redirectTo]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage(getGenericErrorMessage('password_mismatch'));
      setShowError(true);
      return;
    }

    dispatch(register({ email, password, confirmPassword }));
  };

  const handleCloseError = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          pt: { xs: 4, sm: 8 },
          pb: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          mt={10}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: 600,
              textAlign: 'center',
              background:
                'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundSize: '200% auto',
              animation: 'shine 3s linear infinite, slideDown 0.8s ease-out',
              '@keyframes shine': {
                to: {
                  backgroundPosition: '200% center',
                },
              },
              '@keyframes slideDown': {
                from: {
                  opacity: 0,
                  transform: 'translateY(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateY(0)',
                },
              },
            }}
          >
            {i18next.t('Views.RegisterPage.Title')}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '100%',
              background: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(10px)',
              p: 3,
              borderRadius: 1,
              border: '1px solid rgba(212, 175, 55, 0.3)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
              position: 'relative',
              overflow: 'hidden',
              animation: 'formAppear 0.8s ease-out 0.2s both',
              '@keyframes formAppear': {
                from: {
                  opacity: 0,
                  transform: 'scale(0.95)',
                  filter: 'blur(5px)',
                },
                to: {
                  opacity: 1,
                  transform: 'scale(1)',
                  filter: 'blur(0)',
                },
              },
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18next.t('Views.RegisterPage.EmailLabel')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18next.t('Views.RegisterPage.PasswordLabel')}
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={i18next.t('Views.RegisterPage.ConfirmPasswordLabel')}
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                color: '#D4AF37',
                borderColor: '#D4AF37',
                borderWidth: '2px',
                backgroundColor: 'rgba(212, 175, 55, 0.05)',
                textTransform: 'none',
                fontSize: '1.1rem',
                fontWeight: 500,
                padding: '10px',
                '&:hover': {
                  borderColor: '#D4AF37',
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                  borderWidth: '2px',
                },
                position: 'relative',
                overflow: 'hidden',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-100%',
                  width: '200%',
                  height: '100%',
                  background:
                    'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.2), transparent)',
                  animation: 'shimmer 2s infinite',
                },
                '@keyframes shimmer': {
                  '0%': {
                    left: '-100%',
                  },
                  '100%': {
                    left: '100%',
                  },
                },
              }}
            >
              {i18next.t('Views.RegisterPage.RegisterButton')}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="/login"
                  variant="body2"
                  sx={{
                    color: '#D4AF37',
                    textDecoration: 'none',
                    opacity: 0.8,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {i18next.t('Views.RegisterPage.LoginLink')}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              onClose={handleCloseError}
              severity="error"
              sx={{
                width: '100%',
                backgroundColor: 'rgba(211, 47, 47, 0.95)',
                color: '#fff',
                backdropFilter: 'blur(10px)',
              }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterPage;
