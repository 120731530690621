import { Theme } from '@mui/material';
import { ListingStatus } from '../types/states/ListingState';

export const getStatusChipStyle = (theme: Theme, status: ListingStatus) => {
  // Get appropriate colors based on status
  let bgColor;
  let textColor;

  switch (status) {
    case ListingStatus.DRAFT:
      bgColor = theme.palette.grey[600];
      textColor = '#ffffff';
      break;
    case ListingStatus.PENDING_APPROVAL:
      bgColor = theme.palette.warning.main;
      textColor = theme.palette.warning.contrastText;
      break;
    case ListingStatus.APPROVED:
      bgColor = theme.palette.success.main;
      textColor = theme.palette.success.contrastText;
      break;
    case ListingStatus.REJECTED:
      bgColor = theme.palette.error.main;
      textColor = theme.palette.error.contrastText;
      break;
    default:
      bgColor = theme.palette.grey[600];
      textColor = '#ffffff';
      break;
  }

  // Return style object without referencing theme directly in nested properties
  return {
    '&.MuiChip-root.MuiChip-filled': {
      backgroundColor: `${bgColor} !important`,
      color: textColor,
      '&:hover': {
        backgroundColor: `${bgColor} !important`,
        opacity: 0.9,
      },
    },
  };
};
