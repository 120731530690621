import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { CompanyGrid } from './components/CompanyGrid.tsx';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStylePreference,
  setCurrentCompany,
  setStylePreference,
} from '../../store/slices/companySlice.ts';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';
import i18next from '../../utilis/i18next.ts';

const CompaniesPage = () => {
  useDocumentTitle(i18next.t('Views.CompaniesPage.Title'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedStyle = useSelector(selectStylePreference);

  const handleAddCompany = () => {
    dispatch(
      setCurrentCompany({
        id: '',
        name: '',
        shortDescription: '',
        description: '',
        logoImageUri: '',
        bannerImageUri: '',
        createdById: '',
        createdAt: '',
        updatedAt: '',
        status: 100,
        stripeCustomerId: '',
        activeSubscriptionId: '',
        activeProductId: '',
        currentPeriodStart: '',
        currentPeriodEnd: '',
        subscriptionHistory: [],
        oneTimePurchaseHistory: [],
        listingTokens: 0,
        eventClickTokens_Subscription: 0,
        eventClickTokens_Extras: 0,
      })
    );
    navigate('/company');
  };

  const buttonStyle = {
    backgroundColor: 'rgba(212, 175, 55, 0.05)',
    border: '2px solid',
    borderColor: 'rgba(212, 175, 55, 0.3)',
    color: '#D4AF37',
    padding: '10px 24px',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#D4AF37',
      borderColor: '#D4AF37',
      color: '#000',
      boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
      transform: 'translateY(-1px)',
    },
    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
    },
  };

  const selectStyle = {
    height: '100%',
    backgroundColor: 'rgba(218,178,48,0.05)',
    border: '2px solid rgba(212, 175, 55, 0.3)',
    borderRadius: '4px',
    color: '#D4AF37',
    '& .MuiSelect-select': {
      padding: '8px 16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover': {
      borderColor: '#D4AF37',
    },
    '&.Mui-focused': {
      borderColor: '#D4AF37',
      backgroundColor: 'rgba(212, 175, 55, 0.1)',
    },
    '& .MuiSvgIcon-root': {
      color: '#D4AF37',
    },
  };

  const menuItemStyle = {
    '&:hover': {
      backgroundColor: 'rgba(212, 175, 55, 0.5)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(212, 175, 55, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(212, 175, 55, 0.3)',
      },
    },
  };

  return (
    <Box>
      <Header
        title="Companies"
        subtitle="Create, edit, and manage your companies"
        showBackButton={true}
        actions={
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <FormControl sx={{ height: '48px', minWidth: '120px' }}>
              <Select
                value={selectedStyle}
                onChange={(e) => dispatch(setStylePreference(e.target.value))}
                sx={{
                  ...selectStyle,
                  height: '48px',
                  '& .MuiSelect-select': {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'rgba(18, 18, 18, 0.95)',
                      backgroundImage:
                        'linear-gradient(rgba(212, 175, 55, 0.05), rgba(212, 175, 55, 0.05))',
                      border: '1px solid rgba(212, 175, 55, 0.2)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
                    },
                  },
                }}
              >
                <MenuItem value="default" sx={menuItemStyle}>
                  Default Style
                </MenuItem>
                <MenuItem value="minimal" sx={menuItemStyle}>
                  Minimal
                </MenuItem>
                <MenuItem value="elegant" sx={menuItemStyle}>
                  Elegant
                </MenuItem>
                <MenuItem value="modern" sx={menuItemStyle}>
                  Modern
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              startIcon={<AddIcon sx={{ fontSize: '1.2rem' }} />}
              onClick={handleAddCompany}
              sx={{
                ...buttonStyle,
                '& .MuiButton-startIcon': {
                  marginRight: '8px',
                },
              }}
            >
              Add Company
            </Button>
          </Box>
        }
      />
      <Box
        sx={{
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
            opacity: 0.7,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background:
              'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
          },
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            py: { xs: 6, md: 8 },
            position: 'relative',
            zIndex: 1,
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <CompanyGrid styleVariant={selectedStyle} />
        </Container>
      </Box>
    </Box>
  );
};

export default CompaniesPage;
