import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { approveEvent, fetchEventById, selectCurrentEvent } from '../../../../store/slices/eventSlice';
import { fetchListingsByIds } from '../../../../store/slices/listingSlice';
import EventOverviewStep from '../../../planner/event/components/EventOverviewStep';
import { ListingCard } from '../../../ListingsPage/ListingCard.tsx';
import { selectCategories } from '../../../../store/slices/categorySlice.ts';
import { selectSubCategories } from '../../../../store/slices/subCategorySlice.ts';
import { selectSubCategoryServices } from '../../../../store/slices/subCategoryServiceSlice.ts';
import Header from '../../../../components/header';

export const EventApprovalPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');

  const event = useSelector(selectCurrentEvent);
  const listings = useSelector((state: RootState) => state.listings.listings);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);

  useEffect(() => {
    if (id) {
      dispatch(fetchEventById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (event?.matchingListingIds?.length) {
      dispatch(fetchListingsByIds(event.matchingListingIds));
    }
  }, [event?.matchingListingIds, dispatch]);

  const handleApprove = () => {
    if (!id) return;
    dispatch(approveEvent({ eventId: id, approved: true }));
    navigate('/admin/approvals/events');
  };

  const handleReject = () => {
    setOpenRejectDialog(true);
  };

  const handleConfirmReject = () => {
    if (!id) return;
    dispatch(
      approveEvent({
        eventId: id,
        approved: false,
        rejectionMessage: rejectionMessage.trim(),
      })
    );
    setOpenRejectDialog(false);
    navigate('/admin/approvals/events');
  };

  if (!event) {
    return (
      <Container>
        <Typography variant="h4">Event not found</Typography>
      </Container>
    );
  }

  return (
    <>
      <Header
        title="Event Approval"
        subtitle="Review and approve or reject this event"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Stack spacing={4}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              bgcolor: 'rgba(0, 0, 0, 0.9)',
              border: '1px solid rgba(255, 215, 0, 0.2)',
              borderRadius: 2,
              mb: 3,
            }}
          >
            <Typography variant="h4" sx={{ color: '#FFD700', mb: 2 }}>
              Event Approval Review
            </Typography>
            <Typography sx={{ color: '#fff', mb: 3 }}>
              Review the event details below and choose to approve or reject the
              event.
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="success"
                onClick={handleApprove}
                sx={{
                  bgcolor: 'success.main',
                  '&:hover': { bgcolor: 'success.dark' },
                }}
              >
                Approve Event
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleReject}
                sx={{
                  bgcolor: 'error.main',
                  '&:hover': { bgcolor: 'error.dark' },
                }}
              >
                Reject Event
              </Button>
            </Stack>
          </Paper>
          {/* Event Overview */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Event Overview
            </Typography>
            <Box sx={{ mt: 2 }}>
              <EventOverviewStep event={event} />
            </Box>
          </Paper>
          {event.matchingListingIds?.length > 0 && (
            <Paper
              elevation={0}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                border: '1px solid rgba(255, 215, 0, 0.2)',
                borderRadius: 2,
                mt: 3,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center" mb={3}>
                <Typography variant="h5" sx={{ color: '#FFD700' }}>
                  Matching Listings
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#FFD700' }}>
                  ({listings.length})
                </Typography>
              </Stack>
              <Grid container spacing={3}>
                {listings.map((listing) => (
                  <Grid item xs={12} sm={6} md={4} key={listing.id}>
                    <ListingCard
                      listing={listing}
                      categories={categories}
                      subCategories={subCategories}
                      subCategoryServices={subCategoryServices}
                    />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}
        </Stack>
      </Container>

      <Dialog
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        PaperProps={{
          sx: {
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle sx={{ color: '#FFD700' }}>Reject Event</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: '#fff', mb: 2 }}>
            Please provide a reason for rejecting this event. This message will
            be sent to the event organizer.
          </Typography>
          <TextField
            autoFocus
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Enter rejection reason..."
            value={rejectionMessage}
            onChange={(e) => setRejectionMessage(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': {
                  borderColor: 'rgba(255, 215, 0, 0.3)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 215, 0, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFD700',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setOpenRejectDialog(false)}
            sx={{ color: '#fff' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmReject}
            variant="contained"
            color="error"
            disabled={!rejectionMessage.trim()}
            sx={{
              bgcolor: 'error.main',
              '&:hover': { bgcolor: 'error.dark' },
            }}
          >
            Confirm Rejection
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventApprovalPage;
