import React from 'react';
import { Box, Chip, Grid, Paper, Stack, Typography } from '@mui/material';
import {
  Category as CategoryIcon,
  LocationOn as LocationOnIcon,
  Notes as NotesIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { EventPlannerTypeEnum, PlannerEvent } from '../../../../types/event';
import { Listing } from '../../../../types/states/ListingState';
import { LocationDisplay } from '../../../../components/filters/LocationDisplay';

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  border: '1px solid rgba(255, 215, 0, 0.3)',
  '& .MuiTypography-label': {
    color: '#FFD700',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  '& .MuiTypography-value': {
    color: '#fff',
    fontSize: '1rem',
  },
}));

interface EventOverviewStepProps {
  event: PlannerEvent;
  companyListings: Listing[];
  companyId?: string;
}

const CompanyEventOverview: React.FC<EventOverviewStepProps> = ({ 
  event,
  companyListings,
  companyId
}) => {
  // Get unique category and subcategory IDs from company listings
  const companyCategories = new Set(companyListings.map(l => l.categoryId));
  const companySubCategories = new Set(companyListings.map(l => l.subCategoryId));

  // Get all service IDs from company listings
  const companyCategoryServices = new Set(companyListings.flatMap(l => l.categoryServiceIds || []));
  const companySubCategoryServices = new Set(companyListings.flatMap(l => l.subCategoryServiceIds || []));

  // Helper function to determine if a service chip should be filled
  const getChipVariant = (serviceId: string, isSubCategoryService: boolean) => {
    if (!companyId) return "filled"; // Show all services as filled when no companyId
    const hasService = isSubCategoryService 
      ? companySubCategoryServices.has(serviceId)
      : companyCategoryServices.has(serviceId);
    return hasService ? "filled" : "outlined";
  };

  // Helper function for chip styles
  const getChipStyles = (variant: "filled" | "outlined") => ({
    bgcolor: variant === "filled" ? 'rgba(255, 215, 0, 0.1)' : 'transparent',
    border: '1px solid rgba(255, 215, 0, 0.3)',
    color: '#fff',
    '& .MuiChip-label': {
      color: '#fff',
    },
    '&:hover': {
      bgcolor: variant === "filled" ? 'rgba(255, 215, 0, 0.2)' : 'rgba(255, 215, 0, 0.05)',
    },
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {/* Left Column - Event Details and Locations */}
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            {/* Event Details */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                border: '1px solid rgba(255, 215, 0, 0.2)',
                borderRadius: 2,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                <NotesIcon sx={{ color: '#FFD700' }} />
                <Typography variant="h6" sx={{ color: '#FFD700' }}>
                  Event Details
                </Typography>
              </Stack>

              <Stack spacing={2}>
                {/* Event Type */}
                <DetailItem>
                  <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                    Event Type:
                  </Typography>
                  <Typography sx={{ color: '#fff' }}>
                    {EventPlannerTypeEnum[event.eventType] || 'No Type Selected'}
                  </Typography>
                </DetailItem>

                {/* Event Name */}
                <DetailItem>
                  <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                    Event Name:
                  </Typography>
                  <Typography sx={{ color: '#fff' }}>
                    {event.eventConfig?.eventName || 'Untitled Event'}
                  </Typography>
                </DetailItem>

                {/* Start Date */}
                <DetailItem>
                  <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                    Start Date:
                  </Typography>
                  <Typography sx={{ color: '#fff' }}>
                    {event.eventConfig?.startDate
                      ? new Date(event.eventConfig.startDate).toLocaleDateString()
                      : 'No Date Set'}
                  </Typography>
                </DetailItem>

                {/* End Date */}
                <DetailItem>
                  <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                    End Date:
                  </Typography>
                  <Typography sx={{ color: '#fff' }}>
                    {event.eventConfig?.endDate
                      ? new Date(event.eventConfig.endDate).toLocaleDateString()
                      : 'No Date Set'}
                  </Typography>
                </DetailItem>

                {/* Number of Attendees */}
                <DetailItem>
                  <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                    Number of Attendees:
                  </Typography>
                  <Typography sx={{ color: '#fff' }}>
                    {event.eventConfig?.numberOfAttendees?.toString() || '0'}
                  </Typography>
                </DetailItem>

                {/* Budget */}
                {event.eventConfig?.budget && (
                  <DetailItem>
                    <Typography variant="subtitle2" sx={{ color: '#FFD700' }}>
                      Budget:
                    </Typography>
                    <Typography sx={{ color: '#fff' }}>
                      ${event.eventConfig.budget.toLocaleString()}
                    </Typography>
                  </DetailItem>
                )}

                {/* Description */}
                {event.eventConfig?.description && (
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: '#FFD700', mb: 1 }}>
                      Description:
                    </Typography>
                    <Typography sx={{ color: '#fff' }}>
                      {event.eventConfig.description}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Paper>

            {/* Locations */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                border: '1px solid rgba(255, 215, 0, 0.2)',
                borderRadius: 2,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                <LocationOnIcon sx={{ color: '#FFD700' }} />
                <Typography variant="h6" sx={{ color: '#FFD700' }}>
                  Event Locations
                </Typography>
              </Stack>

              <Box sx={{ mt: 1 }}>
                <LocationDisplay locations={event.eventLocations || []} />
              </Box>
            </Paper>
          </Stack>
        </Grid>

        {/* Right Column - Services */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              bgcolor: 'rgba(0, 0, 0, 0.9)',
              border: '1px solid rgba(255, 215, 0, 0.2)',
              borderRadius: 2,
              height: '100%',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" mb={2}>
              <CategoryIcon sx={{ color: '#FFD700' }} />
              <Typography variant="h6" sx={{ color: '#FFD700' }}>
                {companyId ? 'Your Matching Services' : 'Event Services'}
              </Typography>
            </Stack>

            <Stack spacing={3}>
              {Object.entries(event.selectedServices || {}).map(
                ([categoryId, categoryData]) => {
                  // Skip category filtering if no companyId
                  if (companyId && !companyCategories.has(categoryId)) return null;

                  const { subCategories, categoryServices } = categoryData;
                  const hasSelectedServices = categoryServices?.some(
                    (service) => service.isSelected
                  );
                  const hasSelectedSubCategories = subCategories?.some(
                    (sub) =>
                      (sub.isSelected && (!companyId || companySubCategories.has(sub.id))) ||
                      (sub.services?.some((service) => service.isSelected) && (!companyId || companySubCategories.has(sub.id)))
                  );

                  if (!hasSelectedServices && !hasSelectedSubCategories)
                    return null;

                  const category = event.eventCategories?.find(
                    (cat) => cat.id === categoryId
                  );

                  return (
                    <Box
                      key={categoryId}
                      sx={{
                        p: 2,
                        bgcolor: 'rgba(0, 0, 0, 0.8)',
                        border: '1px solid rgba(255, 215, 0, 0.3)',
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: '#FFD700',
                          fontWeight: 600,
                          mb: 3,
                          pb: 1,
                          borderBottom: '1px solid rgba(255, 215, 0, 0.2)',
                        }}
                      >
                        {category?.name || 'Unknown Category'}
                      </Typography>

                      <Stack spacing={2}>
                        {/* Category Services */}
                        {categoryServices?.some(
                          (service) => service.isSelected
                        ) && (
                          <Box>
                            <Typography
                              sx={{
                                color: '#FFD700',
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              General Services
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              flexWrap="wrap"
                              sx={{ gap: 1 }}
                            >
                              {categoryServices
                                ?.filter((service) => service.isSelected)
                                .map((service, sIndex) => {
                                  const variant = getChipVariant(service.id, false);
                                  return (
                                    <Chip
                                      key={sIndex}
                                      label={service.name}
                                      variant={variant}
                                      sx={getChipStyles(variant)}
                                    />
                                  );
                                })}
                            </Stack>
                          </Box>
                        )}

                        {/* SubCategories and their services */}
                        {subCategories?.map((subCategory) => {
                          // Skip if company doesn't have listings in this subcategory (only when companyId is present)
                          if (companyId && !companySubCategories.has(subCategory.id)) return null;

                          const selectedServices = subCategory.services?.filter(
                            (service) => service.isSelected
                          );
                          if (
                            !subCategory.isSelected &&
                            !selectedServices?.length
                          )
                            return null;

                          return (
                            <Box key={subCategory.id}>
                              <Typography
                                sx={{
                                  color: '#FFD700',
                                  fontWeight: 500,
                                  mb: 1,
                                }}
                              >
                                {subCategory.name}
                              </Typography>
                              {selectedServices &&
                                selectedServices.length > 0 && (
                                  <>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      flexWrap="wrap"
                                      sx={{ gap: 1 }}
                                    >
                                      {selectedServices.map((service, sIndex) => {
                                        const variant = getChipVariant(service.id, true);
                                        return (
                                          <Chip
                                            key={sIndex}
                                            label={service.name}
                                            variant={variant}
                                            sx={getChipStyles(variant)}
                                          />
                                        );
                                      })}
                                    </Stack>
                                    <Box sx={{ mt: 2 }}>
                                      {selectedServices.map((service, sIndex) => (
                                        <Typography
                                          key={sIndex}
                                          sx={{ color: '#fff' }}
                                        >
                                          {service.description}
                                        </Typography>
                                      ))}
                                    </Box>
                                  </>
                                )}
                            </Box>
                          );
                        })}
                      </Stack>
                    </Box>
                  );
                }
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyEventOverview;
