import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchListings,
  selectSearchError,
  selectSearchLoading,
  selectSearchResults,
} from '../../store/slices/searchSlice';
import { AppDispatch } from '../../store/store';
import Header from '../../components/header';
import { ImageView } from '../../components/inputs/ImageView';

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') || '';
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector(selectSearchResults);
  const loading = useSelector(selectSearchLoading);
  const error = useSelector(selectSearchError);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchListings(searchQuery));
    }
  }, [dispatch, searchQuery]);

  const handleCategoryClick = (categoryId: string) => {
    navigate(
      `/listings?categoryId=${categoryId}&search=${encodeURIComponent(searchQuery)}`
    );
  };

  if (error) {
    return (
      <Box>
        <Header
          title="Search Results"
          subtitle={`for ${searchQuery}`}
          showBackButton={true}
        />
        <Box
          sx={{
            position: 'relative',
            background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            minHeight: 'calc(100vh - 64px)',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
              opacity: 0.7,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '2px',
              background:
                'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
            },
          }}
        >
          <Typography color="error">Error: {error}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header
        title="Search Results"
        subtitle={`for ${searchQuery}`}
        showBackButton={true}
      />
      <Box
        sx={{
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
            opacity: 0.7,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background:
              'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
          },
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ py: 4, borderTop: 1, borderColor: 'divider' }}>
            <Container
              maxWidth="lg"
              sx={{
                position: 'relative',
                zIndex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: { xs: 2, md: 4 },
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    minHeight: '300px',
                  }}
                >
                  <CircularProgress sx={{ color: '#D4AF37' }} />
                </Box>
              ) : searchResults ? (
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mb: { xs: 2, md: 4 },
                      textAlign: 'center',
                      fontWeight: 500,
                      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                      background:
                        'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      backgroundSize: '200% auto',
                      animation: 'shine 3s linear infinite',
                      '@keyframes shine': {
                        to: {
                          backgroundPosition: '200% center',
                        },
                      },
                    }}
                  ></Typography>
                  <Box
                    sx={{
                      animation: 'fadeIn 0.8s ease-out',
                      '@keyframes fadeIn': {
                        from: { opacity: 0, transform: 'translateY(20px)' },
                        to: { opacity: 1, transform: 'translateY(0)' },
                      },
                    }}
                  >
                    <Box
                      display="grid"
                      gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                        lg: 'repeat(4, 1fr)',
                      }}
                      gap={3}
                    >
                      {searchResults.categoryResults
                        .filter((category) => category.listingCount > 0)
                        .map((category, index) => (
                          <Card
                            key={category.categoryId}
                            onClick={() =>
                              handleCategoryClick(category.categoryId)
                            }
                            elevation={0}
                            sx={{
                              position: 'relative',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)',
                              height: '100%',
                              width: '100%',
                              minHeight: '240px',
                              maxWidth: '320px',
                              margin: '0 auto',
                              transition:
                                'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                              borderRadius: '16px',
                              overflow: 'hidden',
                              backdropFilter: 'blur(8px)',
                              border: '2px solid',
                              borderColor: 'rgba(212, 175, 55, 0.3)',
                              cursor: 'pointer',
                              animation: `fadeSlideIn 0.6s ease-out ${index * 0.1}s both`,
                              '@keyframes fadeSlideIn': {
                                from: {
                                  opacity: 0,
                                  transform: 'translateY(20px)',
                                },
                                to: {
                                  opacity: 1,
                                  transform: 'translateY(0)',
                                },
                              },
                              '&:hover': {
                                transform: 'translateY(-8px)',
                                borderColor: '#D4AF37',
                                boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
                                '& .category-image': {
                                  transform: 'scale(1.1)',
                                  borderColor: '#D4AF37',
                                },
                                '& .category-title': {
                                  background:
                                    'linear-gradient(to right, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  backgroundSize: '200% auto',
                                  animation: 'shine 3s linear infinite',
                                },
                                '&::after': {
                                  opacity: 1,
                                },
                              },
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                  'linear-gradient(135deg, rgba(212, 175, 55, 0.1) 0%, rgba(212, 175, 55, 0) 100%)',
                                opacity: 0,
                                transition: 'opacity 0.3s ease-in-out',
                                pointerEvents: 'none',
                              },
                              '@keyframes shine': {
                                to: {
                                  backgroundPosition: '200% center',
                                },
                              },
                            }}
                          >
                            <Box
                              sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <Box
                                className="category-image"
                                sx={{
                                  width: 100,
                                  height: 100,
                                  borderRadius: '50%',
                                  mb: 2.5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                  border: '2px solid rgba(212, 175, 55, 0.3)',
                                  transition:
                                    'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                  overflow: 'hidden',
                                }}
                              >
                                <ImageView
                                  fileId={category?.imageUri}
                                  alt={category?.name}
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </Box>
                              <Typography
                                className="category-title"
                                variant="h6"
                                sx={{
                                  color: '#D4AF37',
                                  textAlign: 'center',
                                  fontWeight: 500,
                                  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                                  mb: 0.5,
                                }}
                              >
                                {category.categoryName}
                              </Typography>
                              <Typography
                                sx={{
                                  color: 'rgba(212, 175, 55, 0.7)',
                                  textAlign: 'center',
                                  fontSize: '0.9rem',
                                }}
                              >
                                <Box component="span" sx={{ 
                                  color: '#D4AF37',
                                  fontWeight: 600,
                                  fontSize: '1.1rem',
                                  textShadow: '0 1px 2px rgba(0,0,0,0.3)'
                                }}>
                                  {category.listingCount}
                                </Box>{' '}
                                {category.listingCount === 1
                                  ? 'listing'
                                  : 'listings'}
                              </Typography>
                            </Box>
                          </Card>
                        ))}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    textAlign: 'center',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: '#D4AF37',
                      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                    }}
                  >
                    No results found
                  </Typography>
                </Box>
              )}
            </Container>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default SearchPage;
