// src/services/favoritesService.ts
import axiosInstance from '../utilis/axios';
import { apiUrl } from '../main';

export interface FavoritesResponse {
  favoriteListings: string[];
}

const favoritesService = {
  /**
   * Get all favorites for the current user
   */
  getFavorites: async (): Promise<FavoritesResponse> => {
    const response = await axiosInstance.get(`${apiUrl}/Favorites`);
    return response.data;
  },

  /**
   * Add a listing to favorites
   */
  addFavorite: async (listingId: string): Promise<FavoritesResponse> => {
    const response = await axiosInstance.post(`${apiUrl}/Favorites/${listingId}`);
    return response.data;
  },

  /**
   * Remove a listing from favorites
   */
  removeFavorite: async (listingId: string): Promise<FavoritesResponse> => {
    const response = await axiosInstance.delete(`${apiUrl}/Favorites/${listingId}`);
    return response.data;
  },

  /**
   * Check if a listing is in the user's favorites
   */
  isFavorite: async (listingId: string): Promise<boolean> => {
    const response = await axiosInstance.get(`${apiUrl}/Favorites/${listingId}`);
    return response.data.isFavorite;
  }
};

export default favoritesService;
