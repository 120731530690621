import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Listing } from '../../types/states/ListingState';
import favoritesService from '../../services/favoritesService';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';

export interface FavoritesState {
  favoriteIds: string[];
  favoriteListings: Listing[];
  isLoading: boolean;
  error: string | null;
}

const initialState: FavoritesState = {
  favoriteIds: [],
  favoriteListings: [],
  isLoading: false,
  error: null,
};

// Async thunks
export const getFavorites = createAsyncThunk(
  'favorites/getFavorites',
  async (_, { rejectWithValue }) => {
    try {
      const response = await favoritesService.getFavorites();
      return response.favoriteListings;
    } catch (error) {
      return rejectWithValue('Failed to fetch favorites');
    }
  }
);

export const getFavoriteListings = createAsyncThunk(
  'favorites/getFavoriteListings',
  async (favoriteIds: string[], { rejectWithValue }) => {
    try {
      if (favoriteIds.length === 0) return [];

      const listingDetailsPromises = favoriteIds.map(async (listingId) => {
        try {
          const response = await axiosInstance.get(
            `${apiUrl}/Listing/${listingId}`
          );
          return response.data;
        } catch (error) {
          console.error(`Error fetching listing ${listingId}:`, error);
          return null;
        }
      });

      const listingDetails = await Promise.all(listingDetailsPromises);
      return listingDetails.filter(
        (listing): listing is Listing => listing !== null
      );
    } catch (error) {
      return rejectWithValue('Failed to fetch favorite listings');
    }
  }
);

export const addFavorite = createAsyncThunk(
  'favorites/addFavorite',
  async (listingId: string, { rejectWithValue }) => {
    try {
      const response = await favoritesService.addFavorite(listingId);
      return response.favoriteListings;
    } catch (error) {
      return rejectWithValue('Failed to add favorite');
    }
  }
);

export const removeFavorite = createAsyncThunk(
  'favorites/removeFavorite',
  async (listingId: string, { rejectWithValue }) => {
    try {
      const response = await favoritesService.removeFavorite(listingId);
      return response.favoriteListings;
    } catch (error) {
      return rejectWithValue('Failed to remove favorite');
    }
  }
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Favorites
      .addCase(getFavorites.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.favoriteIds = action.payload;
        state.isLoading = false;
      })
      .addCase(getFavorites.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Get Favorite Listings
      .addCase(getFavoriteListings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFavoriteListings.fulfilled, (state, action) => {
        state.favoriteListings = action.payload;
        state.isLoading = false;
      })
      .addCase(getFavoriteListings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Add Favorite
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.favoriteIds = action.payload;
      })

      // Remove Favorite
      .addCase(removeFavorite.fulfilled, (state, action) => {
        state.favoriteIds = action.payload;
        state.favoriteListings = state.favoriteListings.filter((listing) =>
          action.payload.includes(listing.id)
        );
      });
  },
});

export default favoritesSlice.reducer;
