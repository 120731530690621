import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';

interface CategorySearchResult {
  categoryId: string;
  categoryName: string;
  imageUri: string;
  listingCount: number;
}

interface SearchResults {
  searchQuery: string;
  categoryResults: CategorySearchResult[];
  totalListings: number;
}

interface SearchState {
  results: SearchResults | null;
  loading: boolean;
  error: string | null;
}

const initialState: SearchState = {
  results: null,
  loading: false,
  error: null,
};

export const searchListings = createAsyncThunk(
  'search/searchListings',
  async (query: string) => {
    const response = await axiosInstance.get<SearchResults>(
      `/listing/search/categories?query=${encodeURIComponent(query)}`
    );
    // Transform the response data to match our interface
    return {
      searchQuery: response.data.searchQuery,
      categoryResults:
        response.data.categoryResults?.map((result) => ({
          categoryId: result.categoryId,
          categoryName: result.categoryName,
          listingCount: result.listingCount,
          imageUri: result.imageUri,
        })) || [],
      totalListings: response.data.totalListings,
    };
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.results = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchListings.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(searchListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export const { clearSearch } = searchSlice.actions;

export const selectSearchResults = (state: RootState) => state.search.results;
export const selectSearchLoading = (state: RootState) => state.search.loading;
export const selectSearchError = (state: RootState) => state.search.error;

export default searchSlice.reducer;
