import { Button, Paper, Stack, Typography } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';

interface ChatCardProps {
  onClick: () => void;
}

export const ChatCard = ({ onClick }: ChatCardProps) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      bgcolor: 'rgba(0, 0, 0, 0.9)',
      border: '1px solid rgba(255, 215, 0, 0.2)',
      borderRadius: 2,
      width: '100%',
    }}
  >
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MessageIcon sx={{ color: '#FFD700' }} />
        <Typography variant="h6" sx={{ color: '#FFD700' }}>
          Event Communication
        </Typography>
      </Stack>
      
      <Typography variant="body2" sx={{ color: '#fff', opacity: 0.9 }}>
        Start a conversation with the event organizer to discuss your services
        and requirements.
      </Typography>

      <Button
        variant="outlined"
        fullWidth
        onClick={onClick}
        sx={{
          borderColor: '#FFD700',
          color: '#FFD700',
          '&:hover': {
            bgcolor: 'rgba(255, 215, 0, 0.1)',
            borderColor: '#FFD700',
          },
        }}
      >
        Open Chat
      </Button>
    </Stack>
  </Paper>
);
