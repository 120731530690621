import { Avatar, Box } from '@mui/material';
import { formatMessageTime } from '../../../utils/dateUtils.ts';
import { Chat, Message } from '../../../store/slices/chatSlice.ts';
import MinimalListing from './MinimalListing';
import React from 'react';

export interface MessageItemProps {
  message: Message;
  chat: Chat;
  currentUserId: string;
}

export const getChatName = (chat: Chat, isCompany: boolean = false) => {
  if (isCompany) {
    return chat.userFullName || 'User';
  } else {
    return chat.companyName;
  }
};

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  chat,
  currentUserId,
}) => {
  const isCurrentUser = message.senderId === currentUserId;
  const listingMatch = message.text.match(/^@listing:([0-9a-f-]+)/);

  const formatMessageText = (text: string) => {
    // If it's a listing message, don't format it
    if (listingMatch) return text;
    
    // Replace newlines with <br /> for proper line breaks
    return text.split('\n').map((line, i, arr) => (
      <React.Fragment key={i}>
        {line}
        {i < arr.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  if (listingMatch) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
          mb: 1,
          position: 'relative',
          width: '100%',
          px: 2,
        }}
      >
        <MinimalListing listingId={listingMatch[1]} isOwnMessage={isCurrentUser} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
        mb: 1,
        position: 'relative',
        width: '100%',
        px: 2,
      }}
    >
      {!isCurrentUser && (
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: '#D4AF37',
            fontSize: '0.875rem',
            mr: 1,
            alignSelf: 'flex-end',
            mb: 0.5,
          }}
        >
          {getChatName(chat)[0]}
        </Avatar>
      )}
      <Box
        sx={{
          maxWidth: '85%',
          backgroundColor: isCurrentUser ? '#D4AF37' : 'rgba(255, 255, 255, 0.1)',
          color: isCurrentUser ? '#000' : '#fff',
          borderRadius: 2,
          p: 1.5,
          position: 'relative',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(212, 175, 55, 0.1)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          '&::before': isCurrentUser
            ? {
                content: '""',
                position: 'absolute',
                right: -6,
                bottom: 8,
                width: 0,
                height: 0,
                borderTop: '6px solid transparent',
                borderBottom: '6px solid transparent',
                borderLeft: `6px solid #D4AF37`,
              }
            : {
                content: '""',
                position: 'absolute',
                left: -6,
                bottom: 8,
                width: 0,
                height: 0,
                borderTop: '6px solid transparent',
                borderBottom: '6px solid transparent',
                borderRight: '6px solid rgba(255, 255, 255, 0.1)',
              },
        }}
      >
        {formatMessageText(message.text)}
      </Box>
      {isCurrentUser && (
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: '#D4AF37',
            fontSize: '0.875rem',
            ml: 1,
          }}
        >
          {getChatName(chat)[0]}
        </Avatar>
      )}
    </Box>
  );
};

export default MessageItem;
