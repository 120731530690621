import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Box, CircularProgress } from '@mui/material';

interface PowerBIReportProps {
  reportId: string;
  embedToken: string;
  embedUrl: string;
  companyId: string;
}

const PowerBIReport: React.FC<PowerBIReportProps> = ({
  reportId,
  embedToken,
  embedUrl,
  companyId,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Create the filter configuration
  const filters = companyId
    ? [
        {
          $schema: 'http://powerbi.com/product/schema#basic',
          target: {
            table: 'test', // Replace with your actual table name from Power BI
            column: 'CompanyId', // Replace with your actual column name from Power BI
          },
          operator: 'In',
          values: [companyId],
          filterType: models.FilterType.Basic,
        },
      ]
    : [];
  console.log('Filters:', filters);
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        minHeight: 'inherit',
        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: 'none',
          width: '100% !important',
          height: '100% !important',
        },
        '& .power-bi-report': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          width: '100%',
        },
      }}
    >
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: reportId,
          embedUrl: embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          filters: filters, // Add the filters here
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToPage,
            },
            navContentPaneEnabled: true,
            visualRenderedEvents: true,
          },
        }}
        cssClassName="power-bi-report"
        getEmbeddedComponent={(embeddedReport) => {
          console.log('Power BI Report loaded:', embeddedReport);
          // Apply filters after report loads
          if (embeddedReport) {
            setTimeout(async () => {
              try {
                if (companyId) {
                  await embeddedReport.setFilters(filters);
                }
                embeddedReport.reload();
              } catch (error) {
                console.error('Error applying filter:', error);
              }
            }, 1000);
          }
        }}
      />
    </Box>
  );
};

export default PowerBIReport;
