import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { EventPlannerTypeEnum } from '../../../../types/event.ts';

interface EventTypeCardProps {
  name: string;
  type: EventPlannerTypeEnum;
  description: string;
  image: string;
  handleNext: () => void;
  setEventType: (eventType: number) => void;
  isSelected?: boolean;
}

const EventTypeCard: FC<EventTypeCardProps> = ({
  name,
  type,
  description,
  image,
  handleNext,
  setEventType,
  isSelected,
}: EventTypeCardProps) => {
  return (
    <Card
      onClick={() => {
        setEventType(type);
        handleNext();
      }}
      variant="elevation"
      sx={{
        borderRadius: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        border: '2px solid',
        borderColor: isSelected ? '#D4AF37' : 'rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer',
        overflow: 'hidden',
        height: '100%', // Make all cards same height
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          borderColor: '#D4AF37',
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
        },
      }}
    >
      <Box
        sx={{ position: 'relative', paddingTop: '60%' /* 3:5 aspect ratio */ }}
      >
        <CardMedia
          component="img"
          src={image}
          alt={name}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'brightness(0.8)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              filter: 'brightness(1)',
              transform: 'scale(1.05)',
            },
          }}
        />
      </Box>
      <CardHeader
        sx={{
          flexGrow: 1, // Allow content to fill remaining space
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 2,
        }}
        title={
          <Typography
            variant="h6"
            sx={{
              color: '#D4AF37',
              fontWeight: 500,
              textShadow: '0 2px 4px rgba(0,0,0,0.3)',
              mb: 1,
              minHeight: '32px', // Ensure consistent title height
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {name}
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(212, 175, 55, 0.7)',
              minHeight: '40px', // Ensure consistent description height
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {description}
          </Typography>
        }
      />
    </Card>
  );
};

export interface EventTypePickerProps {
  handleNext: () => void;
  setEventType: (eventType: number) => void;
  eventType: number;
}

export const eventTypes = [
  {
    name: 'Wedding',
    type: EventPlannerTypeEnum.Wedding,
    description: 'Plan your perfect wedding day with our expert services',
    image:
      'https://media.istockphoto.com/photos/closeup-of-couple-holding-hands-picture-id1295707411?b=1&k=20&m=1295707411&s=170667a&w=0&h=IaIPqI7So8motTjfEIyBXrepLA0UZ-Sp9nMjsLNFCE4=',
  },
  {
    name: 'Birthday',
    type: EventPlannerTypeEnum.Birthday,
    description: 'Create unforgettable birthday celebrations',
    image:
      'https://th.bing.com/th/id/OIP.NtVXc8p6pDFVGV2wcmt4jQHaEi?rs=1&pid=ImgDetMain',
  },
  {
    name: 'Team Building',
    type: EventPlannerTypeEnum.TeamBuilding,
    description: 'Strengthen your team bonds with engaging activities',
    image:
      'https://www.opstart.ca/wp-content/uploads/2018/09/benefits-of-team-building.jpeg',
  },
  {
    name: 'Bachelor Party',
    type: EventPlannerTypeEnum.BachelorParty,
    description: 'Celebrate the last days of freedom in style',
    image:
      'https://th.bing.com/th/id/R.c326635912b4e583e2e6423b669903e0?rik=NamSXK%2bMUK1Row&pid=ImgRaw&r=0',
  },
  {
    name: 'Seminar',
    type: EventPlannerTypeEnum.Seminar,
    description: 'Organize professional and impactful seminars',
    image:
      'https://th.bing.com/th/id/OIP.ht4qJWbTrmhrUJE8BvZUYwHaE8?rs=1&pid=ImgDetMain',
  },
  {
    name: 'Custom',
    type: EventPlannerTypeEnum.Custom,
    description: 'Create your own unique event experience',
    image: 'https://images.unsplash.com/photo-1511578314322-379afb476865',
  },
];

const EventTypePicker: FC<EventTypePickerProps> = ({
  handleNext,
  setEventType,
  eventType,
}: EventTypePickerProps) => {
  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      {eventTypes.map((type, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box
            sx={{
              animation: `fadeIn 0.8s ease-out ${index * 0.1}s`,
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
              animationFillMode: 'both',
            }}
          >
            <EventTypeCard
              {...type}
              handleNext={handleNext}
              setEventType={setEventType}
              isSelected={eventType === type.type}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default EventTypePicker;
