import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  Close as CloseIcon,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import { apiUrl } from '../../main';
import { useInView } from 'react-intersection-observer';

interface GalleryViewProps {
  imageIds: string[];
}

const MAX_VISIBLE_IMAGES = 7;

const getThumbnailUrl = (id: string) => `${apiUrl}/files/${id}?width=300`;
const getFullImageUrl = (id: string) => `${apiUrl}/files/${id}`;

export const GalleryView: React.FC<GalleryViewProps> = ({ imageIds }) => {
  const theme = useTheme();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleImageLoad = (id: string) => {
    setLoadedImages(prev => new Set(prev).add(id));
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevious = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex(
      selectedImageIndex === 0 ? imageIds.length - 1 : selectedImageIndex - 1
    );
  };

  const handleNext = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex(
      selectedImageIndex === imageIds.length - 1 ? 0 : selectedImageIndex + 1
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handlePrevious();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const remainingCount = Math.max(0, imageIds.length - MAX_VISIBLE_IMAGES);
  const visibleImages = imageIds.slice(0, MAX_VISIBLE_IMAGES);

  return (
    <Box ref={ref}>
      <Grid container spacing={2}>
        {visibleImages.map((id, index) => (
          <Grid item xs={6} sm={4} md={3} key={id}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                border: '2px solid',
                borderColor: 'rgba(212, 175, 55, 0.3)',
                transition: 'all 0.3s ease-in-out',
                animation: 'fadeIn 0.8s ease-out',
                '@keyframes fadeIn': {
                  from: { opacity: 0, transform: 'translateY(20px)' },
                  to: { opacity: 1, transform: 'translateY(0)' },
                },
                '&:hover': {
                  borderColor: '#D4AF37',
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
                  '& .hover-overlay': {
                    opacity: 1,
                  },
                  '& img': {
                    filter: 'brightness(1.1)',
                  },
                },
              }}
              onClick={() => handleImageClick(index)}
            >
              {inView ? (
                <>
                  {!loadedImages.has(id) && (
                    <Skeleton 
                      variant="rectangular" 
                      width="100%" 
                      height="100%" 
                      sx={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  )}
                  <Box
                    component="img"
                    src={getThumbnailUrl(id)}
                    alt="Gallery image"
                    loading="lazy"
                    onLoad={() => handleImageLoad(id)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'all 0.3s ease',
                      filter: 'brightness(0.9)',
                      opacity: loadedImages.has(id) ? 1 : 0,
                    }}
                  />
                </>
              ) : (
                <Skeleton 
                  variant="rectangular" 
                  width="100%" 
                  height="100%" 
                  sx={{ position: 'absolute', top: 0, left: 0 }}
                />
              )}
              <Box
                className="hover-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'white', fontWeight: 'bold' }}
                >
                  Click to view
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        {remainingCount > 0 && (
          <Grid item xs={6} sm={4} md={3}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '100%',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                background:
                  'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px solid transparent',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
              onClick={() => handleImageClick(MAX_VISIBLE_IMAGES)}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  color: 'white',
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  +{remainingCount}
                </Typography>
                <Typography variant="body2">More images</Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <Modal
        open={selectedImageIndex !== null}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.9)',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90vw',
            height: '90vh',
            outline: 'none',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: theme.spacing(2),
              right: theme.spacing(2),
              color: 'white',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.7)',
              },
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          {selectedImageIndex !== null && (
            <>
              <Box
                component="img"
                src={getFullImageUrl(imageIds[selectedImageIndex])}
                alt={`Gallery image ${selectedImageIndex + 1}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              <IconButton
                onClick={handlePrevious}
                sx={{
                  position: 'absolute',
                  left: theme.spacing(2),
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              >
                <NavigateBefore />
              </IconButton>
              <IconButton
                onClick={handleNext}
                sx={{
                  position: 'absolute',
                  right: theme.spacing(2),
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              >
                <NavigateNext />
              </IconButton>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
