import React, { useState } from 'react';
import { Box, Chip, Typography, Popover } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Region } from '../../types/locations';

interface CompactLocationDisplayProps {
  locations: Region[];
}

export const CompactLocationDisplay: React.FC<CompactLocationDisplayProps> = ({
  locations,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeRegion, setActiveRegion] = useState<string | null>(null);
  const [showAll, setShowAll] = useState(false);
  const MAX_VISIBLE = 3;

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    regionId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveRegion(regionId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRegion(null);
  };

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Filter out locations and counties that are not selected
  const selectedLocations = locations?.filter((region) => {
    const hasSelectedCounties = region.counties.some(
      (county) => county.isSelected
    );
    return region.isSelected || hasSelectedCounties;
  });

  if (!selectedLocations?.length) {
    return null;
  }

  const displayedLocations = showAll 
    ? selectedLocations 
    : selectedLocations.slice(0, MAX_VISIBLE);

  const hiddenCount = selectedLocations.length - MAX_VISIBLE;

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 0.5,
        '& .MuiChip-root': {
          maxWidth: '150px',
          '& .MuiChip-label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }
        }
      }}>
        {displayedLocations.map((region) => {
          const selectedCounties = region.counties.filter(
            (county) => county.isSelected
          );

          if (region.isSelected || selectedCounties.length > 5) {
            return (
              <React.Fragment key={region.id}>
                <Box
                  onClick={(e) => handleClick(e, region.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Chip
                    icon={<LocationOnIcon sx={{ fontSize: 16 }} />}
                    deleteIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}
                    onDelete={() => {}}
                    label={`${region.name} ${
                      region.counties.length === selectedCounties.length
                        ? '(All)'
                        : `(${selectedCounties.length})`
                    }`}
                    size="small"
                    variant="outlined"
                    sx={{
                      borderColor: '#D4AF37',
                      borderWidth: 1,
                      color: '#D4AF37',
                      height: '24px',
                      fontSize: '0.75rem',
                      '& .MuiChip-icon': {
                        color: '#D4AF37',
                      },
                      '& .MuiChip-deleteIcon': {
                        color: '#D4AF37',
                      },
                      '&:hover': {
                        backgroundColor: 'rgba(212, 175, 55, 0.08)',
                      },
                    }}
                  />
                </Box>
                <Popover
                  open={Boolean(anchorEl) && activeRegion === region.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      mt: 1,
                      p: 2,
                      maxWidth: '300px',
                      backgroundColor: '#000000',
                      border: '1px solid rgba(255, 215, 0, 0.3)',
                      borderRadius: 1,
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, color: '#FFD700' }}
                  >
                    {region.name} Counties:
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    gap: 0.5,
                    maxHeight: '120px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'rgba(0, 0, 0, 0.1)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'rgba(212, 175, 55, 0.5)'
                    }
                  }}>
                    {region.counties.filter(c => c.isSelected).map((county) => (
                      <Chip
                        key={county.id}
                        label={county.name}
                        size="small"
                        variant="outlined"
                        sx={{
                          borderColor: '#D4AF37',
                          borderWidth: 1,
                          color: '#D4AF37',
                          height: '24px',
                          fontSize: '0.75rem',
                          '&:hover': {
                            backgroundColor: 'rgba(212, 175, 55, 0.08)',
                          },
                        }}
                      />
                    ))}
                  </Box>
                </Popover>
              </React.Fragment>
            );
          }

          return selectedCounties.map((county) => (
            <Chip
              key={`${region.id}-${county.id}`}
              icon={<LocationOnIcon sx={{ fontSize: 16 }} />}
              label={`${region.name.split(' ')[0]} - ${county.name}`}
              size="small"
              variant="outlined"
              sx={{
                borderColor: '#D4AF37',
                borderWidth: 1,
                color: '#D4AF37',
                height: '24px',
                fontSize: '0.75rem',
                '& .MuiChip-icon': {
                  color: '#D4AF37',
                },
                '&:hover': {
                  backgroundColor: 'rgba(212, 175, 55, 0.08)',
                },
              }}
            />
          ));
        })}
        
        {/* Show more/less button */}
        {selectedLocations.length > MAX_VISIBLE && (
          <Chip
            icon={showAll ? <ExpandLessIcon sx={{ fontSize: 16 }} /> : <ExpandMoreIcon sx={{ fontSize: 16 }} />}
            label={showAll ? 'Show Less' : `+${hiddenCount} more`}
            onClick={handleToggleShowAll}
            size="small"
            variant="outlined"
            sx={{
              borderColor: '#D4AF37',
              borderWidth: 1,
              color: '#D4AF37',
              height: '24px',
              fontSize: '0.75rem',
              cursor: 'pointer',
              '& .MuiChip-icon': {
                color: '#D4AF37',
              },
              '&:hover': {
                backgroundColor: 'rgba(212, 175, 55, 0.08)',
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
