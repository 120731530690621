import { FC, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEventById,
  selectCurrentEvent,
  selectEventError,
  selectEventStatus,
} from '../../../store/slices/eventSlice';
import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import Header from '../../../components/header';
import { eventTypes } from '../../planner/event/components/EventTypePicker';
import { fetchListingsByIds } from '../../../store/slices/listingSlice';
import { AppDispatch, RootState } from '../../../store/store';
import { ListingCard } from '../../ListingsPage/ListingCard';
import {
  fetchCategories,
  selectCategories,
} from '../../../store/slices/categorySlice';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../../../store/slices/subCategorySlice';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../../store/slices/subCategoryServiceSlice';
import CompanyEventOverview from './components/CompanyEventOverview.tsx';
import { ChatCard } from './components/ChatCard';
import { selectCurrentCompany } from '../../../store/slices/companySlice.ts';

const CompanyEventPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');
  const company = useSelector(selectCurrentCompany);
  const event = useSelector(selectCurrentEvent);
  const listings = useSelector((state: RootState) => state.listings.listings);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const status = useSelector(selectEventStatus);
  const error = useSelector(selectEventError);

  useEffect(() => {
    if (!eventId) {
      navigate('/companies');
      return;
    }

    dispatch(fetchEventById(eventId));
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    dispatch(fetchSubCategoryServices());
  }, [dispatch, eventId, navigate]);

  // Fetch matching listings when event is loaded
  useEffect(() => {
    if (event?.matchingListingIds?.length) {
      dispatch(fetchListingsByIds(event.matchingListingIds));
    }
  }, [event?.matchingListingIds, dispatch]);

  if (status === 'pending') {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Typography>Loading event...</Typography>
      </Box>
    );
  }

  if (error || !event) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error || 'Event not found'}</Typography>
      </Box>
    );
  }

  const eventType = eventTypes.find(
    (type) => type.type === event.eventType
  ) || { name: event.eventType };

  // Filter to show only company listings that are in the event's matching listings
  const companyListings = listings.filter(
    (listing) =>
      listing.companyId === companyId &&
      event.matchingListingIds?.includes(listing.id)
  );
  const hasMatchingListings = companyListings.length > 0;

  const handleStartChat = (customerId: string) => {
    if (companyId) {
      const params = new URLSearchParams({
        mode: 'new-event-chat',
        companyId: companyId,
        eventId: event.id,
        isCompany: 'true',
        listingId: '',
        customerId,
      });
      navigate(`/messages?${params.toString()}`);
    }
  };

  return (
    <>
      <Header
        title={event.eventConfig.eventName}
        subtitle={`${eventType?.name}`}
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Stack spacing={3}>
          {/* Chat Card - Only shown when companyId is available */}
          {companyId && <ChatCard onClick={() => handleStartChat(event?.createdBy)} />}
          
          {/* Event Overview */}
          <Box sx={{ mt: 2 }}>
            <CompanyEventOverview
              event={event}
              companyListings={companyListings}
              companyId={companyId}
            />
          </Box>

          {/* Matching Listings Section - Only shown when companyId is available */}
          {companyId && (
            <Paper
              elevation={0}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                border: '1px solid rgba(255, 215, 0, 0.2)',
                borderRadius: 2,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center" mb={3}>
                <Typography variant="h5" sx={{ color: '#FFD700' }}>
                  Your Matching Listings
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#FFD700' }}>
                  ({companyListings.length})
                </Typography>
              </Stack>

              {hasMatchingListings ? (
                <Grid container spacing={3}>
                  {companyListings.map((listing) => (
                    <Grid item xs={12} sm={6} md={4} key={listing.id}>
                      <ListingCard
                        listing={listing}
                        categories={categories}
                        subCategories={subCategories}
                        subCategoryServices={subCategoryServices}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <Typography sx={{ color: '#fff', mb: 2 }}>
                    None of your listings match this event's requirements.
                  </Typography>
                  <Typography sx={{ color: '#FFD700' }}>
                    Consider updating your listings to match the event categories
                    and location.
                  </Typography>
                </Box>
              )}
            </Paper>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default CompanyEventPage;
