import React, { useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { apiUrl } from '../../main';
import { useInView } from 'react-intersection-observer';

interface ImageViewProps {
  fileId?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  sx?: any;
  width?: number;
  priority?: boolean;
  fullWidth?: boolean;
}

const StyledImage = styled('img')<{ customSx?: any }>(
  ({ theme, customSx }) => ({
    transition: 'all 0.3s ease',
    maxWidth: '100%',
    ...(customSx || {}),
  })
);

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const getImageUrl = (fileId: string, width?: number) => {
  const url = `${apiUrl}/files/${fileId}`;
  return width ? `${url}?width=${width}` : url;
};

export const ImageView: React.FC<ImageViewProps> = ({
  fileId,
  alt = '',
  className = '',
  style = {},
  sx = {},
  width,
  priority = false,
  fullWidth = false,
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    skip: priority,
  });

  if (!fileId || hasError) {
    return (
      <ImageContainer style={style}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            ...sx,
          }}
        >
          <BrokenImageIcon
            sx={{
              fontSize: '3rem',
              color: 'rgba(0, 0, 0, 0.3)',
            }}
          />
        </Box>
      </ImageContainer>
    );
  }

  return (
    <ImageContainer ref={ref} style={style}>
      {(inView || priority) && (
        <>
          {!isLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              }}
            />
          )}
          <StyledImage
            src={getImageUrl(fileId, width)}
            alt={alt}
            className={className}
            onError={() => setHasError(true)}
            onLoad={() => setIsLoaded(true)}
            loading={priority ? 'eager' : 'lazy'}
            customSx={{
              ...sx,
              opacity: isLoaded ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              width: fullWidth ? '100%' : 'auto',
              height: fullWidth ? '100%' : 'auto',
            }}
          />
        </>
      )}
    </ImageContainer>
  );
};
