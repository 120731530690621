import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import StyledContainer from '../../components/StyledContainer.tsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteEventAsync,
  fetchEvents,
  selectEventError,
  selectEvents,
  selectEventStatus,
  updateEventAsync,
} from '../../store/slices/eventSlice';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../store/store';
import EventPlanCard from './components/EventPlanCard.tsx';
import { EventStatusEnum } from '../../types/event';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';
import i18next from '../../utilis/i18next.ts';

const getStatusLabel = (status: EventStatusEnum | 'all') => {
  if (status === 'all') return 'all';
  switch (status) {
    case EventStatusEnum.Draft:
      return 'draft';
    case EventStatusEnum.AwaitingApproval:
      return 'pending approval';
    case EventStatusEnum.Approved:
      return 'approved';
    case EventStatusEnum.Rejected:
      return 'rejected';
    default:
      return 'unknown';
  }
};

const PlannerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector(selectEvents);
  const status = useSelector(selectEventStatus);
  const error = useSelector(selectEventError);
  const [statusFilter, setStatusFilter] = useState<EventStatusEnum | 'all'>(
    EventStatusEnum.Approved
  );
  useDocumentTitle(i18next.t('Views.PlannerPage.Title'));
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchEvents());
    }
  }, [status, dispatch]);

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: EventStatusEnum | 'all'
  ) => {
    if (newFilter !== null) {
      setStatusFilter(newFilter);
    }
  };

  const filteredEvents = events?.filter((event) =>
    statusFilter === 'all'
      ? event.status !== EventStatusEnum.Cancelled
      : event.status === statusFilter
  );

  const handleAddEvent = () => {
    navigate('/planner/event');
  };

  const handleEditEvent = (eventId: string) => {
    navigate(`/planner/event?id=${eventId}`);
  };

  const handleCancelEvent = async (eventId: string) => {
    try {
      const eventToCancel = events?.find((e) => e.id === eventId);
      if (eventToCancel) {
        const updatedEvent = {
          ...eventToCancel,
          status: EventStatusEnum.Cancelled,
        };
        await dispatch(updateEventAsync(updatedEvent)).unwrap();
        dispatch(fetchEvents()); // Refresh the list
      }
    } catch (error) {
      console.error('Failed to cancel event:', error);
    }
  };

  const handleDeleteEvent = async (eventId: string) => {
    try {
      await dispatch(deleteEventAsync(eventId)).unwrap();
      dispatch(fetchEvents()); // Refresh the list after deletion
    } catch (error) {
      console.error('Failed to delete event:', error);
    }
  };

  const renderEventList = () => {
    if (!filteredEvents || filteredEvents.length === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          width="100%"
        >
          <Typography variant="body1" color="textSecondary">
            {statusFilter === 'all'
              ? 'No events found. Create your first event to get started!'
              : `No ${getStatusLabel(statusFilter)} events found.`}
          </Typography>
        </Box>
      );
    }

    return filteredEvents.map((event) => (
      <Grid item xs={12} sm={6} md={4} key={event.id}>
        <EventPlanCard
          event={event}
          handleEditEvent={handleEditEvent}
          handleCancelEvent={handleCancelEvent}
          handleDeleteEvent={handleDeleteEvent}
        />
      </Grid>
    ));
  };

  return (
    <Box>
      <Header
        title="Event Planner"
        subtitle="Plan and manage your events"
        showBackButton={true}
      />
      <StyledContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ color: '#D4AF37', mb: 1 }}>
              Event Planner
            </Typography>
            <ToggleButtonGroup
              value={statusFilter}
              exclusive
              onChange={handleStatusFilterChange}
              aria-label="event status filter"
              size="small"
              sx={{
                '& .MuiToggleButton-root': {
                  color: '#D4AF37',
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(212, 175, 55, 0.1)',
                    color: '#D4AF37',
                    '&:hover': {
                      backgroundColor: 'rgba(212, 175, 55, 0.2)',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.05)',
                  },
                },
              }}
            >
              <ToggleButton value={EventStatusEnum.Approved}>
                Approved
              </ToggleButton>
              <ToggleButton value={EventStatusEnum.Draft}>Drafts</ToggleButton>
              <ToggleButton value={EventStatusEnum.AwaitingApproval}>
                Pending
              </ToggleButton>
              <ToggleButton value={EventStatusEnum.Rejected}>
                Rejected
              </ToggleButton>
              <ToggleButton value="all">All Events</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddEvent}
            sx={{
              color: '#D4AF37',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              padding: '8px 24px',
              '&:hover': {
                borderColor: '#D4AF37',
                backgroundColor: 'rgba(212, 175, 55, 0.08)',
              },
            }}
          >
            Create Event
          </Button>
        </Box>

        <Grid container spacing={3}>
          {status === 'pending' ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
              width="100%"
            >
              <CircularProgress sx={{ color: '#D4AF37' }} />
            </Box>
          ) : error ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
              width="100%"
            >
              <Typography color="error">{error}</Typography>
            </Box>
          ) : (
            renderEventList()
          )}
        </Grid>
      </StyledContainer>
    </Box>
  );
};

export default PlannerPage;
