import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { addFavorite, removeFavorite } from '../../store/slices/favoritesSlice';

interface FavoriteButtonProps {
  listingId: string;
  size?: 'small' | 'medium' | 'large';
  color?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  listingId,
  size = 'medium',
  color = '#D4AF37', // Gold color
  tooltipPlacement = 'top',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { favoriteIds } = useSelector((state: RootState) => state.favorites);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    // Check if this listing is in the favorites
    setIsFavorite(favoriteIds.includes(listingId));
  }, [favoriteIds, listingId]);

  const handleToggleFavorite = async (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering parent click events
    
    if (isFavorite) {
      await dispatch(removeFavorite(listingId));
    } else {
      await dispatch(addFavorite(listingId));
    }
  };

  return (
    <Tooltip
      title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
      placement={tooltipPlacement}
    >
      <IconButton
        onClick={handleToggleFavorite}
        size={size}
        sx={{
          color: isFavorite ? color : 'rgba(255, 255, 255, 0.7)',
          transition: 'all 0.3s ease',
          '&:hover': {
            color,
            transform: 'scale(1.1)',
          },
        }}
      >
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default FavoriteButton;
