import React, { FC, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  CardHeader,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Container,
  Divider,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Stack,
  TextField,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { fetchListingsByCompany, selectListings } from '../../store/slices/listingSlice';
import listingStatisticsService, { 
  ListingStatisticsResponse, 
  DailyStatistic,
  CompanyListingStatisticsResponse,
  UniqueViewsStats
} from '../../services/listingStatisticsService';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { 
  BarChart, 
  LineChart, 
  PieChart,
  SparkLineChart, 
  axisClasses
} from '@mui/x-charts';
import Header from '../../components/header';
import WebIcon from '@mui/icons-material/Web';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonIcon from '@mui/icons-material/Person';
import DevicesIcon from '@mui/icons-material/Devices';
import FilterListIcon from '@mui/icons-material/FilterList';

// Define interaction type interface
interface InteractionType {
  id: string;
  label: string;
  color: string;
  visible: boolean;
  icon: React.ReactNode;
}

// Date range presets
type DateRangePreset = '7days' | '30days' | '90days' | 'year' | 'custom';

const CompanyStatisticsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');
  const listingIdParam = searchParams.get('listingId');
  const dispatch = useDispatch<AppDispatch>();
  const listings = useSelector(selectListings);
  
  const [companyStatistics, setCompanyStatistics] = useState<CompanyListingStatisticsResponse | null>(null);
  const [selectedListingId, setSelectedListingId] = useState<string>('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [uniqueViewsStats, setUniqueViewsStats] = useState<Record<string, UniqueViewsStats>>({});
  const [loadingUniqueViews, setLoadingUniqueViews] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Date range state
  const [dateRangePreset, setDateRangePreset] = useState<DateRangePreset>('7days');
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(() => {
    const date = dayjs();
    return date.subtract(7, 'day'); // Default to 7 days ago
  });
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [showDatePickers, setShowDatePickers] = useState(false);

  // Interaction types state with visibility toggle
  const [interactionTypes, setInteractionTypes] = useState<InteractionType[]>([
    { id: 'views', label: 'Views', color: '#D4AF37', visible: true, icon: <VisibilityIcon /> },
    { id: 'favorites', label: 'Favorites', color: '#E57373', visible: true, icon: <FavoriteIcon /> },
    { id: 'linkOpens', label: 'Links Opened', color: '#64B5F6', visible: true, icon: <LinkIcon /> },
    { id: 'chats', label: 'Chats Started', color: '#81C784', visible: true, icon: <ChatIcon /> },
    { id: 'phoneClicks', label: 'Phone Clicks', color: '#9575CD', visible: true, icon: <PhoneIcon /> },
    { id: 'eventHits', label: 'Event Hits', color: '#FF9800', visible: true, icon: <CalendarTodayIcon /> },
  ]);

  // Set the document title
  useDocumentTitle('Company Statistics');

  useEffect(() => {
    if (!companyId) {
      navigate('/companies');
      return;
    }

    // Fetch listings for the company
    dispatch(fetchListingsByCompany(companyId));
  }, [companyId, dispatch, navigate]);

  useEffect(() => {
    if (companyId && listings.length > 0) {
      fetchCompanyStatistics();
      
      // If a listingId was provided in the URL, set it as the selected listing
      if (listingIdParam && listings.some(listing => listing.id === listingIdParam)) {
        setSelectedListingId(listingIdParam);
      }
    }
  }, [companyId, listings, listingIdParam]);

  // Apply date range filter automatically when preset changes
  useEffect(() => {
    if (companyId && listings.length > 0 && !loading) {
      // Only fetch new data if we've already loaded data at least once
      // This prevents double fetching on initial load
      if (companyStatistics) {
        fetchCompanyStatistics();
      }
    }
  }, [dateRangePreset]); // Only trigger when date range preset changes

  // Update date range when preset changes
  useEffect(() => {
    const now = dayjs();
    let start = dayjs();
    
    switch (dateRangePreset) {
      case '7days':
        start = now.subtract(7, 'day');
        break;
      case '30days':
        start = now.subtract(30, 'day');
        break;
      case '90days':
        start = now.subtract(90, 'day');
        break;
      case 'year':
        start = now.subtract(1, 'year');
        break;
      case 'custom':
        // Don't change the dates when switching to custom
        return;
      default:
        start = now.subtract(7, 'day');
    }
    
    setStartDate(start);
    setEndDate(now);
  }, [dateRangePreset]);

  const fetchCompanyStatistics = async () => {
    if (!companyId || listings.length === 0) return;

    setLoading(true);
    setError(null);

    try {
      // Get all listing IDs for this company
      const listingIds = listings.map(listing => listing.id);
      
      // Convert dayjs objects to JavaScript Date objects for the API call
      const jsStartDate = startDate.toDate();
      const jsEndDate = endDate.toDate();
      
      // Use the date range version of the API
      const stats = await listingStatisticsService.getCompanyStatisticsWithDateRange(
        companyId, 
        listingIds,
        jsStartDate,
        jsEndDate
      );
      
      // Enhance with listing names
      const enhancedStats = {
        ...stats,
        listingStatistics: stats.listingStatistics.map(stat => {
          const listing = listings.find(l => l.id === stat.listingId);
          return {
            ...stat,
            listingName: listing ? listing.name : 'Unknown Listing'
          };
        })
      };
      
      setCompanyStatistics(enhancedStats);
      
      // After getting regular statistics, fetch unique views for each listing
      fetchUniqueViewsForListings(enhancedStats.listingStatistics);
      
    } catch (err) {
      console.error('Error fetching company statistics:', err);
      setError('Failed to load company statistics');
    } finally {
      setLoading(false);
    }
  };

  // New function to fetch unique views for all listings
  const fetchUniqueViewsForListings = async (listingStats: ListingStatisticsResponse[]) => {
    try {
      setLoadingUniqueViews(true);
      
      // Create an object to store unique views stats for each listing
      const uniqueViewsData: Record<string, UniqueViewsStats> = {};
      
      // Fetch unique views for each listing
      const uniqueViewsPromises = listingStats.map(async (listing) => {
        try {
          const uniqueStats = await listingStatisticsService.getUniqueViews(
            listing.listingId,
            startDate.toDate(),
            endDate.toDate()
          );
          
          // Store the unique views stats with the listing ID as the key
          uniqueViewsData[listing.listingId] = uniqueStats;
        } catch (err) {
          console.error(`Error fetching unique views for listing ${listing.listingId}:`, err);
        }
      });
      
      // Wait for all requests to complete
      await Promise.all(uniqueViewsPromises);
      
      // Update state with unique views data
      setUniqueViewsStats(uniqueViewsData);
    } catch (err) {
      console.error('Error fetching unique views:', err);
    } finally {
      setLoadingUniqueViews(false);
    }
  };

  const handleBack = () => {
    navigate(`/company?companyId=${companyId}&view=listings`);
  };
  
  // Handle listing selection change
  const handleListingChange = (event: SelectChangeEvent<string>) => {
    const newListingId = event.target.value;
    setSelectedListingId(newListingId);
    
    // Update URL to reflect the selected listing
    const newSearchParams = new URLSearchParams(location.search);
    if (newListingId === 'all') {
      newSearchParams.delete('listingId');
    } else {
      newSearchParams.set('listingId', newListingId);
    }
    
    // Replace current URL with updated one (without creating a new history entry)
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    }, { replace: true });
  };
  
  // Handle interaction visibility toggle
  const handleInteractionToggle = (interactionId: string) => {
    setInteractionTypes(prevTypes => 
      prevTypes.map(type => 
        type.id === interactionId ? { ...type, visible: !type.visible } : type
      )
    );
  };

  // Select all interactions
  const handleSelectAll = () => {
    setInteractionTypes(prevTypes => 
      prevTypes.map(type => ({ ...type, visible: true }))
    );
  };

  // Deselect all interactions
  const handleDeselectAll = () => {
    setInteractionTypes(prevTypes => 
      prevTypes.map(type => ({ ...type, visible: false }))
    );
  };

  // Handle date range preset change
  const handleDateRangePresetChange = (preset: DateRangePreset) => {
    setDateRangePreset(preset);
    setShowDatePickers(preset === 'custom');
  };

  // Handle manual date changes (for custom date range)
  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setStartDate(date);
      if (dateRangePreset !== 'custom') {
        setDateRangePreset('custom');
      }
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setEndDate(date);
      if (dateRangePreset !== 'custom') {
        setDateRangePreset('custom');
      }
    }
  };

  // Apply date filters and refresh data
  const handleApplyDateFilter = () => {
    // Log the selected date range for debugging
    console.log('Applying date filter:', {
      preset: dateRangePreset,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    });
    
    // Refresh the data with the current date range
    fetchCompanyStatistics();
  };

  if (loading && !companyStatistics) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!companyStatistics) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <Typography>No statistics available</Typography>
      </Box>
    );
  }

  // Get current statistics based on selected listing
  const getCurrentStats = () => {
    if (selectedListingId === 'all') {
      // For "All Listings", combine daily statistics
      const combinedDailyViews: { [date: string]: number } = {};
      const combinedDailyFavorites: { [date: string]: number } = {};
      const combinedDailyLinkOpens: { [date: string]: number } = {};
      const combinedDailyChats: { [date: string]: number } = {};
      const combinedDailyPhoneClicks: { [date: string]: number } = {};
      const combinedDailyEventHits: { [date: string]: number } = {};
      
      // Combine all listing data
      companyStatistics.listingStatistics.forEach(listingStat => {
        listingStat.dailyViews.forEach(item => {
          const date = item.date;
          combinedDailyViews[date] = (combinedDailyViews[date] || 0) + item.count;
        });
        
        listingStat.dailyFavorites.forEach(item => {
          const date = item.date;
          combinedDailyFavorites[date] = (combinedDailyFavorites[date] || 0) + item.count;
        });
        
        listingStat.dailyLinkOpens.forEach(item => {
          const date = item.date;
          combinedDailyLinkOpens[date] = (combinedDailyLinkOpens[date] || 0) + item.count;
        });
        
        listingStat.dailyChats.forEach(item => {
          const date = item.date;
          combinedDailyChats[date] = (combinedDailyChats[date] || 0) + item.count;
        });

        listingStat.dailyPhoneClicks.forEach(item => {
          const date = item.date;
          combinedDailyPhoneClicks[date] = (combinedDailyPhoneClicks[date] || 0) + item.count;
        });

        // Add null check for dailyEventHits
        if (listingStat.dailyEventHits && Array.isArray(listingStat.dailyEventHits)) {
          listingStat.dailyEventHits.forEach(item => {
            const date = item.date;
            combinedDailyEventHits[date] = (combinedDailyEventHits[date] || 0) + item.count;
          });
        }
      });
      
      // Convert combined objects to arrays
      const dailyViews = Object.entries(combinedDailyViews).map(([date, count]) => ({ date, count }));
      const dailyFavorites = Object.entries(combinedDailyFavorites).map(([date, count]) => ({ date, count }));
      const dailyLinkOpens = Object.entries(combinedDailyLinkOpens).map(([date, count]) => ({ date, count }));
      const dailyChats = Object.entries(combinedDailyChats).map(([date, count]) => ({ date, count }));
      const dailyPhoneClicks = Object.entries(combinedDailyPhoneClicks).map(([date, count]) => ({ date, count }));
      const dailyEventHits = Object.entries(combinedDailyEventHits).map(([date, count]) => ({ date, count }));
      
      // Sort by date
      const sortByDate = (a: DailyStatistic, b: DailyStatistic) => 
        new Date(a.date).getTime() - new Date(b.date).getTime();
        
      return {
        viewCount: companyStatistics.totalViews,
        favoriteCount: companyStatistics.totalFavorites,
        linkOpenCount: companyStatistics.totalLinkOpens,
        chatStartCount: companyStatistics.totalChats,
        phoneClickCount: companyStatistics.totalPhoneClicks,
        eventHitCount: companyStatistics.totalEventHits,
        dailyViews: dailyViews.sort(sortByDate),
        dailyFavorites: dailyFavorites.sort(sortByDate),
        dailyLinkOpens: dailyLinkOpens.sort(sortByDate),
        dailyChats: dailyChats.sort(sortByDate),
        dailyPhoneClicks: dailyPhoneClicks.sort(sortByDate),
        dailyEventHits: dailyEventHits.sort(sortByDate)
      };
    } else {
      // Get statistics for the selected listing
      const listingStat = companyStatistics.listingStatistics.find(
        stat => stat.listingId === selectedListingId
      );
      
      if (!listingStat) {
        return null;
      }
      
      return listingStat;
    }
  };
  
  const currentStats = getCurrentStats();
  
  if (!currentStats) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <Typography>No statistics available for the selected listing</Typography>
      </Box>
    );
  }

  // Format daily statistics for MUI X Charts
  const formatDailyData = (dailyStats: DailyStatistic[]) => {
    // Determine aggregation level based on date range
    const daysDifference = endDate.diff(startDate, 'day');
    
    // For 7 days or less, show daily data (no aggregation)
    if (daysDifference <= 7) {
      return dailyStats.map(stat => ({
        date: new Date(stat.date),
        count: stat.count
      }));
    }
    
    // Aggregate data based on date range
    const aggregatedData = new Map<string, number>();
    
    dailyStats.forEach(stat => {
      const date = new Date(stat.date);
      let key: string;
      
      if (daysDifference <= 30) {
        // For 8-30 days, aggregate by week
        const weekStart = dayjs(date).startOf('week').format('YYYY-MM-DD');
        key = `Week of ${weekStart}`;
      } else if (daysDifference <= 90) {
        // For 31-90 days, aggregate by month
        key = date.toLocaleString('default', { month: 'short', year: 'numeric' });
      } else {
        // For >90 days, aggregate by year or quarter
        const quarter = Math.floor(date.getMonth() / 3) + 1;
        key = `Q${quarter} ${date.getFullYear()}`;
      }
      
      aggregatedData.set(key, (aggregatedData.get(key) || 0) + stat.count);
    });
    
    // Convert Map to array and sort chronologically
    return Array.from(aggregatedData.entries())
      .map(([dateLabel, count]) => ({ 
        date: dateLabel, 
        count 
      }))
      .sort((a, b) => {
        // Custom sort that works with our string labels
        // For daily data, we've already converted to Date objects earlier
        if (a.date instanceof Date && b.date instanceof Date) {
          return a.date.getTime() - b.date.getTime();
        }
        
        // For weekly data (starts with "Week of")
        if (typeof a.date === 'string' && a.date.startsWith('Week of') && 
            typeof b.date === 'string' && b.date.startsWith('Week of')) {
          const dateA = new Date(a.date.substring(8));
          const dateB = new Date(b.date.substring(8));
          return dateA.getTime() - dateB.getTime();
        }
        
        // For monthly data (e.g., "Jan 2025")
        if (typeof a.date === 'string' && typeof b.date === 'string') {
          // If they're quarters, compare them directly
          if (a.date.startsWith('Q') && b.date.startsWith('Q')) {
            const yearA = parseInt(a.date.split(' ')[1]);
            const yearB = parseInt(b.date.split(' ')[1]);
            
            if (yearA !== yearB) return yearA - yearB;
            
            const quarterA = parseInt(a.date.charAt(1));
            const quarterB = parseInt(b.date.charAt(1));
            return quarterA - quarterB;
          }
          
          // Otherwise try to parse as dates
          try {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA.getTime() - dateB.getTime();
          } catch {
            // Fallback to string comparison
            return a.date.localeCompare(b.date);
          }
        }
        
        // Default fallback
        return 0;
      });
  };

  const chartSx = {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)'
    }
  };

  // Prepare data for charts
  const viewsData = formatDailyData(currentStats.dailyViews);
  const favoritesData = formatDailyData(currentStats.dailyFavorites);
  const linkOpensData = formatDailyData(currentStats.dailyLinkOpens);
  const chatsData = formatDailyData(currentStats.dailyChats);
  const phoneClicksData = formatDailyData(currentStats.dailyPhoneClicks);
  const eventHitsData = formatDailyData(currentStats.dailyEventHits || []);

  // Get comparison data for pie chart - only include visible interaction types
  const pieChartData = [
    { id: 0, value: currentStats.viewCount, label: 'Views' },
    { id: 1, value: currentStats.favoriteCount, label: 'Favorites' },
    { id: 2, value: currentStats.linkOpenCount, label: 'Links Opened' },
    { id: 3, value: currentStats.chatStartCount, label: 'Chats Started' },
    { id: 4, value: currentStats.phoneClickCount, label: 'Phone Clicks' },
    { id: 5, value: currentStats.eventHitCount || 0, label: 'Event Hits' }
  ].filter((_, index) => index < interactionTypes.length && interactionTypes[index].visible);

  // Get interaction colors from the interactionTypes array
  const colors = Object.fromEntries(
    interactionTypes.map(type => [type.id, type.color])
  );
  
  // Color array for charts - only include visible interaction types
  const colorArray = interactionTypes
    .filter(type => type.visible)
    .map(type => type.color);

  // Create series array for line chart - only include visible interactions
  const chartSeries = interactionTypes
    .filter(type => type.visible)
    .map(type => {
      let data;
      switch(type.id) {
        case 'views': 
          data = viewsData;
          break;
        case 'favorites':
          data = favoritesData;
          break;
        case 'linkOpens':
          data = linkOpensData;
          break;
        case 'chats':
          data = chatsData;
          break;
        case 'phoneClicks':
          data = phoneClicksData;
          break;
        case 'eventHits':
          data = eventHitsData;
          break;
        default:
          data = [];
      }
      
      return {
        data: data.map(item => item.count),
        label: type.label,
        color: type.color
      };
    });

  const getPageTitle = () => {
    if (selectedListingId === 'all') {
      return 'All Listings Statistics';
    } else {
      const listing = companyStatistics.listingStatistics.find(stat => stat.listingId === selectedListingId);
      return listing?.listingName ? `${listing.listingName} Statistics` : 'Listing Statistics';
    }
  };

  return (
    <Box sx={{ 
      position: 'relative',
      background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      minHeight: 'calc(100vh - 64px)',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        opacity: 0.7,
      }
    }}>
      <Header
        title={getPageTitle()}
        subtitle="View company performance statistics"
        showBackButton={false}
      />
      
      <Container maxWidth="lg" sx={{ py: 3 }}>
        {/* Back button */}
        <Box sx={{ mb: 3 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              backgroundColor: 'rgba(212, 175, 55, 0.05)',
              border: '2px solid',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              color: '#D4AF37',
              padding: '10px 24px',
              transition: 'all 0.3s ease',
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              borderRadius: '4px',
              letterSpacing: '0.5px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#D4AF37',
                borderColor: '#D4AF37',
                color: '#000',
                boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
                transform: 'translateY(-1px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
              },
            }}
          >
            Back to Listings
          </Button>
        </Box>
        
        {/* Listing Selector */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 4, 
            backgroundColor: 'rgba(18, 18, 18, 0.8)', 
            backdropFilter: 'blur(10px)', 
            border: '1px solid rgba(212, 175, 55, 0.1)' 
          }}
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel 
              id="listing-select-label" 
              sx={{ 
                color: 'rgba(212, 175, 55, 0.8)',
                '&.Mui-focused': {
                  color: '#D4AF37',
                }
              }}
            >
              Select Listing
            </InputLabel>
            <Select
              labelId="listing-select-label"
              id="listing-select"
              value={selectedListingId}
              onChange={handleListingChange}
              label="Select Listing"
              sx={{ 
                color: '#D4AF37',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D4AF37',
                },
              }}
            >
              <MenuItem value="all">All Listings</MenuItem>
              {companyStatistics.listingStatistics.map((listing) => (
                <MenuItem key={listing.listingId} value={listing.listingId}>
                  {listing.listingName || listing.listingId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
        
        {/* Date Range Filter */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 4, 
            backgroundColor: 'rgba(18, 18, 18, 0.8)', 
            backdropFilter: 'blur(10px)', 
            border: '1px solid rgba(212, 175, 55, 0.1)' 
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ color: 'rgba(212, 175, 55, 0.9)', mb: 2 }}>
            Date Range
          </Typography>
          
          {/* Quick date selector buttons */}
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={1} 
            sx={{ 
              mb: 2,
              flexWrap: { sm: 'wrap' },
              gap: { sm: 1 }
            }}
          >
            <Button 
              variant={dateRangePreset === '7days' ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleDateRangePresetChange('7days')}
              startIcon={<TodayIcon />}
              sx={{ 
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: dateRangePreset === '7days' ? '#000' : 'rgba(212, 175, 55, 0.9)',
                backgroundColor: dateRangePreset === '7days' ? '#D4AF37' : 'transparent',
                '&:hover': {
                  backgroundColor: dateRangePreset === '7days' ? '#D4AF37' : 'rgba(212, 175, 55, 0.1)',
                  borderColor: '#D4AF37',
                }
              }}
            >
              Last 7 Days
            </Button>
            <Button 
              variant={dateRangePreset === '30days' ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleDateRangePresetChange('30days')}
              startIcon={<CalendarTodayIcon />}
              sx={{ 
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: dateRangePreset === '30days' ? '#000' : 'rgba(212, 175, 55, 0.9)',
                backgroundColor: dateRangePreset === '30days' ? '#D4AF37' : 'transparent',
                '&:hover': {
                  backgroundColor: dateRangePreset === '30days' ? '#D4AF37' : 'rgba(212, 175, 55, 0.1)',
                  borderColor: '#D4AF37',
                }
              }}
            >
              Last 30 Days
            </Button>
            <Button 
              variant={dateRangePreset === '90days' ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleDateRangePresetChange('90days')}
              startIcon={<DateRangeIcon />}
              sx={{ 
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: dateRangePreset === '90days' ? '#000' : 'rgba(212, 175, 55, 0.9)',
                backgroundColor: dateRangePreset === '90days' ? '#D4AF37' : 'transparent',
                '&:hover': {
                  backgroundColor: dateRangePreset === '90days' ? '#D4AF37' : 'rgba(212, 175, 55, 0.1)',
                  borderColor: '#D4AF37',
                }
              }}
            >
              Last 90 Days
            </Button>
            <Button 
              variant={dateRangePreset === 'year' ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleDateRangePresetChange('year')}
              startIcon={<DateRangeIcon />}
              sx={{ 
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: dateRangePreset === 'year' ? '#000' : 'rgba(212, 175, 55, 0.9)',
                backgroundColor: dateRangePreset === 'year' ? '#D4AF37' : 'transparent',
                '&:hover': {
                  backgroundColor: dateRangePreset === 'year' ? '#D4AF37' : 'rgba(212, 175, 55, 0.1)',
                  borderColor: '#D4AF37',
                }
              }}
            >
              Last Year
            </Button>
            <Button 
              variant={dateRangePreset === 'custom' ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handleDateRangePresetChange('custom')}
              startIcon={<DateRangeIcon />}
              sx={{ 
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: dateRangePreset === 'custom' ? '#000' : 'rgba(212, 175, 55, 0.9)',
                backgroundColor: dateRangePreset === 'custom' ? '#D4AF37' : 'transparent',
                '&:hover': {
                  backgroundColor: dateRangePreset === 'custom' ? '#D4AF37' : 'rgba(212, 175, 55, 0.1)',
                  borderColor: '#D4AF37',
                }
              }}
            >
              Custom Range
            </Button>
          </Stack>

          {showDatePickers && (
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
              <Box sx={{ width: '100%' }}>
                <TextField
                  label="Start Date"
                  type="date"
                  fullWidth
                  value={startDate.format('YYYY-MM-DD')}
                  onChange={(e) => {
                    const newDate = dayjs(e.target.value);
                    if (newDate.isValid()) {
                      handleStartDateChange(newDate);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: '#fff',
                      '& fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D4AF37',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(212, 175, 55, 0.8)',
                      '&.Mui-focused': {
                        color: '#D4AF37',
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <TextField
                  label="End Date"
                  type="date"
                  fullWidth
                  value={endDate.format('YYYY-MM-DD')}
                  onChange={(e) => {
                    const newDate = dayjs(e.target.value);
                    if (newDate.isValid()) {
                      handleEndDateChange(newDate);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: '#fff',
                      '& fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D4AF37',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(212, 175, 55, 0.8)',
                      '&.Mui-focused': {
                        color: '#D4AF37',
                      },
                    },
                  }}
                />
              </Box>
            </Stack>
          )}
          <Button
            variant="contained"
            onClick={handleApplyDateFilter}
            startIcon={<RefreshIcon />}
            sx={{
              mt: 2,
              backgroundColor: '#D4AF37',
              color: '#000',
              padding: '10px 24px',
              transition: 'all 0.3s ease',
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              borderRadius: '4px',
              letterSpacing: '0.5px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#D4AF37',
                boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
                transform: 'translateY(-1px)',
              },
              '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
              },
            }}
          >
            Apply Date Filter
          </Button>
        </Paper>
        
        {/* Interaction type filters */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 4, 
            backgroundColor: 'rgba(18, 18, 18, 0.8)', 
            backdropFilter: 'blur(10px)', 
            border: '1px solid rgba(212, 175, 55, 0.1)', 
            borderRadius: '8px' 
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" sx={{ color: '#D4AF37', fontWeight: 500 }}>
              Interaction Types
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button 
                size="small" 
                onClick={handleSelectAll}
                sx={{ 
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  color: '#D4AF37',
                  fontSize: '0.75rem',
                  padding: '5px 12px',
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.2)',
                    borderColor: '#D4AF37',
                  }
                }}
                variant="outlined"
                startIcon={<VisibilityIcon fontSize="small" />}
              >
                Select All
              </Button>
              <Button 
                size="small" 
                onClick={handleDeselectAll}
                sx={{ 
                  backgroundColor: 'rgba(212, 175, 55, 0.05)',
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  color: 'rgba(212, 175, 55, 0.9)', 
                  fontSize: '0.75rem',
                  padding: '5px 12px',
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.1)',
                    borderColor: '#D4AF37',
                  }
                }}
                variant="outlined"
                startIcon={<VisibilityIcon style={{ opacity: 0.5 }} fontSize="small" />}
              >
                Deselect All
              </Button>
            </Stack>
          </Box>
          
          <Grid container spacing={2}>
            {interactionTypes.map((type) => (
              <Grid item xs={12} sm={6} md={4} lg={2.4} key={type.id}>
                <Paper 
                  elevation={type.visible ? 3 : 1}
                  sx={{
                    p: 1.5,
                    backgroundColor: type.visible 
                      ? `${type.color}15` // Semi-transparent version of the color
                      : 'rgba(18, 18, 18, 0.5)',
                    border: '1px solid',
                    borderColor: type.visible ? type.color : 'rgba(212, 175, 55, 0.1)',
                    borderRadius: '8px',
                    transition: 'all 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: type.visible 
                        ? `${type.color}25`
                        : 'rgba(18, 18, 18, 0.7)',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                    }
                  }}
                  onClick={() => handleInteractionToggle(type.id)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box 
                        sx={{ 
                          color: type.visible ? type.color : 'rgba(255, 255, 255, 0.5)',
                          mr: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '1.2rem'
                        }}
                      >
                        {type.icon}
                      </Box>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontWeight: type.visible ? 600 : 400,
                          color: type.visible ? '#fff' : 'rgba(255, 255, 255, 0.6)'
                        }}
                      >
                        {type.label}
                      </Typography>
                    </Box>
                    <Checkbox 
                      checked={type.visible}
                      onChange={() => handleInteractionToggle(type.id)}
                      onClick={(e) => e.stopPropagation()}
                      sx={{
                        color: type.visible ? type.color : 'rgba(255, 255, 255, 0.3)',
                        padding: '2px',
                        '&.Mui-checked': {
                          color: type.color,
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
        
        {/* Summary cards */}
        <Paper elevation={3} sx={{ p: 2, mb: 4, backgroundColor: 'rgba(18, 18, 18, 0.8)', backdropFilter: 'blur(10px)', border: '1px solid rgba(212, 175, 55, 0.1)' }}>
          <Grid container spacing={3}>
            {/* Left column - Views card */}
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ 
                backgroundColor: 'rgba(18, 18, 18, 0.7)', 
                border: '1px solid rgba(212, 175, 55, 0.2)',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ color: colors.views, mr: 1, display: 'flex' }}>
                      <VisibilityIcon />
                    </Box>
                    <Typography variant="h6">Views</Typography>
                  </Box>
                  
                  {/* Main data area */}
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    {/* Views count */}
                    <Typography variant="h3" sx={{ color: colors.views, mb: 2 }}>{currentStats.viewCount}</Typography>
                    
                    {/* Chart - full width */}
                    <Box sx={{ height: 60, mb: 2, width: '100%' }}>
                      <SparkLineChart
                        data={viewsData.map(d => d.count)}
                        height={60}
                        colors={[colors.views]}
                        showTooltip
                        showHighlight
                        valueFormatter={(value) => `${value} view${value !== 1 ? 's' : ''}`}
                      />
                    </Box>
                    
                    {/* Unique metrics section - simple bar */}
                    {uniqueViewsStats && Object.keys(uniqueViewsStats).length > 0 ? (
                      <Box sx={{ 
                        mt: 'auto',
                        py: 1,
                        px: 1.5,
                        borderRadius: 1,
                        backgroundColor: 'rgba(212, 175, 55, 0.07)',
                        border: '1px solid rgba(212, 175, 55, 0.15)'
                      }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FilterAltIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'rgba(212, 175, 55, 0.7)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', whiteSpace: 'nowrap' }}>
                              Unique Views: <span style={{ color: '#D4AF37', fontWeight: 500 }}>{Object.values(uniqueViewsStats).reduce((sum, stat) => sum + stat.uniqueViews, 0)}</span>
                            </Typography>
                          </Box>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'rgba(212, 175, 55, 0.7)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', whiteSpace: 'nowrap' }}>
                              Unique Users: <span style={{ color: '#D4AF37', fontWeight: 500 }}>{Object.values(uniqueViewsStats).reduce((sum, stat) => sum + stat.uniqueUsers, 0)}</span>
                            </Typography>
                          </Box>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DevicesIcon sx={{ fontSize: '0.9rem', mr: 0.5, color: 'rgba(212, 175, 55, 0.7)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', whiteSpace: 'nowrap' }}>
                              Unique Devices: <span style={{ color: '#D4AF37', fontWeight: 500 }}>{Object.values(uniqueViewsStats).reduce((sum, stat) => sum + stat.uniqueDevices, 0)}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : loadingUniqueViews ? (
                      <Box sx={{ 
                        mt: 'auto',
                        py: 1,
                        px: 1.5,
                        borderRadius: 1,
                        backgroundColor: 'rgba(212, 175, 55, 0.07)',
                        border: '1px solid rgba(212, 175, 55, 0.15)',
                        display: 'flex', 
                        alignItems: 'center' 
                      }}>
                        <CircularProgress size={14} sx={{ mr: 1, color: 'rgba(212, 175, 55, 0.7)' }} />
                        <Typography variant="body2" sx={{ color: 'rgba(212, 175, 55, 0.7)' }}>Loading unique stats...</Typography>
                      </Box>
                    ) : null}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            
            {/* Right column - top row */}
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={3} sx={{ height: '100%' }}>
                {/* Favorites Card */}
                <Grid item xs={12} sm={6}>
                  <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ color: colors.favorites, mr: 1, display: 'flex' }}>
                          <FavoriteIcon />
                        </Box>
                        <Typography variant="h6">Favorites</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ color: colors.favorites }}>{currentStats.favoriteCount}</Typography>
                        <Box sx={{ ml: 2, height: 40, flexGrow: 1 }}>
                          <SparkLineChart
                            data={favoritesData.map(d => d.count)}
                            height={40}
                            colors={[colors.favorites]}
                            showTooltip
                            showHighlight
                            valueFormatter={(value) => `${value} favorite${value !== 1 ? 's' : ''}`}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Links Opened Card */}
                <Grid item xs={12} sm={6}>
                  <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ color: colors.linkOpens, mr: 1, display: 'flex' }}>
                          <LinkIcon />
                        </Box>
                        <Typography variant="h6">Links Opened</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ color: colors.linkOpens }}>{currentStats.linkOpenCount}</Typography>
                        <Box sx={{ ml: 2, height: 40, flexGrow: 1 }}>
                          <SparkLineChart
                            data={linkOpensData.map(d => d.count)}
                            height={40}
                            colors={[colors.linkOpens]}
                            showTooltip
                            showHighlight
                            valueFormatter={(value) => `${value} link${value !== 1 ? 's' : ''} opened`}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Chats Started Card */}
                <Grid item xs={12} sm={6}>
                  <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ color: colors.chats, mr: 1, display: 'flex' }}>
                          <ChatIcon />
                        </Box>
                        <Typography variant="h6">Chats Started</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ color: colors.chats }}>{currentStats.chatStartCount}</Typography>
                        <Box sx={{ ml: 2, height: 40, flexGrow: 1 }}>
                          <SparkLineChart
                            data={chatsData.map(d => d.count)}
                            height={40}
                            colors={[colors.chats]}
                            showTooltip
                            showHighlight
                            valueFormatter={(value) => `${value} chat${value !== 1 ? 's' : ''}`}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Phone Clicks Card */}
                <Grid item xs={12} sm={6}>
                  <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ color: colors.phoneClicks, mr: 1, display: 'flex' }}>
                          <PhoneIcon />
                        </Box>
                        <Typography variant="h6">Phone Clicks</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ color: colors.phoneClicks }}>{currentStats.phoneClickCount}</Typography>
                        <Box sx={{ ml: 2, height: 40, flexGrow: 1 }}>
                          <SparkLineChart
                            data={phoneClicksData.map(d => d.count)}
                            height={40}
                            colors={[colors.phoneClicks]}
                            showTooltip
                            showHighlight
                            valueFormatter={(value) => `${value} phone click${value !== 1 ? 's' : ''}`}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                
                {/* Event Hits Card */}
                <Grid item xs={12}>
                  <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ color: colors.eventHits, mr: 1, display: 'flex' }}>
                          <CalendarTodayIcon />
                        </Box>
                        <Typography variant="h6">Event Hits</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" sx={{ color: colors.eventHits }}>{currentStats.eventHitCount || 0}</Typography>
                        <Box sx={{ ml: 2, height: 40, flexGrow: 1 }}>
                          <SparkLineChart
                            data={(eventHitsData || []).map(d => d.count)}
                            height={40}
                            colors={[colors.eventHits]}
                            showTooltip
                            showHighlight
                            valueFormatter={(value) => `${value} event hit${value !== 1 ? 's' : ''}`}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {/* Combined statistics visualization */}
        <Paper elevation={3} sx={{ backgroundColor: 'rgba(18, 18, 18, 0.8)', backdropFilter: 'blur(10px)', border: '1px solid rgba(212, 175, 55, 0.1)', p: 2 }}>
          <Typography variant="h5" sx={{ mb: 2, color: '#D4AF37', fontWeight: 500 }}>
            Statistics Overview
          </Typography>
          <Divider sx={{ opacity: 0.2, mb: 3 }} />
          
          <Grid container spacing={3}>
            {/* Line Chart with selected metrics */}
            <Grid item xs={12} lg={8}>
              <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)', height: '100%' }}>
                <CardHeader title="Daily Activity Trends" />
                <Divider sx={{ opacity: 0.2 }} />
                <CardContent>
                  {chartSeries.length > 0 ? (
                    <Box sx={{ height: 500, width: '100%' }}>
                      <LineChart
                        xAxis={[{
                          data: viewsData.map(item => item.date),
                          scaleType: viewsData.length > 0 && typeof viewsData[0].date === 'string' ? 'band' : 'time',
                          valueFormatter: (date) => {
                            if (date instanceof Date) {
                              return date.toLocaleDateString();
                            }
                            return String(date); // For string labels (week/month/quarter)
                          }
                        }]}
                        series={chartSeries}
                        height={500}
                        margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
                        sx={chartSx}
                        slotProps={{
                          legend: {
                            hidden: true
                          }
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6" color="text.secondary">
                        Please select at least one interaction type to display
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            
            {/* Pie Chart for distribution */}
            <Grid item xs={12} lg={4}>
              <Card sx={{ backgroundColor: 'rgba(18, 18, 18, 0.7)', border: '1px solid rgba(212, 175, 55, 0.2)', height: '100%' }}>
                <CardHeader title="Distribution of Interactions" />
                <Divider sx={{ opacity: 0.2 }} />
                <CardContent>
                  {pieChartData.length > 0 ? (
                    <Box sx={{ height: 500, width: '100%' }}>
                      <PieChart
                        series={[
                          {
                            data: pieChartData,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, color: 'gray' },
                            color: 'category',
                            valueFormatter: (item) => `${item.value} interactions`
                          }
                        ]}
                        colors={colorArray}
                        height={500}
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        slotProps={{ 
                          legend: { 
                            hidden: true
                          } 
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6" color="text.secondary">
                        Please select at least one interaction type to display
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default CompanyStatisticsPage;
