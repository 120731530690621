import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useEffect, useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  createChat,
  createEventChat,
  fetchChats,
  selectAllChats,
  selectChatsError,
  selectChatsStatus,
} from '../../store/slices/chatSlice';
import { AppDispatch } from '../../store/store';
import { signalRService } from '../../services/signalRService';
import { selectAuth } from '../../store/slices/authSlice';
import { useMessagePageParams } from './hooks/useMessagePageParams';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';
import MessageItem, { getChatName } from './components/Message.tsx';
import { fetchListingsByIds } from '../../store/slices/listingSlice.ts';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';
import i18next from '../../utilis/i18next.ts';

const MessagesPage = () => {
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [messageInput, setMessageInput] = useState('');
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const chats = useSelector(selectAllChats);
  const status = useSelector(selectChatsStatus);
  const error = useSelector(selectChatsError);
  const auth = useSelector(selectAuth);
  const currentUserId = auth.user?.id;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { params, clearParams, setSearchParams } = useMessagePageParams();
  useDocumentTitle(i18next.t('Views.MessagesPage.Title'));
  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, []);

  // Auto-resize textarea based on content
  const adjustTextareaHeight = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 120)}px`;
    }
  }, []);

  // Scroll to bottom when messages change or chat is selected
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, selectedChat, chats]);

  // Adjust textarea height when input changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [messageInput, adjustTextareaHeight]);

  // Listen for viewport changes (keyboard appearance/disappearance)
  useEffect(() => {
    const handleResize = () => {
      // Delay scrolling to ensure the UI has updated
      setTimeout(scrollToBottom, 100);
    };

    window.addEventListener('resize', handleResize);
    
    // For iOS devices specifically
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.addEventListener('focusin', () => {
        // Input was focused, keyboard likely appeared
        setTimeout(scrollToBottom, 100);
      });
      window.addEventListener('focusout', () => {
        // Input lost focus, keyboard likely disappeared
        setTimeout(scrollToBottom, 100);
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.removeEventListener('focusin', scrollToBottom);
        window.removeEventListener('focusout', scrollToBottom);
      }
    };
  }, [scrollToBottom]);

  // Fetch chats when component mounts
  useEffect(() => {
    if (status === 'idle') {
      // If companyId is specified in URL, fetch chats for that company
      dispatch(
        fetchChats({
          companyId: params.companyId,
          isCompany: params.isCompany,
        })
      );
    }
  }, [status, dispatch, params.companyId, params.isCompany]);

  // Fetch all listings referenced in messages when chats are loaded
  useEffect(() => {
    if (chats.length > 0) {
      // Extract all listing IDs from messages
      const listingIds = new Set<string>();
      chats.forEach((chat) => {
        chat.messages.forEach((message) => {
          const match = message.text.match(/^@listing:([0-9a-f-]+)/);
          if (match?.[1]) {
            listingIds.add(match[1]);
          }
        });
      });

      // Fetch all listings at once
      if (listingIds.size > 0) {
        dispatch(fetchListingsByIds(Array.from(listingIds)));
      }
    }
  }, [chats, dispatch]);

  // Initialize SignalR connection
  useEffect(() => {
    const initializeSignalR = async () => {
      try {
        await signalRService.startConnection();
      } catch (err) {
        console.error('Failed to initialize SignalR:', err);
      }
    };

    initializeSignalR();

    return () => {
      signalRService.stopConnection();
    };
  }, []);

  // Handle URL parameters
  useEffect(() => {
    const handleUrlParams = async () => {
      if (status !== 'succeeded') return;

      if (params.mode === 'newChat') {
        try {
          const existingChat = params.listingId
            ? chats.find((chat) => chat.listingId === params.listingId)
            : null;

          if (existingChat) {
            setSelectedChat(existingChat.id);
            await signalRService.joinChat(existingChat.id);
          } else if (params.companyName && params.listingId) {
            const actionResult = await dispatch(
              createChat({
                companyName: params.companyName,
                listingId: params.listingId,
                listingName: params.listingName || '',
              })
            );

            if (createChat.fulfilled.match(actionResult)) {
              setSelectedChat(actionResult.payload.id);
              await signalRService.joinChat(actionResult.payload.id);
            }
          }
        } catch (error) {
          console.error('Failed to handle chat creation:', error);
        }
        clearParams();
      } else if (params.mode === 'new-event-chat') {
        console.log('Creating event chat:', params);
        try {
          const existingChat = params.eventId
            ? chats.find((chat) => chat.eventId === params.eventId)
            : null;

          if (existingChat) {
            console.log('found existing');
            setSelectedChat(existingChat.id);
            await signalRService.joinChat(existingChat.id);
          } else if (params.customerId && params.companyId && params.eventId) {
            console.log('sending createEventChat');
            const actionResult = await dispatch(
              createEventChat({
                userId: params.customerId,
                companyId: params.companyId,
                eventId: params.eventId,
              })
            );

            if (createEventChat.fulfilled.match(actionResult)) {
              setSelectedChat(actionResult.payload.id);
              await signalRService.joinChat(actionResult.payload.id);
            }
          }
        } catch (error) {
          console.error('Failed to handle chat creation:', error);
        }
        const newParams = new URLSearchParams({
          companyId: params.companyId,
          isCompany: 'true',
        });
        setSearchParams(newParams);
      }
    };

    handleUrlParams();
  }, [status, params, dispatch, chats, clearParams]);

  const getFilteredChats = () => {
    let filteredChats = chats;
    if (params.companyId) {
      filteredChats = chats.filter(
        (chat) => chat.companyId === params.companyId
      );
    } else {
      // If viewing as user, show only user's chats
      filteredChats = chats.filter((chat) => chat.userId === auth.user?.id);
    }
    return filteredChats;
  };

  // Show loading
  if (status === 'loading') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{ color: '#D4AF37' }} />
      </Box>
    );
  }

  // Show error
  if (status === 'failed' && error) {
    return (
      <Box
        sx={{
          p: 3,
          background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))`,
          minHeight: '100vh',
        }}
      >
        <Alert
          severity="error"
          sx={{
            bgcolor: 'rgba(211, 47, 47, 0.1)',
            color: '#ff8a80',
            border: '1px solid rgba(211, 47, 47, 0.3)',
          }}
        >
          {error}
          <Button
            color="inherit"
            size="small"
            onClick={() => dispatch(fetchChats())}
            sx={{ ml: 2 }}
          >
            Retry
          </Button>
        </Alert>
      </Box>
    );
  }

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!messageInput.trim() || !selectedChat) return;

    try {
      // Send to server
      await signalRService.sendMessage(selectedChat, messageInput.trim());

      // Clear input and scroll chat area
      setMessageInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
      scrollToBottom();
    } catch (err) {
      console.error('Failed to send message:', err);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if it's a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (e.key === 'Enter') {
      // On mobile, send message when Enter is pressed
      if (isMobile) {
        // Only if shift key is not pressed (to allow new lines with Shift+Enter)
        if (!e.shiftKey) {
          e.preventDefault();
          handleSendMessage(e);
        }
        return;
      }

      // On desktop, only send if Enter is pressed without Shift
      if (!e.shiftKey) {
        e.preventDefault();
        handleSendMessage(e);
      }
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'black',
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
        }}
      >
        {/* Chat List */}
        <Box
          sx={{
            width: { xs: '100%', sm: 300 },
            borderRight: '1px solid rgba(212, 175, 55, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            position: { xs: 'absolute', sm: 'relative' },
            left: { xs: selectedChat ? '-100%' : 0, sm: 0 },
            right: { xs: selectedChat ? '100%' : 0, sm: 'auto' },
            top: 0,
            bottom: 0,
            transition: 'all 0.3s ease-in-out',
            bgcolor: 'black',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(212, 175, 55, 0.1)',
            }}
          >
            {selectedChat && isMobile && (
              <IconButton
                onClick={() => setSelectedChat(null)}
                sx={{ color: '#D4AF37', mr: 1 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" sx={{ color: '#D4AF37' }}>
              Messages
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(255, 255, 255, 0.05)',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(212, 175, 55, 0.3)',
                borderRadius: '4px',
                '&:hover': {
                  background: 'rgba(212, 175, 55, 0.5)',
                },
              },
            }}
          >
            <List sx={{ p: 0 }}>
              {chats.map((chat) => (
                <ListItemButton
                  key={chat.id}
                  selected={selectedChat === chat.id}
                  onClick={() => setSelectedChat(chat.id)}
                  sx={{
                    borderBottom: '1px solid rgba(212, 175, 55, 0.1)',
                    '&.Mui-selected': {
                      bgcolor: 'rgba(212, 175, 55, 0.1)',
                      '&:hover': {
                        bgcolor: 'rgba(212, 175, 55, 0.15)',
                      },
                    },
                    '&:hover': {
                      bgcolor: 'rgba(212, 175, 55, 0.05)',
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#D4AF37' }}>
                      {getChatName(chat)[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={getChatName(chat)}
                    primaryTypographyProps={{
                      sx: { color: '#D4AF37' },
                    }}
                    secondary={chat.messages[chat.messages.length - 1]?.text}
                    secondaryTypographyProps={{
                      sx: { color: 'rgba(255, 255, 255, 0.7)' },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Box>

        {/* Chat Area */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            position: { xs: 'absolute', sm: 'relative' },
            left: { xs: selectedChat ? 0 : '100%', sm: 0 },
            right: { xs: selectedChat ? 0 : '-100%', sm: 'auto' },
            top: 0,
            bottom: 0,
            width: { xs: '100%', sm: 'auto' },
            transition: 'all 0.3s ease-in-out',
            bgcolor: 'black',
            zIndex: 1,
          }}
        >
          {selectedChat ? (
            <>
              {/* Chat Header */}
              <Box
                sx={{
                  mt: 0,
                  p: 2,
                  borderBottom: '1px solid rgba(212, 175, 55, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  position: 'fixed',
                  top: 60, 
                  left: { xs: 0, sm: 300 }, // Align with sidebar on desktop
                  right: 0,
                  width: { xs: '100%', sm: 'auto' },
                  backgroundColor: 'black',
                  zIndex: 1000,
                  height: '60px', // Fixed height for calculations
                  boxSizing: 'border-box',
                }}
              >
                {isMobile && (
                  <IconButton
                    onClick={() => setSelectedChat(null)}
                    sx={{ color: '#D4AF37' }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Avatar sx={{ bgcolor: '#D4AF37' }}>
                  {getChatName(chats.find((c) => c.id === selectedChat)!)[0]}
                </Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ color: '#D4AF37' }}>
                    {getChatName(chats.find((c) => c.id === selectedChat)!)}
                  </Typography>
                </Box>
                {params.companyId && (
                  <IconButton
                    onClick={() => navigate(`/company/${params.companyId}`)}
                    sx={{ color: '#D4AF37' }}
                  >
                    <LaunchIcon />
                  </IconButton>
                )}
              </Box>

              {/* Messages Area */}
              <Box
                ref={chatContainerRef}
                sx={{
                  position: 'fixed',
                  top: 120, // 60px navbar + 60px header
                  bottom: 60, // 60px input field
                  left: { xs: 0, sm: 300 }, // Account for sidebar on desktop
                  right: 0,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  p: { xs: 1, sm: 2 },
                  display: 'flex',
                  flexDirection: 'column',
                  gap: { xs: 1, sm: 2 },
                  WebkitOverflowScrolling: 'touch', // Improve iOS scrolling
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'rgba(255, 255, 255, 0.05)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(212, 175, 55, 0.3)',
                    borderRadius: '4px',
                    '&:hover': {
                      background: 'rgba(212, 175, 55, 0.5)',
                    },
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 2 } }}>
                  {chats
                    .find((c) => c.id === selectedChat)
                    ?.messages.map((message) => (
                      <MessageItem
                        key={message.id}
                        message={message}
                        chat={chats.find((c) => c.id === selectedChat)!}
                        currentUserId={currentUserId}
                      />
                    ))}
                </Box>
              </Box>

              {/* Message Input */}
              <Box
                component="form"
                onSubmit={handleSendMessage}
                sx={{
                  p: { xs: 1, sm: 2 },
                  borderTop: '1px solid rgba(212, 175, 55, 0.1)',
                  display: 'flex',
                  gap: { xs: 1, sm: 2 },
                  position: 'fixed',
                  bottom: 0,
                  left: { xs: 0, sm: 300 }, // Account for sidebar width on desktop
                  right: 0,
                  backgroundColor: 'black',
                  zIndex: 1000,
                  width: { xs: '100%', sm: 'auto' },
                  maxWidth: '100%',
                  boxSizing: 'border-box',
                  height: '60px', // Fixed height for calculations
                }}
              >
                <Box
                  component="textarea"
                  ref={inputRef}
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Type a message... (Press Enter to send)"
                  sx={{
                    flex: 1,
                    border: '1px solid rgba(212, 175, 55, 0.2)',
                    outline: 'none',
                    padding: { xs: '8px', sm: '12px' },
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    fontSize: { xs: '0.9rem', sm: '0.95rem' },
                    color: 'white',
                    transition: 'all 0.2s ease',
                    resize: 'none',
                    overflow: 'hidden',
                    minHeight: { xs: '36px', sm: '40px' },
                    maxHeight: '120px',
                    width: '100%',
                    maxWidth: 'calc(100% - 16px)', // Ensure it doesn't exceed container on mobile
                    fontFamily: 'inherit',
                    '&::placeholder': {
                      color: 'rgba(255, 255, 255, 0.5)',
                      fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    },
                    '&:focus': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderColor: '#D4AF37',
                    },
                  }}
                />
                {/* Only show send button on non-mobile devices */}
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <IconButton
                    type="submit"
                    disabled={!messageInput.trim()}
                    sx={{
                      bgcolor: '#D4AF37',
                      color: 'black',
                      transition: 'all 0.2s ease',
                      width: '48px',
                      height: '48px',
                      flexShrink: 0,
                      '&:hover': {
                        bgcolor: '#b69121',
                        transform: 'scale(1.05)',
                      },
                      '&.Mui-disabled': {
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        color: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                  >
                    <SendIcon sx={{ fontSize: '1.2rem' }} />
                  </IconButton>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: { xs: 'none', sm: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: 'rgba(255, 255, 255, 0.5)' }}
              >
                Select a chat to start messaging
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MessagesPage;
