import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { DragIndicator } from '@mui/icons-material';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SectionHeader } from '../dashboard/SectionHeader';

interface SortableVideoProps {
  id: string;
  link: string;
  error: string;
  onLinkChange: (id: string, newLink: string) => void;
  onRemove: (id: string) => void;
}

const validateVideoUrl = (url: string): string => {
  if (!url.trim()) return '';

  // YouTube URL patterns
  const youtubePatterns = [
    /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/,
    /^https?:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,
    /^https?:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/,
  ];

  // Vimeo URL patterns
  const vimeoPatterns = [
    /^https?:\/\/(?:www\.)?vimeo\.com\/(\d+)/,
    /^https?:\/\/(?:www\.)?player\.vimeo\.com\/video\/(\d+)/,
  ];

  // Check YouTube patterns
  for (const pattern of youtubePatterns) {
    if (pattern.test(url)) return '';
  }

  // Check Vimeo patterns
  for (const pattern of vimeoPatterns) {
    if (pattern.test(url)) return '';
  }

  return 'Please enter a valid embed URL:\nYouTube: youtube.com/embed/... or\nVimeo: player.vimeo.com/video/...';
};

const SortableVideo = ({
  id,
  link,
  error,
  onLinkChange,
  onRemove,
}: SortableVideoProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      data-dragging={isDragging}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        mb: 2,
      }}
    >
      <IconButton {...attributes} {...listeners} size="small">
        <DragIndicator />
      </IconButton>
      <TextField
        fullWidth
        value={link}
        onChange={(e) => onLinkChange(id, e.target.value)}
        placeholder="Enter YouTube or Vimeo URL"
        error={!!error}
        helperText={error}
      />
      <IconButton onClick={() => onRemove(id)} color="error" size="small">
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

interface VideoInputProps {
  videoLinks: string[];
  onVideoLinksChange: (links: string[]) => void;
}

export const VideoInput: React.FC<VideoInputProps> = ({
  videoLinks,
  onVideoLinksChange,
}) => {
  const [videoItems, setVideoItems] = useState(
    videoLinks.map((link) => ({
      id: crypto.randomUUID(),
      link,
      error: validateVideoUrl(link),
    }))
  );

  // Remove this effect to prevent circular updates
  // useEffect(() => {
  //   onVideoLinksChange(
  //     videoItems.filter((item) => !item.error).map((item) => item.link)
  //   );
  // }, [videoItems, onVideoLinksChange]);

  // Add new helper function
  const notifyChanges = (items: typeof videoItems) => {
    const validLinks = items
      .filter((item) => !item.error && item.link.trim())
      .map((item) => item.link);
    onVideoLinksChange(validLinks);
  };

  const handleLinkChange = (id: string, newLink: string) => {
    const newItems = videoItems.map((item) =>
      item.id === id
        ? {
            ...item,
            link: newLink,
            error: validateVideoUrl(newLink),
          }
        : item
    );
    setVideoItems(newItems);
    notifyChanges(newItems);
  };

  const handleRemove = (id: string) => {
    const newItems = videoItems.filter((item) => item.id !== id);
    setVideoItems(newItems);
    notifyChanges(newItems);
  };

  const handleAdd = () => {
    const newItems = [
      ...videoItems,
      {
        id: crypto.randomUUID(),
        link: '',
        error: '',
      },
    ];
    setVideoItems(newItems);
    // Don't notify on add since the new link is empty
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: isMobileDevice()
        ? {
            delay: 150,
            tolerance: 5,
            distance: 1,
          }
        : undefined,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const preventScroll = (e: TouchEvent) => {
      if (document.querySelector('[data-dragging="true"]')) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventScroll, { passive: false });
    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setVideoItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <SectionHeader title="Video Links" icon={undefined} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={videoItems.map((item) => item.id)}
          strategy={horizontalListSortingStrategy}
        >
          {videoItems.map((item) => (
            <SortableVideo
              key={item.id}
              id={item.id}
              link={item.link}
              error={item.error}
              onLinkChange={handleLinkChange}
              onRemove={handleRemove}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Button
        variant="outlined"
        onClick={handleAdd}
        startIcon={<AddIcon />}
        sx={{
          borderColor: 'rgba(212, 175, 55, 0.3)',
          color: 'primary.main',
          '&:hover': {
            borderColor: 'rgba(212, 175, 55, 0.6)',
            backgroundColor: 'rgba(212, 175, 55, 0.05)',
          },
        }}
      >
        Add Video Link
      </Button>
    </Box>
  );
};
