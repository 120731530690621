import React, { FC, useEffect } from 'react';
import { Box, Button, Toolbar, useTheme, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveListing,
  fetchListing,
  selectCurrentListing,
} from '../../store/slices/listingSlice';
import { ListingView } from '../../components/listings/ListingView';
import { CompanySideCard } from '../../components/listings/CompanySideCard';
import { AppDispatch } from '../../store/store';
import { fetchCategories } from '../../store/slices/categorySlice';
import { fetchSubCategories } from '../../store/slices/subCategorySlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { selectAuth } from '../../store/slices/authSlice.ts';
import { ListingStatus } from '../../types/states/ListingState.ts';
import { apiUrl } from '../../main.tsx';
import { ImageView } from '../../components/inputs/ImageView';
import listingStatisticsService from '../../services/listingStatisticsService';

const ListingItemPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentListing = useSelector(selectCurrentListing);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const auth = useSelector(selectAuth);
  const mode = new URLSearchParams(location.search).get('mode');

  // Choose which image to display
  const displayImage = React.useMemo(() => {
    if (isMobile) {
      // On mobile, prefer card image but fall back to banner if card is not available
      return (
        currentListing?.cardImageUri || currentListing?.bannerImageUri || ''
      );
    }
    // On desktop, prefer banner image but fall back to card if banner is not available
    return currentListing?.bannerImageUri || currentListing?.cardImageUri || '';
  }, [isMobile, currentListing?.cardImageUri, currentListing?.bannerImageUri]);

  // Track view event when the listing is loaded
  const recordViewEvent = async (listingId: string) => {
    try {
      await listingStatisticsService.recordEvent({
        listingId,
        eventType: 'view',
        userId: auth.user?.id, // Track user if logged in, otherwise anonymous
      });
    } catch (error) {
      console.error('Failed to record listing view event:', error);
      // Don't show error to user - non-critical operation
    }
  };

  // Helper to track link opens
  const trackLinkOpen = async (linkUrl: string) => {
    if (!id) return;
    
    try {
      await listingStatisticsService.recordEvent({
        listingId: id,
        eventType: 'linkOpen',
        userId: auth.user?.id,
        metadata: { url: linkUrl }
      });
    } catch (error) {
      console.error('Failed to record link open event:', error);
    }
  };

  // Helper to track phone number clicks
  const trackPhoneClick = async (phoneNumber: string) => {
    if (!id) return;
    
    try {
      await listingStatisticsService.recordEvent({
        listingId: id,
        eventType: 'phoneClick',
        userId: auth.user?.id,
        metadata: { phoneNumber }
      });
    } catch (error) {
      console.error('Failed to record phone click event:', error);
    }
  };

  // Helper to track chat starts
  const trackChatStart = async () => {
    if (!id) return;
    
    try {
      await listingStatisticsService.recordEvent({
        listingId: id,
        eventType: 'chatStart',
        userId: auth.user?.id
      });
    } catch (error) {
      console.error('Failed to record chat start event:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(fetchListing(id));
      dispatch(fetchCategories());
      dispatch(fetchSubCategories());
      
      // Record view event when the listing is loaded
      // Use a small timeout to ensure it doesn't block the page load
      setTimeout(() => {
        recordViewEvent(id);
      }, 100);
    } else {
      navigate('/');
    }
  }, [id]);

  if (!currentListing) {
    return (
      <Box sx={{ width: '100vw', marginX: '-24px' }}>
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height={isMobile ? 400 : 600} 
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} 
        />
      </Box>
    );
  }

  const handleApprove = (status: ListingStatus) => {
    dispatch(
      approveListing({
        id: currentListing.id,
        approved: status === ListingStatus.APPROVED,
      })
    );
    navigate('/admin/approvals/listings');
  };

  // Function to handle external link clicks and track them
  const handleLinkClick = (url: string) => {
    trackLinkOpen(url);
    window.open(url, '_blank');
  };

  return (
    <Box>
      <Box 
        sx={{ 
          position: 'relative', 
          width: '100vw',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: isMobile ? '400px' : '600px',
            bgcolor: 'background.paper',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
          }}
        >
          <ImageView
            fileId={displayImage}
            alt={currentListing.name}
            width={isMobile ? 1200 : 2400}
            priority
            fullWidth
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            sx={{
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      {mode === 'review' && auth.user?.roles?.includes('Admin') && (
        <Toolbar
          sx={{
            bgcolor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            justifyContent: 'center',
            py: 1,
            px: 2,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="success"
            size="large"
            startIcon={<CheckCircleIcon />}
            sx={{
              minWidth: 150,
              fontWeight: 'bold',
              '&:hover': {
                bgcolor: 'success.dark',
              },
            }}
            onClick={() => handleApprove(ListingStatus.APPROVED)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            size="large"
            startIcon={<CancelIcon />}
            sx={{
              minWidth: 150,
              fontWeight: 'bold',
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}
            onClick={() => handleApprove(ListingStatus.REJECTED)}
          >
            Reject
          </Button>
        </Toolbar>
      )}
      <Box>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 12, md: 9 }}>
            <Box sx={{ px: 3 }}>
              <ListingView
                mode="view"
                listingId={id || undefined}
                showImageFields={false}
                validationErrors={{
                  categoryId: '',
                  subCategoryId: '',
                  name: '',
                  shortDescription: '',
                }}
                onLinkClick={handleLinkClick}
                onChatStart={trackChatStart}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 3 }}>
            <CompanySideCard 
              mode="view" 
              company={currentListing.company} 
              onLinkClick={handleLinkClick}
              onPhoneClick={trackPhoneClick}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListingItemPage;
