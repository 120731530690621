/**
 * Utility for generating and managing a device ID to track anonymous users
 */

// Key used for localStorage
const DEVICE_ID_KEY = 'festportalen_device_id';

/**
 * Generates a new random device ID
 * @returns A randomly generated UUID v4
 */
function generateDeviceId(): string {
  // Simple UUID v4 implementation
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * Gets the device ID from localStorage or creates a new one if it doesn't exist
 * @returns The device ID for the current device
 */
export function getDeviceId(): string {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  
  // If no device ID exists, create one and store it
  if (!deviceId) {
    deviceId = generateDeviceId();
    try {
      localStorage.setItem(DEVICE_ID_KEY, deviceId);
    } catch (error) {
      console.error('Failed to store device ID in localStorage:', error);
    }
  }
  
  return deviceId;
}

/**
 * Resets the device ID, generating a new one
 * Useful if you need to treat the current user as a new user
 * @returns The new device ID
 */
export function resetDeviceId(): string {
  const newDeviceId = generateDeviceId();
  try {
    localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
  } catch (error) {
    console.error('Failed to store new device ID in localStorage:', error);
  }
  return newDeviceId;
}
