import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import background from '/public/images/banner.jpg';

export interface HeaderProps {
  title: string | undefined;
  subtitle?: string;
  showBackButton?: boolean;
  actions?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  showBackButton = false,
  actions,
}) => {
  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: 'rgba(212, 175, 55, 0.05)',
    border: '2px solid',
    borderColor: 'rgba(212, 175, 55, 0.3)',
    color: '#D4AF37',
    padding: '10px 24px',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#D4AF37',
      borderColor: '#D4AF37',
      color: '#000',
      boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
      transform: 'translateY(-1px)',
    },
    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
    },
  };

  return (
    <Box
      component="header"
      sx={{
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        // borderBottom: '2px solid',
        // borderColor: 'rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden',
        // '&:hover': {
        //     borderColor: '#D4AF37',
        //     boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
        // },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 40% -20%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          zIndex: 2,
          py: { xs: 6, md: 8 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 500,
              textAlign: 'center',
              maxWidth: '800px',
              mx: 'auto',
              color: '#D4AF37',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: '#FFFFFF',
                textAlign: 'center',
                maxWidth: '800px',
                mx: 'auto',
                fontWeight: 400,
                lineHeight: 1.5,
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
              }}
            >
              {subtitle}
            </Typography>
          )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              mt: 4,
              px: { xs: 2, md: 4 },
            }}
          >
            {showBackButton && (
              <Button
                startIcon={<ArrowBackIcon sx={{ fontSize: '1.2rem' }} />}
                onClick={() => navigate(-1)}
                sx={{
                  ...buttonStyle,
                  '& .MuiButton-startIcon': {
                    marginRight: '8px',
                  },
                }}
              >
                Back
              </Button>
            )}

            {actions}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
