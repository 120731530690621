import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { apiUrl } from '../../main';
import axiosInstance from '../../utilis/axios.ts';

export interface Message {
  id: string;
  senderId: string;
  text: string;
  timestamp: string;
  chatId: string;
}

export interface Chat {
  id: string;
  userFullName: string;
  companyName: string;
  lastMessage: string;
  timestamp: string;
  messages: Message[];
  listingId?: string;
  listingName?: string;
  userId: string;
  companyId: string;
  eventId?: string;
  eventName?: string;
}

interface ChatState {
  chats: Chat[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ChatState = {
  chats: [],
  status: 'idle',
  error: null,
};

interface FetchChatsParams {
  companyId?: string;
  isCompany?: boolean;
}

// Async thunk for fetching chats
export const fetchChats = createAsyncThunk(
  'chats/fetchChats',
  async (params?: FetchChatsParams) => {
    const { companyId, isCompany } = params || {};

    let url = `${apiUrl}/chats`;
    if (isCompany && companyId) {
      url = `${apiUrl}/chats/company/${companyId}`;
    }

    const response = await axiosInstance.get(url);
    return response.data;
  }
);

// Async thunk for creating a new chat
export const createChat = createAsyncThunk(
  'chats/createChat',
  async (
    chatData: {
      companyName: string;
      listingId: string;
      listingName: string;
      userName?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      console.log('Creating chat with data:', chatData);
      const response = await axiosInstance.post(`${apiUrl}/chats`, chatData);
      console.log('Chat creation response:', response.data);
      return response.data;
    } catch (error: any) {
      console.error(
        'Failed to create chat:',
        error.response?.data || error.message
      );
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createEventChat = createAsyncThunk(
  'chats/createEventChat',
  async (
    chatData: {
      userId: string;
      companyId: string;
      eventId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      console.log('Creating chat with data:', chatData);
      const response = await axiosInstance.post(
        `${apiUrl}/chats/event-chat`,
        chatData
      );
      console.log('Chat creation response:', response.data);
      return response.data;
    } catch (error: any) {
      console.error(
        'Failed to create chat:',
        error.response?.data || error.message
      );
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk for sending a message
export const sendMessage = createAsyncThunk(
  'chats/sendMessage',
  async ({ chatId, text }: { chatId: string; text: string }) => {
    const response = await axiosInstance.post(
      `${apiUrl}/chats/${chatId}/messages`,
      { text }
    );
    return response.data;
  }
);

const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    addMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat) {
        // Check if message already exists to prevent duplicates
        const messageExists = chat.messages.some((m) => m.id === message.id);
        if (!messageExists) {
          chat.messages.push(message);
          chat.lastMessage = message.text;
          chat.timestamp = message.timestamp;
        }
      }
    },
    updateChat: (
      state,
      action: PayloadAction<{
        chatId: string;
        lastMessage: string;
        timestamp: string;
      }>
    ) => {
      const { chatId, lastMessage, timestamp } = action.payload;
      const chat = state.chats.find((c) => c.id === chatId);
      if (chat) {
        chat.lastMessage = lastMessage;
        chat.timestamp = timestamp;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch chats
      .addCase(fetchChats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      // Create chat
      .addCase(createChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chats.unshift(action.payload);
      })
      .addCase(createChat.rejected, (state, action) => {
        state.status = 'failed';
        state.error = (action.payload as string) || 'Failed to create chat';
      })
      // Create event chat
      .addCase(createEventChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEventChat.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chats.unshift(action.payload);
      })
      .addCase(createEventChat.rejected, (state, action) => {
        state.status = 'failed';
        state.error = (action.payload as string) || 'Failed to create chat';
      })
      // Send message
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { chatId, message } = action.payload;
        const chat = state.chats.find((c) => c.id === chatId);
        if (chat) {
          chat.messages.push(message);
          chat.lastMessage = message.text;
          chat.timestamp = message.timestamp;
          // Move the updated chat to the top of the list
          state.chats = [chat, ...state.chats.filter((c) => c.id !== chatId)];
        }
      });
  },
});

export const selectAllChats = (state: RootState) => state.chats.chats;
export const selectChatById = (state: RootState, chatId: string) =>
  state.chats.chats.find((chat) => chat.id === chatId);
export const selectChatsStatus = (state: RootState) => state.chats.status;
export const selectChatsError = (state: RootState) => state.chats.error;

export const { addMessage, updateChat } = chatSlice.actions;

export default chatSlice.reducer;
