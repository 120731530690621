import {
  Box,
  Container,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import logo from '/public/images/logo.png';
import i18next from '../../../utilis/i18next.ts';
import { useSelector } from 'react-redux';
import { selectCategories } from '../../../store/slices/categorySlice.ts';
import { CategoryCard } from '../../../components/category/CategoryCard.tsx';

const HeroSection = () => {
  const categories = useSelector(selectCategories);
  const navigate = useNavigate();

  const renderCategories = () => {
    if (!categories || !Array.isArray(categories)) {
      return null;
    }
    return categories.map((category, i) => (
      <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={i}>
        <CategoryCard category={category} index={i} />
      </Grid>
    ));
  };

  const CategorySkeletons = () => {
    return (
      <>
        {Array.from({ length: 12 }).map((_, i) => (
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={i}>
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={160}
                height={160}
                sx={{ borderRadius: 2 }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={140}
                sx={{ mt: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={120}
                sx={{ mt: 0.5 }}
              />
            </Box>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        pt: { xs: 8, md: 12 },
        pb: { xs: 8, md: 12 },
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Stack
          spacing={4}
          alignItems="center"
          sx={{ position: 'relative', zIndex: 1 }}
        >
          <Box
            sx={{
              textAlign: 'center',
              animation: 'fadeIn 0.8s ease-out',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                maxWidth: '500px',
                height: 'auto',
                filter:
                  'drop-shadow(0 4px 8px rgba(0,0,0,0.2)) brightness(1.1)',
              }}
            />
            <Typography
              variant="h3"
              sx={{
                mt: 3,
                fontWeight: 500,
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                maxWidth: '800px',
                mx: 'auto',
                background:
                  'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundSize: '200% auto',
                animation: 'shine 3s linear infinite',
                '@keyframes shine': {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
              }}
            >
              {i18next.t('Views.HomePage.HeroSection.HeroText')}
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              animation: 'slideUp 0.8s ease-out 0.2s both',
              '@keyframes slideUp': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            <OutlinedInput
              fullWidth
              placeholder={i18next.t(
                'Views.HomePage.HeroSection.SearchPlaceholder'
              )}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#D4AF37' }} />
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.currentTarget.value.trim()) {
                  navigate(`/search?q=${encodeURIComponent(e.currentTarget.value.trim())}`);
                }
              }}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '12px',
                height: '56px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  borderWidth: '2px',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D4AF37',
                },
                input: {
                  color: '#D4AF37',
                  fontSize: '1.1rem',
                  '&::placeholder': {
                    color: 'rgba(212, 175, 55, 0.7)',
                    opacity: 1,
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              mt: 6,
              animation: 'fadeIn 0.8s ease-out 0.4s both',
            }}
          >
            <Grid container spacing={3}>
              {Array.isArray(categories) && categories.length > 0 ? renderCategories() : <CategorySkeletons />}
            </Grid>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeroSection;
