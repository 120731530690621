import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyById,
  selectCurrentCompany,
} from '../../store/slices/companySlice';
import { AppDispatch } from '../../store/store';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/header';
import PowerBIReport from '../../components/PowerBIReport';
import type { PowerBIEmbedConfig } from '../../services/powerBIService';
import { getPowerBIEmbedConfig } from '../../services/powerBIService';

const CompanyOverviewPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const company = useSelector(selectCurrentCompany);
  const [isLoading, setIsLoading] = useState(true);
  const [embedConfig, setEmbedConfig] = useState<PowerBIEmbedConfig | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (companyId) {
        setIsLoading(true);
        setError(null);
        try {
          await dispatch(fetchCompanyById(companyId)).unwrap();
          const config = await getPowerBIEmbedConfig(
            'caaff76d-b262-41f5-b876-4daeb8937d7e'
          ); // Replace with your actual report ID
          setEmbedConfig(config);
        } catch (error) {
          console.error('Error loading data:', error);
          setError('Failed to load company data or Power BI report');
        }
        setIsLoading(false);
      }
    };

    loadData();
  }, [companyId, dispatch]);

  if (isLoading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!companyId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">No company ID provided</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!company) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Company not found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Header
        title="Company Analytics"
        subtitle="View all company analytics in one place"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box
          sx={{
            height: '1000px', // Adjust height based on viewport height
            width: '100%',
            minHeight: '1000px', // Minimum height to ensure report is visible
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {embedConfig && (
            <PowerBIReport
              reportId={embedConfig.reportId}
              embedUrl={embedConfig.embedUrl}
              embedToken={embedConfig.embedToken}
              companyId={companyId || ''}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyOverviewPage;
