import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CategoryFilterSection from './CategoryFilterSection';
import SubcategoryFilterSection from './SubcategoryFilterSection';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store.ts';
import {
  searchListings,
  selectSearchParams,
} from '../../store/slices/listingSlice.ts';
import LocationFilterSection from './FilterLocationsSection.tsx';
import { County, Region } from '../../types/locations.ts';
import { selectRegions } from '../../store/slices/locationSlice.ts';
import { fetchSubCategories } from '../../store/slices/subCategorySlice.ts';
import { useSearchParams } from 'react-router-dom';

interface FilterContentProps {
  selectedCategoryId: string | null;
  onClose?: () => void;
  variant: 'sidebar' | 'dialog';
  onCategorySelect?: (categoryId: string | null) => void;
}

const FilterContent: React.FC<FilterContentProps> = ({
  selectedCategoryId,
  onClose,
  variant,
  onCategorySelect,
}) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchQuery = searchParams.get('search') || '';
  const [searchQuery, setSearchQuery] = React.useState(initialSearchQuery);
  const [selectedSubcategories, setSelectedSubcategories] = React.useState<
    string[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const regions = useSelector(selectRegions);
  const searchParamsState = useSelector(selectSearchParams);
  const [selectedLocations, setSelectedLocations] = React.useState<Region[]>(
    []
  );

  useEffect(() => {
    dispatch(fetchSubCategories());
  }, []);

  useEffect(() => {
    setSelectedLocations(regions);
  }, []);

  useEffect(() => {
    // Clear selected subcategories when category changes
    setSelectedSubcategories([]);
  }, [selectedCategoryId]);

  useEffect(() => {
    // Update searchQuery when URL parameter changes
    const urlSearchQuery = searchParams.get('search') || '';
    setSearchQuery(urlSearchQuery);
  }, [searchParams]);

  useEffect(() => {
    // Only update if any filter parameters have changed
    if (
      searchParamsState.categoryId !== selectedCategoryId ||
      JSON.stringify(searchParamsState.subCategoryIds) !==
        JSON.stringify(selectedSubcategories) ||
      searchParamsState.searchQuery !== searchQuery ||
      JSON.stringify(searchParamsState.regions) !==
        JSON.stringify(selectedLocations)
    ) {
      dispatch(
        searchListings({
          ...searchParamsState,
          categoryId: selectedCategoryId,
          subCategoryIds: selectedSubcategories,
          searchQuery: searchQuery,
          regions: selectedLocations,
          pageNumber: 1, // Reset to first page only when filters change
        })
      );
    }
  }, [
    selectedCategoryId,
    selectedSubcategories,
    searchQuery,
    selectedLocations,
  ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
  };

  const handleSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (searchQuery) {
      newSearchParams.set('search', searchQuery);
    } else {
      newSearchParams.delete('search');
    }
    setSearchParams(newSearchParams);

    dispatch(
      searchListings({
        ...searchParamsState,
        searchQuery: searchQuery,
        pageNumber: 1,
      })
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('search');
    setSearchParams(newSearchParams);

    dispatch(
      searchListings({
        ...searchParamsState,
        searchQuery: '',
        pageNumber: 1,
      })
    );
  };

  const handleSubcategorySelect = (subcategoryId: string) => {
    setSelectedSubcategories((prev) =>
      prev.includes(subcategoryId)
        ? prev.filter((id) => id !== subcategoryId)
        : [...prev, subcategoryId]
    );
  };

  const handleLocationSelect = useCallback(
    (region: Region, county?: County) => {
      const updatedLocations = selectedLocations.map((loc) => {
        if (loc.id === region.id) {
          return {
            ...loc,
            isSelected: region.isSelected,
            counties: region.counties.map((c) => ({
              ...c,
              isSelected: county
                ? c.id === county.id
                  ? county.isSelected
                  : c.isSelected
                : region.isSelected,
            })),
          };
        }
        return loc;
      });

      setSelectedLocations(updatedLocations);
    },
    [selectedLocations]
  );

  const handleClearAllFilters = () => {
    // Clear search query
    setSearchQuery('');

    // Clear category selection if onCategorySelect is provided
    if (onCategorySelect) {
      onCategorySelect(null);
    }

    // Clear subcategories
    setSelectedSubcategories([]);

    // Reset locations to initial state
    const resetLocations = regions.map((region) => ({
      ...region,
      isSelected: false,
      counties: region.counties.map((county) => ({
        ...county,
        isSelected: false,
      })),
    }));
    setSelectedLocations(resetLocations);

    // Trigger search with cleared filters
    dispatch(
      searchListings({
        categoryId: null,
        subCategoryIds: [],
        searchQuery: '',
        regions: resetLocations,
        pageNumber: 1,
      })
    );
  };

  const CloseButton = variant === 'dialog' ? CloseIcon : ChevronRightIcon;

  // Check if any filters are active
  const hasActiveFilters = Boolean(
    searchQuery ||
      selectedCategoryId ||
      selectedSubcategories.length > 0 ||
      selectedLocations.some(
        (region) =>
          region.isSelected ||
          region.counties.some((county) => county.isSelected)
      )
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom:
            variant === 'dialog' ? '1px solid rgba(212, 175, 55, 0.3)' : 'none',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            fontWeight: 600,
          }}
        >
          Filters
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {hasActiveFilters && (
            <Button
              startIcon={<ClearAllIcon />}
              onClick={handleClearAllFilters}
              size="small"
              sx={{
                color: 'rgba(212, 175, 55, 0.8)',
                '&:hover': {
                  color: '#D4AF37',
                  backgroundColor: 'rgba(212, 175, 55, 0.08)',
                },
              }}
            >
              Clear All
            </Button>
          )}
          {onClose && (
            <IconButton
              onClick={onClose}
              sx={{
                color: 'rgba(212, 175, 55, 0.8)',
                backgroundColor:
                  variant === 'sidebar' ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
                transition: theme.transitions.create(
                  ['transform', 'color', 'background-color'],
                  {
                    duration: theme.transitions.duration.standard,
                  }
                ),
                transform: variant === 'sidebar' ? 'rotate(180deg)' : 'none',
                '&:hover': {
                  color: '#D4AF37',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                },
              }}
            >
              <CloseButton />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#D4AF37' }} />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleClearSearch}
                    sx={{ color: 'text.secondary' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D4AF37',
                },
              },
              '& input': {
                color: '#D4AF37',
                '&::placeholder': {
                  color: 'rgba(212, 175, 55, 0.7)',
                  opacity: 1,
                },
              },
            }}
          />
        </Box>
        <CategoryFilterSection selectedCategoryId={selectedCategoryId} />
        <SubcategoryFilterSection
          selectedCategoryId={selectedCategoryId}
          selectedSubcategories={selectedSubcategories}
          onSubcategorySelect={handleSubcategorySelect}
        />
        <LocationFilterSection
          onLocationSelect={handleLocationSelect}
          selectedLocations={selectedLocations}
        />
      </Box>
    </Box>
  );
};

export default FilterContent;
