import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import {
  AccountBalance,
  CalendarMonth,
  OpenInNew,
  People,
} from '@mui/icons-material';
import { PlannerEvent } from '../../../../types/event';
import { eventTypes } from '../../../planner/event/components/EventTypePicker.tsx';
import { formatDate } from '../../../../utils/date.ts';
import { CompactLocationDisplay } from '../../../../components/filters/CompactLocationDisplay.tsx';

interface EventCardProps {
  event: PlannerEvent;
  onOpenEvent: (eventId: string) => void;
}

const EventCard: FC<EventCardProps> = ({ event, onOpenEvent }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '600px',
        bgcolor: 'background.paper',
        borderRadius: 2,
        overflow: 'hidden',
        border: '2px solid',
        borderColor: 'rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s ease-in-out',
        animation: 'fadeIn 0.8s ease-out',
        '@keyframes fadeIn': {
          from: { opacity: 0, transform: 'translateY(20px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        '&:hover': {
          transform: 'translateY(-4px)',
          borderColor: 'rgba(212, 175, 55, 0.6)',
          boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
        },
        background: 'rgba(0, 0, 0, 0.85)',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '220px',
          overflow: 'hidden',
          flexShrink: 0,
        }}
      >
        <img
          src={eventTypes.find((type) => type.type === event.eventType)?.image}
          alt={event.eventType.toString()}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(600px - 220px)',
          overflow: 'hidden',
        }}
      >
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: '#FFD700',
                fontWeight: 600,
                fontSize: '1.1rem',
                mb: 1,
              }}
            >
              {event.eventConfig.eventName || 'Untitled Event'}
            </Typography>

            {event.eventCategories && event.eventCategories.length > 0 && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                {event.eventCategories.map((category) => (
                  <Chip
                    key={category.id}
                    label={category.name}
                    size="small"
                    variant="outlined"
                    sx={{
                      borderColor: '#D4AF37',
                      borderWidth: 1,
                      color: '#D4AF37',
                      fontSize: '0.75rem',
                      height: '20px',
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>

          <Box sx={{ overflow: 'auto', flex: 1, mb: 2 }}>
            <Stack spacing={1.5}>
              <Stack direction="row" spacing={1} alignItems="center">
                <CalendarMonth sx={{ color: '#FFD700', fontSize: 20 }} />
                <Typography variant="body2">
                  {formatDate(event.eventConfig.startDate)}
                  {event.eventConfig.endDate &&
                    ` - ${formatDate(event.eventConfig.endDate)}`}
                </Typography>
              </Stack>

              {event.eventConfig.numberOfAttendees && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <People sx={{ color: '#FFD700', fontSize: 20 }} />
                  <Typography variant="body2">
                    {event.eventConfig.numberOfAttendees} attendees
                  </Typography>
                </Stack>
              )}

              {event.eventConfig.budget && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <AccountBalance sx={{ color: '#FFD700', fontSize: 20 }} />
                  <Typography variant="body2">
                    Budget: {event.eventConfig.budget}
                  </Typography>
                </Stack>
              )}

              {event.eventLocations && event.eventLocations.length > 0 && (
                <Box>
                  <CompactLocationDisplay locations={event.eventLocations} />
                </Box>
              )}
            </Stack>
          </Box>

          <Button
            variant="contained"
            startIcon={<OpenInNew />}
            onClick={() => onOpenEvent(event.id)}
            fullWidth
            sx={{
              bgcolor: '#D4AF37',
              color: 'black',
              mt: 'auto',
              '&:hover': {
                bgcolor: '#FFD700',
              },
            }}
          >
            Open Event
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default EventCard;
