import Header from '../../../../components/header';
import { Box, Container } from '@mui/material';
import { AppDispatch } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchEventApprovals,
  selectEvents,
} from '../../../../store/slices/eventSlice';
import { EventApprovalsCard } from './EventApprovalsCard';
import Grid from '@mui/material/Grid';
import { EventStatusEnum } from '../../../../types/event';

const EventApprovalsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector(selectEvents);

  useEffect(() => {
    dispatch(fetchEventApprovals());
  }, [dispatch]);

  return (
    <Box>
      <Header
        title="Event Approvals"
        subtitle="Review and manage event approval requests"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box>
          <Grid container spacing={2}>
            {events
              .filter((event) => event.status === EventStatusEnum.AwaitingApproval)
              .map((event, index) => (
                <Grid item key={event.id} xs={12} sm={6} md={4} lg={3}>
                  <EventApprovalsCard event={event} index={index} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default EventApprovalsPage;
