import { useSearchParams } from 'react-router-dom';

export interface MessagePageParams {
  mode?: 'newChat' | 'view' | 'new-event-chat';
  companyId?: string;
  companyName?: string;
  listingId?: string;
  listingName?: string;
  isCompany?: boolean;
  customerId?: string;
  eventId?: string;
}

export function useMessagePageParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: MessagePageParams = {
    mode:
      (searchParams.get('mode') as 'newChat' | 'view' | 'new-event-chat') ||
      undefined,
    companyId: searchParams.get('companyId') || undefined,
    companyName: searchParams.get('companyName') || undefined,
    listingId: searchParams.get('listingId') || undefined,
    listingName: searchParams.get('listingName') || undefined,
    isCompany: searchParams.get('isCompany') === 'true',
    customerId: searchParams.get('customerId') || undefined,
    eventId: searchParams.get('eventId') || undefined,
  };

  const clearParams = () => {
    setSearchParams({});
  };

  return {
    params,
    clearParams,
    setSearchParams,
  };
}
