import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NewEventStepProps } from '../index.tsx';
import { Region } from '../../../../types/locations.ts';
import {
  fetchLocations,
  selectRegions,
} from '../../../../store/slices/locationSlice';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { AppDispatch } from '../../../../store/store.ts';

export interface LocationConfiguratorStepProps extends NewEventStepProps {
  callback: (locations: Region[]) => void;
  selectedLocations?: Region[];
}

const LocationConfiguratorStep: FC<LocationConfiguratorStepProps> = ({
  handleNext,
  handleBack,
  callback,
  selectedLocations,
}: LocationConfiguratorStepProps) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const regions = useSelector(selectRegions);
  const [locations, setLocations] = React.useState<Region[]>([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeRegion, setActiveRegion] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      console.log('Initializing locations with:', {
        regions,
        selectedLocations,
      });

      const initializedLocations = regions.map((region) => {
        const savedRegion = selectedLocations?.find(
          (saved) => saved.id === region.id
        );

        return {
          ...region,
          isSelected: savedRegion?.isSelected || false,
          counties: region.counties.map((county) => {
            const savedCounty = savedRegion?.counties?.find(
              (c) => c.id === county.id
            );
            return {
              ...county,
              isSelected: savedCounty?.isSelected || false,
            };
          }),
        };
      });

      console.log('Initialized locations:', initializedLocations);
      setLocations(initializedLocations);
    }
  }, [regions, selectedLocations]);

  useEffect(() => {
    const hasSelections = locations.some(
      (location) =>
        location.isSelected ||
        location.counties.some((county) => county.isSelected)
    );
    setIsNextButtonDisabled(!hasSelections);
  }, [locations]);

  const handleChipClick = (
    event: React.MouseEvent<HTMLDivElement>,
    regionId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveRegion(regionId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setActiveRegion(null);
  };

  const handleLocationSelect = (regionId: string, countyId?: string) => {
    console.log('Selecting location:', { regionId, countyId });

    const updatedLocations = locations.map((region) => {
      if (region.id === regionId) {
        if (countyId) {
          const updatedCounties = region.counties.map((county) => ({
            ...county,
            isSelected:
              county.id === countyId ? !county.isSelected : county.isSelected,
          }));

          const anyCountySelected = updatedCounties.some((c) => c.isSelected);

          return {
            ...region,
            counties: updatedCounties,
            isSelected: anyCountySelected,
          };
        } else {
          const newIsSelected = !region.isSelected;
          return {
            ...region,
            isSelected: newIsSelected,
            counties: region.counties.map((county) => ({
              ...county,
              isSelected: newIsSelected,
            })),
          };
        }
      }
      return region;
    });

    console.log('Updated locations:', updatedLocations);
    setLocations(updatedLocations);

    // Save all locations with their selection state
    const locationsToSave = updatedLocations.map((region) => ({
      ...region,
      counties: region.counties,
    }));

    console.log('Locations for callback:', locationsToSave);
    callback(locationsToSave);
    
    // Check if any locations or counties are selected for the next button
    const hasSelections = updatedLocations.some(
      (region) =>
        region.isSelected ||
        region.counties.some((county) => county.isSelected)
    );
    setIsNextButtonDisabled(!hasSelections);
  };

  const clearAllSelections = useCallback(() => {
    const updatedLocations = locations.map((region) => ({
      ...region,
      isSelected: false,
      counties: region.counties.map((county) => ({
        ...county,
        isSelected: false,
      })),
    }));
    
    setLocations(updatedLocations);
    callback(updatedLocations);
    setIsNextButtonDisabled(true);
  }, [locations, callback]);

  const getSelectedCount = useCallback(() => {
    return locations.reduce((count, region) => {
      const selectedCounties = region.counties.filter(
        (c) => c.isSelected
      ).length;
      return (
        count + (region.isSelected ? region.counties.length : selectedCounties)
      );
    }, 0);
  }, [locations]);

  const filteredLocations = locations.filter((region) => {
    const matchesRegion = region.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCounty = region.counties.some((county) =>
      county.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return matchesRegion || matchesCounty;
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Select locations for your event
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Choose the regions and/or counties where your event will be available.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Accordion
          defaultExpanded={true}
          sx={{
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: 56,
              color: theme.palette.primary.main,
              '&.Mui-expanded': {
                minHeight: 56,
              },
            },
            '& .MuiAccordionSummary-content': {
              margin: '12px 0',
              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px 16px',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />}
            sx={{
              flexDirection: 'row',
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              },
            }}
          >
            <LocationOnIcon sx={{ color: theme.palette.primary.main }} />
            <Typography>Locations</Typography>
            {getSelectedCount() > 0 && (
              <Button
                startIcon={<ClearAllIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  clearAllSelections();
                }}
                size="small"
                sx={{
                  ml: 'auto',
                  color: theme.palette.primary.main,
                  '&:hover': { color: theme.palette.primary.dark },
                }}
              >
                Clear All
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              size="small"
              placeholder="Search locations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.primary.main }} />
                  </InputAdornment>
                ),
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />

            <Grid container spacing={2}>
              {filteredLocations.map((region) => {
                const selectedCounties = region.counties.filter(
                  (county) => county.isSelected
                );

                return (
                  <Grid item xs={12} md={6} lg={4} key={region.id}>
                    <Box
                      sx={{
                        mb: { xs: 1, md: 0 },
                        backgroundColor: `${theme.palette.primary.main}10`,
                        borderRadius: 1,
                        p: 1,
                        height: '100%',
                      }}
                    >
                      <Box
                        onClick={(e) => handleChipClick(e, region.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Chip
                          icon={<LocationOnIcon />}
                          deleteIcon={<ExpandMoreIcon />}
                          onDelete={() => {}} // Required for the delete icon to show
                          label={`${region.name} ${
                            region.isSelected
                              ? '(All counties)'
                              : selectedCounties.length > 0
                                ? `(${selectedCounties.length} counties)`
                                : ''
                          }`}
                          variant={region.isSelected ? 'filled' : 'outlined'}
                          sx={{
                            borderColor: theme.palette.primary.main,
                            borderWidth: '1.5px',
                            backgroundColor: region.isSelected
                              ? theme.palette.primary.main
                              : 'transparent',
                            height: '32px',
                            fontWeight: 500,
                            width: '100%',
                            '& .MuiSvgIcon-root': {
                              color: region.isSelected ? theme.palette.primary.contrastText : theme.palette.primary.main,
                            },
                            '&:hover': {
                              backgroundColor: region.isSelected
                                ? theme.palette.primary.dark
                                : `${theme.palette.primary.main}20`,
                              borderColor: region.isSelected
                                ? theme.palette.primary.dark
                                : theme.palette.primary.main,
                            },
                          }}
                        />
                      </Box>
                      <Popover
                        open={Boolean(anchorEl) && activeRegion === region.id}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          sx: {
                            mt: 1,
                            p: 2,
                            maxWidth: '300px',
                            backgroundColor: theme.palette.background.paper,
                            border: '1px solid',
                            borderColor: theme.palette.divider,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                            {region.name} Counties:
                          </Typography>
                          <Button
                            size="small"
                            onClick={() => handleLocationSelect(region.id)}
                            sx={{
                              fontSize: '0.75rem',
                            }}
                          >
                            {region.isSelected ? 'Deselect All' : 'Select All'}
                          </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {region.counties.map((county) => (
                            <Chip
                              key={county.id}
                              label={county.name}
                              onClick={() => handleLocationSelect(region.id, county.id)}
                              variant={county.isSelected ? 'filled' : 'outlined'}
                              size="small"
                              color={county.isSelected ? 'primary' : 'default'}
                              sx={{
                                height: '24px',
                                fontSize: '0.75rem',
                              }}
                            />
                          ))}
                        </Box>
                      </Popover>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button onClick={handleBack} variant="outlined">
          Back
        </Button>
        <Button
          onClick={handleNext}
          variant="contained"
          disabled={isNextButtonDisabled}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default LocationConfiguratorStep;
