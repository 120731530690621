import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectListings } from '../../../store/slices/listingSlice';
import { useNavigate } from 'react-router-dom';
import { ImageView } from '../../../components/inputs/ImageView';

interface MinimalListingProps {
  listingId: string;
  isOwnMessage: boolean;
}

const MinimalListing: React.FC<MinimalListingProps> = ({ listingId, isOwnMessage }) => {
  const listings = useSelector(selectListings);
  const navigate = useNavigate();

  const listing = listings.find((l) => l.id === listingId);
  if (!listing?.cardImageUri) return null;

  const handleClick = () => {
    navigate(`/listing/${listingId}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        width: 180,
        display: 'flex',
        flexDirection: 'column',
        background: isOwnMessage ? '#D4AF37' : 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(8px)',
        borderRadius: 2,
        border: '1px solid',
        borderColor: isOwnMessage ? 'rgba(0, 0, 0, 0.1)' : 'rgba(212, 175, 55, 0.1)',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(212, 175, 55, 0.2)',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          [isOwnMessage ? 'right' : 'left']: -6,
          bottom: 8,
          width: 0,
          height: 0,
          borderTop: '6px solid transparent',
          borderBottom: '6px solid transparent',
          ...(isOwnMessage
            ? { borderLeft: '6px solid #D4AF37' }
            : { borderRight: '6px solid rgba(255, 255, 255, 0.1)' }),
        },
      }}
    >
      <Box sx={{ position: 'relative', height: '120px' }}>
        <ImageView
          fileId={listing.cardImageUri}
          alt={listing.name}
          width={180}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          sx={{
            transition: 'all 0.3s ease',
            filter: 'brightness(0.9)',
            '&:hover': {
              filter: 'brightness(1.1)',
            },
          }}
        />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          background: isOwnMessage 
            ? 'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1))'
            : 'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))',
          p: 1,
          '&:last-child': { pb: 1 },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: isOwnMessage ? '#000' : '#D4AF37',
            fontSize: '0.9rem',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {listing.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MinimalListing;
