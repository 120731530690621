// src/services/listingStatisticsService.ts
import axiosInstance from '../utilis/axios';
import { apiUrl } from '../main';
import { getDeviceId } from '../utilis/deviceId';

// Types for statistics events
export type StatisticsEventType = 
  | 'view' 
  | 'favorite' 
  | 'linkOpen' 
  | 'chatStart'
  | 'phoneClick'
  | 'eventHit';

// Response types
export interface DailyStatistic {
  date: string;
  count: number;
}

export interface ListingStatisticsResponse {
  listingId: string;
  listingName?: string; // Added for company-wide statistics
  viewCount: number;
  favoriteCount: number;
  linkOpenCount: number;
  chatStartCount: number;
  phoneClickCount: number;
  eventHitCount: number;
  dailyViews: DailyStatistic[];
  dailyFavorites: DailyStatistic[];
  dailyLinkOpens: DailyStatistic[];
  dailyChats: DailyStatistic[];
  dailyPhoneClicks: DailyStatistic[];
  dailyEventHits: DailyStatistic[];
}

export interface CompanyListingStatisticsResponse {
  companyId: string;
  totalViews: number;
  totalFavorites: number;
  totalLinkOpens: number;
  totalChats: number;
  totalPhoneClicks: number;
  totalEventHits: number;
  listingStatistics: ListingStatisticsResponse[];
}

export interface UniqueViewsStats {
  uniqueViews: number;
  uniqueUsers: number;
  uniqueDevices: number;
}

// Request types
export interface RecordStatisticsEventRequest {
  listingId: string;
  eventType: StatisticsEventType;
  userId?: string; // Optional, can be null for anonymous events
  metadata?: Record<string, string>;
}

const listingStatisticsService = {
  /**
   * Record a statistics event for a listing
   */
  recordEvent: async (
    request: RecordStatisticsEventRequest
  ): Promise<boolean> => {
    try {
      // Convert eventType to lowercase to match backend expectations
      const normalizedRequest = {
        ...request,
        eventType: request.eventType.toLowerCase()
      };
      
      // Get device ID to identify anonymous users
      const deviceId = getDeviceId();
      
      // Automatically collect browser and device metadata
      const metadata = {
        ...(request.metadata || {}),
        // Anonymous user identification
        deviceId,
        // Browser information
        userAgent: navigator.userAgent,
        browser: getBrowserInfo(),
        // Device information
        screenWidth: window.screen.width.toString(),
        screenHeight: window.screen.height.toString(),
        deviceType: getDeviceType(),
        // Language and timezone
        language: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // Page info
        referrer: document.referrer || '',
        path: window.location.pathname,
      };

      // Send the request with enhanced metadata
      const response = await axiosInstance.post(
        `${apiUrl}/ListingStatistics/record`,
        {
          ...normalizedRequest,
          metadata
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error recording statistics event:', error);
      throw error;
    }
  },

  /**
   * Get statistics for a listing (default last 30 days)
   */
  getStatistics: async (
    listingId: string
  ): Promise<ListingStatisticsResponse> => {
    const response = await axiosInstance.get(
      `${apiUrl}/ListingStatistics/${listingId}`
    );
    return response.data;
  },

  /**
   * Get statistics for a listing with custom date range
   */
  getStatisticsRange: async (
    listingId: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<ListingStatisticsResponse> => {
    try {
      let url = `${apiUrl}/ListingStatistics/${listingId}/range`;
      
      // Add date parameters if provided
      const params: Record<string, string> = {};
      if (startDate) {
        params.startDate = startDate.toISOString();
      }
      if (endDate) {
        params.endDate = endDate.toISOString();
      }
      
      const response = await axiosInstance.get(url, { params });
      return response.data;
    } catch (error) {
      console.error('Error getting listing statistics with date range:', error);
      throw error;
    }
  },

  /**
   * Get unique views statistics for a listing, taking into account user ID, device ID, 
   * referrer, and a time window to avoid counting the same session multiple times
   */
  getUniqueViews: async (
    listingId: string,
    startDate?: Date,
    endDate?: Date,
    timeWindowMinutes: number = 10
  ): Promise<UniqueViewsStats> => {
    try {
      let url = `${apiUrl}/ListingStatistics/${listingId}/unique-views`;
      
      // Add parameters
      const params: Record<string, string> = {
        timeWindowMinutes: timeWindowMinutes.toString()
      };
      
      if (startDate) {
        params.startDate = startDate.toISOString();
      }
      if (endDate) {
        params.endDate = endDate.toISOString();
      }
      
      const response = await axiosInstance.get(url, { params });
      return response.data;
    } catch (error) {
      console.error('Error getting unique views statistics:', error);
      throw error;
    }
  },

  /**
   * Get statistics for all listings of a company
   * Note: This would need to be implemented on the backend.
   * For now, we'll fetch each listing's statistics separately
   */
  getCompanyStatistics: async (
    companyId: string,
    listingIds: string[]
  ): Promise<CompanyListingStatisticsResponse> => {
    // Fetch statistics for each listing
    const statisticsPromises = listingIds.map(id => 
      listingStatisticsService.getStatistics(id)
    );
    
    const listingStatistics = await Promise.all(statisticsPromises);
    
    // Calculate totals
    const totalViews = listingStatistics.reduce((sum, stat) => sum + stat.viewCount, 0);
    const totalFavorites = listingStatistics.reduce((sum, stat) => sum + stat.favoriteCount, 0);
    const totalLinkOpens = listingStatistics.reduce((sum, stat) => sum + stat.linkOpenCount, 0);
    const totalChats = listingStatistics.reduce((sum, stat) => sum + stat.chatStartCount, 0);
    const totalPhoneClicks = listingStatistics.reduce((sum, stat) => sum + stat.phoneClickCount, 0);
    const totalEventHits = listingStatistics.reduce((sum, stat) => sum + stat.eventHitCount, 0);
    
    return {
      companyId,
      totalViews,
      totalFavorites,
      totalLinkOpens,
      totalChats,
      totalPhoneClicks,
      totalEventHits,
      listingStatistics
    };
  },

  /**
   * Get statistics for all listings of a company with date range filtering
   */
  getCompanyStatisticsWithDateRange: async (
    companyId: string,
    listingIds: string[],
    startDate?: Date,
    endDate?: Date
  ): Promise<CompanyListingStatisticsResponse> => {
    // Fetch statistics for each listing with date range
    const statisticsPromises = listingIds.map(id => 
      listingStatisticsService.getStatisticsRange(id, startDate, endDate)
    );
    
    const listingStatistics = await Promise.all(statisticsPromises);
    
    // Calculate totals
    const totalViews = listingStatistics.reduce((sum, stat) => sum + stat.viewCount, 0);
    const totalFavorites = listingStatistics.reduce((sum, stat) => sum + stat.favoriteCount, 0);
    const totalLinkOpens = listingStatistics.reduce((sum, stat) => sum + stat.linkOpenCount, 0);
    const totalChats = listingStatistics.reduce((sum, stat) => sum + stat.chatStartCount, 0);
    const totalPhoneClicks = listingStatistics.reduce((sum, stat) => sum + stat.phoneClickCount, 0);
    const totalEventHits = listingStatistics.reduce((sum, stat) => sum + stat.eventHitCount, 0);
    
    return {
      companyId,
      totalViews,
      totalFavorites,
      totalLinkOpens,
      totalChats,
      totalPhoneClicks,
      totalEventHits,
      listingStatistics
    };
  },
};

/**
 * Helper function to determine browser type and version
 */
function getBrowserInfo(): string {
  const userAgent = navigator.userAgent;
  let browser = "Unknown";
  
  // Check for common browsers
  if (userAgent.indexOf("Firefox") > -1) {
    browser = "Firefox";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browser = "Samsung Browser";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browser = "Opera";
  } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) {
    browser = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browser = "Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browser = "Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    browser = "Safari";
  }
  
  return browser;
}

/**
 * Helper function to determine device type
 */
function getDeviceType(): string {
  const userAgent = navigator.userAgent.toLowerCase();
  
  if (/ipad|tablet|playbook|silk/.test(userAgent)) {
    return "Tablet";
  } else if (/mobile|iphone|ipod|android|blackberry|opera mini|iemobile/.test(userAgent)) {
    return "Mobile";
  } else {
    return "Desktop";
  }
}

export default listingStatisticsService;
