import { AxiosError } from 'axios';
import axiosInstance from '../utilis/axios';
import { PlannerEvent } from '../types/event';

const plannerApi = '/PlannerEvent';
const approvalApi = '/approval';

const handleApiError = (error: unknown) => {
  if (error instanceof AxiosError) {
    const errorMessage = error.response?.data?.message || error.message;
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: errorMessage,
    });
    throw new Error(`API Error: ${errorMessage}`);
  }
  throw error;
};

export interface ApproveEventRequest {
  eventId: string;
  approved: boolean;
  rejectionMessage?: string;
}

export interface ApprovalRequest {
  id: string;
  approved: boolean;
  type: number;
  rejectionMessage?: string;
}

export const eventService = {
  getEvents: async () => {
    try {
      console.log('Fetching events from:', plannerApi);
      const response = await axiosInstance.get<PlannerEvent[]>(plannerApi);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  getEventById: async (id: string) => {
    try {
      console.log(
        'Getting event by id:',
        id,
        'from URL:',
        `${plannerApi}/${id}`
      );
      const response = await axiosInstance.get<PlannerEvent>(
        `${plannerApi}/${id}`
      );
      console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  createEvent: async (event: PlannerEvent) => {
    try {
      const response = await axiosInstance.post<PlannerEvent>(
        plannerApi,
        event
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  updateEvent: async (event: PlannerEvent) => {
    try {
      const response = await axiosInstance.put<PlannerEvent>(
        `${plannerApi}/${event.id}`,
        event
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  deleteEvent: async (id: string) => {
    try {
      await axiosInstance.delete(`${plannerApi}/${id}`);
    } catch (error) {
      return handleApiError(error);
    }
  },

  getEventApprovals: async () => {
    try {
      const response = await axiosInstance.get<PlannerEvent[]>(
        approvalApi + '/300'
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  approveEvent: async (
    request: Omit<ApprovalRequest, 'type'>
  ): Promise<void> => {
    try {
      await axiosInstance.post(approvalApi, {
        ...request,
        type: 300, // ApprovalType.Event = 300
      });
    } catch (error) {
      return handleApiError(error);
    }
  },

  getEventsByCompanyId: async (companyId: string) => {
    try {
      const response = await axiosInstance.get<PlannerEvent[]>(
        `${plannerApi}/company/${companyId}`
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};
