import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDate } from '../../../utils/date.ts';
import { FC, useState } from 'react';
import { EventStatusEnum, PlannerEvent } from '../../../types/event.ts';
import {
  AccountBalance,
  CalendarMonth,
  Delete,
  Edit,
  People,
} from '@mui/icons-material';
import { CompactLocationDisplay } from '../../../components/filters/CompactLocationDisplay';
import { eventTypes } from '../event/components/EventTypePicker';

interface EventPlanCardProps {
  event: PlannerEvent;
  handleEditEvent: (eventId: string) => void;
  handleCancelEvent?: (eventId: string) => void;
  handleDeleteEvent?: (eventId: string) => void;
}

const getStatusLabel = (status: EventStatusEnum) => {
  switch (status) {
    case EventStatusEnum.Draft:
      return 'Draft';
    case EventStatusEnum.AwaitingApproval:
      return 'Pending Approval';
    case EventStatusEnum.Approved:
      return 'Approved';
    case EventStatusEnum.Rejected:
      return 'Rejected';
    default:
      return 'Unknown';
  }
};

const EventPlanCard: FC<EventPlanCardProps> = ({
  event,
  handleEditEvent,
  handleCancelEvent,
  handleDeleteEvent,
}) => {
  const theme = useTheme();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const handleEditClick = () => {
    if (event.status === EventStatusEnum.Approved) {
      setEditDialogOpen(true);
    } else {
      handleEditEvent(event.id);
    }
  };

  const handleConfirmEdit = () => {
    setEditDialogOpen(false);
    handleEditEvent(event.id);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteDialogOpen(false);
    handleDeleteEvent?.(event.id);
  };

  const handleCancelClick = () => {
    setCancelDialogOpen(true);
  };

  const handleConfirmCancel = () => {
    setCancelDialogOpen(false);
    handleCancelEvent?.(event.id);
  };

  const showDeleteButton =
    handleDeleteEvent &&
    (event.status === EventStatusEnum.Draft ||
      event.status === EventStatusEnum.Rejected);

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '600px',
          bgcolor: 'background.paper',
          borderRadius: 2,
          overflow: 'hidden',
          border: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.3)',
          transition: 'all 0.3s ease-in-out',
          animation: 'fadeIn 0.8s ease-out',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(20px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
          '&:hover': {
            transform: 'translateY(-4px)',
            borderColor: 'rgba(212, 175, 55, 0.6)',
            boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
          },
          background: 'rgba(0, 0, 0, 0.85)',
          backdropFilter: 'blur(8px)',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '220px',
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          <img
            src={
              eventTypes.find((type) => type.type === event.eventType)?.image
            }
            alt={event.eventType.toString()}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        <CardContent
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(600px - 220px)',
            overflow: 'hidden',
          }}
        >
          <Stack spacing={2} sx={{ height: '100%' }}>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#FFD700',
                    fontWeight: 600,
                    fontSize: '1.1rem',
                  }}
                >
                  {event.eventConfig.eventName || 'Untitled Event'}
                </Typography>
                <Chip
                  label={getStatusLabel(event.status)}
                  size="small"
                  variant="outlined"
                  sx={{
                    borderColor:
                      getStatusLabel(event.status) === 'Approved'
                        ? '#4CAF50'
                        : '#FFB74D',
                    borderWidth: 1.5,
                    color:
                      getStatusLabel(event.status) === 'Approved'
                        ? '#4CAF50'
                        : '#FFB74D',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor:
                        getStatusLabel(event.status) === 'Approved'
                          ? 'rgba(76, 175, 80, 0.08)'
                          : 'rgba(255, 183, 77, 0.08)',
                    },
                  }}
                />
              </Stack>

              {event.eventCategories && event.eventCategories.length > 0 && (
                <Box
                  sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}
                >
                  {event.eventCategories.map((category) => (
                    <Chip
                      key={category.id}
                      label={category.name}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderColor: '#D4AF37',
                        borderWidth: 1,
                        color: '#D4AF37',
                        fontSize: '0.75rem',
                        height: '20px',
                        '&:hover': {
                          backgroundColor: 'rgba(212, 175, 55, 0.08)',
                        },
                      }}
                    />
                  ))}
                </Box>
              )}
            </Box>

            <Box sx={{ overflow: 'auto', flex: 1, mb: 2 }}>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CalendarMonth sx={{ color: '#FFD700', fontSize: 20 }} />
                  <Typography variant="body2">
                    {formatDate(event.eventConfig.startDate)}
                    {event.eventConfig.endDate &&
                      ` - ${formatDate(event.eventConfig.endDate)}`}
                  </Typography>
                </Stack>

                {event.eventConfig.numberOfAttendees && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <People sx={{ color: '#FFD700', fontSize: 20 }} />
                    <Typography variant="body2">
                      {event.eventConfig.numberOfAttendees} attendees
                    </Typography>
                  </Stack>
                )}

                {event.eventConfig.budget && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AccountBalance sx={{ color: '#FFD700', fontSize: 20 }} />
                    <Typography variant="body2">
                      Budget: {event.eventConfig.budget}
                    </Typography>
                  </Stack>
                )}

                {event.eventLocations && event.eventLocations.length > 0 && (
                  <Box>
                    <CompactLocationDisplay locations={event.eventLocations} />
                  </Box>
                )}
              </Stack>
            </Box>

            <Stack direction="row" spacing={1} sx={{ mt: 'auto' }}>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                onClick={handleEditClick}
                size="small"
                sx={{
                  borderColor: '#D4AF37',
                  color: '#D4AF37',
                  '&:hover': {
                    borderColor: '#FFD700',
                    backgroundColor: 'rgba(212, 175, 55, 0.08)',
                  },
                }}
              >
                Edit Event
              </Button>
              {handleCancelEvent &&
                event.status !== EventStatusEnum.Draft &&
                event.status !== EventStatusEnum.Rejected && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelClick}
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(211, 47, 47, 0.08)',
                      },
                    }}
                  >
                    Cancel Event
                  </Button>
                )}
              {showDeleteButton && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                  onClick={handleDeleteClick}
                  size="small"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(211, 47, 47, 0.08)',
                    },
                  }}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {/* Confirmation Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'rgba(212, 175, 55, 0.3)',
            maxWidth: '400px',
          },
        }}
      >
        <DialogTitle sx={{ color: '#D4AF37' }}>
          Edit Approved Event?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            This event is currently approved. If you make any changes, it will
            need to go through the approval process again.
          </Typography>
          <Typography variant="body2" color="warning.main">
            The event status will be changed to "Awaiting Approval" after
            editing.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={() => setEditDialogOpen(false)}
            variant="outlined"
            sx={{
              color: '#D4AF37',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              '&:hover': {
                borderColor: '#D4AF37',
                backgroundColor: 'rgba(212, 175, 55, 0.08)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmEdit}
            variant="contained"
            sx={{
              bgcolor: '#D4AF37',
              color: 'black',
              '&:hover': {
                bgcolor: '#FFD700',
              },
            }}
          >
            Continue Editing
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Confirmation Dialog */}
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'rgba(211, 47, 47, 0.3)',
            maxWidth: '400px',
          },
        }}
      >
        <DialogTitle sx={{ color: 'error.main' }}>Cancel Event?</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to cancel this event? This action will mark the
            event as cancelled.
          </Typography>
          <Typography variant="body2" color="error">
            You cannot undo this action without going through the approval
            process again.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={() => setCancelDialogOpen(false)}
            variant="outlined"
            sx={{
              color: '#D4AF37',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              '&:hover': {
                borderColor: '#D4AF37',
                backgroundColor: 'rgba(212, 175, 55, 0.08)',
              },
            }}
          >
            Keep Event
          </Button>
          <Button
            onClick={handleConfirmCancel}
            variant="contained"
            color="error"
            sx={{
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}
          >
            Cancel Event
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'rgba(211, 47, 47, 0.3)',
            maxWidth: '400px',
          },
        }}
      >
        <DialogTitle sx={{ color: 'error.main' }}>Delete Event?</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this event? This action cannot be
            undone.
          </Typography>
          <Typography variant="body2" color="error">
            All event data will be permanently removed.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
            sx={{
              color: '#D4AF37',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              '&:hover': {
                borderColor: '#D4AF37',
                backgroundColor: 'rgba(212, 175, 55, 0.08)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}
          >
            Delete Event
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventPlanCard;
