import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { County, Region } from '../../types/locations';
import React, { FC, useCallback, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormGroup,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const FilterIcon = <LocationOnIcon />;

interface LocationFilterSectionProps {
  selectedLocations: Region[];
  onLocationSelect: (region: Region, county?: County) => void;
  isCollapsed?: boolean;
}

const LocationFilterSection: FC<LocationFilterSectionProps> = ({
  selectedLocations,
  onLocationSelect,
  isCollapsed = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeRegion, setActiveRegion] = useState<string | null>(null);

  const handleChipClick = (
    event: React.MouseEvent<HTMLDivElement>,
    regionId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveRegion(regionId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setActiveRegion(null);
  };

  const handleRegionSelect = useCallback(
    (selectedRegion: Region) => {
      const isCurrentlySelected =
        selectedRegion.isSelected ||
        selectedLocations.some(
          (loc) => loc.id === selectedRegion.id && loc.isSelected
        );
      const updatedRegion = { ...selectedRegion };
      updatedRegion.isSelected = !isCurrentlySelected;
      updatedRegion.counties = selectedRegion.counties.map((county) => ({
        ...county,
        isSelected: !isCurrentlySelected,
      }));
      onLocationSelect(updatedRegion);
    },
    [onLocationSelect, selectedLocations]
  );

  const handleCountySelect = useCallback(
    (selectedRegion: Region, county: County) => {
      const isCurrentlySelected =
        county.isSelected ||
        selectedLocations.some(
          (loc) =>
            loc.id === selectedRegion.id &&
            loc.counties?.some((c) => c.id === county.id && c.isSelected)
        );

      const updatedRegion = { ...selectedRegion };
      const updatedCounties = updatedRegion.counties.map((c) =>
        c.id === county.id
          ? { ...c, isSelected: !isCurrentlySelected }
          : { ...c }
      );

      updatedRegion.counties = updatedCounties;
      updatedRegion.isSelected = updatedCounties.every((c) => c.isSelected);

      onLocationSelect(updatedRegion, {
        ...county,
        isSelected: !isCurrentlySelected,
      });
    },
    [onLocationSelect, selectedLocations]
  );

  const clearAllSelections = () => {
    selectedLocations.forEach((region) => {
      if (region.isSelected || region.counties.some((c) => c.isSelected)) {
        const clearedRegion = { ...region, isSelected: false };
        clearedRegion.counties = region.counties.map((c) => ({
          ...c,
          isSelected: false,
        }));
        onLocationSelect(clearedRegion);
      }
    });
  };

  const getSelectedCount = () => {
    return selectedLocations.reduce((count, region) => {
      const selectedCounties = region.counties.filter(
        (c) => c.isSelected
      ).length;
      return (
        count + (region.isSelected ? region.counties.length : selectedCounties)
      );
    }, 0);
  };

  const filteredLocations = selectedLocations.filter((region) => {
    const matchesRegion = region.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCounty = region.counties.some((county) =>
      county.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return matchesRegion || matchesCounty;
  });

  if (isCollapsed) {
    return (
      <Tooltip title="Locations" placement="right">
        <IconButton
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            '&:hover': {
              color: '#D4AF37',
            },
          }}
        >
          <LocationOnIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Accordion
        defaultExpanded={false}
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 56,
            color: '#D4AF37',
            '&.Mui-expanded': {
              minHeight: 56,
            },
          },
          '& .MuiAccordionSummary-content': {
            margin: '12px 0',
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '8px 16px 16px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#D4AF37' }} />}
          sx={{
            flexDirection: 'row',
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            },
          }}
        >
          <LocationOnIcon sx={{ color: '#D4AF37' }} />
          <Typography>Locations</Typography>
          {getSelectedCount() > 0 && (
            <Button
              startIcon={<ClearAllIcon />}
              onClick={(e) => {
                e.stopPropagation();
                clearAllSelections();
              }}
              size="small"
              sx={{
                ml: 'auto',
                color: 'rgba(212, 175, 55, 0.8)',
                '&:hover': { color: '#D4AF37' },
              }}
            >
              Clear All
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            size="small"
            placeholder="Search locations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'rgba(212, 175, 55, 0.8)' }} />
                </InputAdornment>
              ),
              sx: {
                color: '#D4AF37',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#D4AF37',
                },
              },
            }}
          />

          <FormGroup>
            {filteredLocations.map((region) => {
              const selectedCounties = region.counties.filter(
                (county) => county.isSelected
              );

              return (
                <Box
                  key={region.id}
                  sx={{
                    mb: 2,
                    backgroundColor: 'rgba(212, 175, 55, 0.05)',
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  <Box
                    onClick={(e) => handleChipClick(e, region.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Chip
                      icon={<LocationOnIcon />}
                      deleteIcon={<ExpandMoreIcon />}
                      onDelete={() => {}} // Required for the delete icon to show
                      label={`${region.name} ${
                        region.isSelected
                          ? '(All counties)'
                          : selectedCounties.length > 0
                            ? `(${selectedCounties.length} counties)`
                            : ''
                      }`}
                      variant={region.isSelected ? 'filled' : 'outlined'}
                      sx={{
                        borderColor: '#D4AF37',
                        borderWidth: '1.5px',
                        color: region.isSelected ? '#000000' : '#D4AF37',
                        backgroundColor: region.isSelected
                          ? '#D4AF37'
                          : 'transparent',
                        height: '32px',
                        fontWeight: 500,
                        width: '100%',
                        '& .MuiSvgIcon-root': {
                          color: region.isSelected ? '#000000' : '#D4AF37',
                        },
                        '&:hover': {
                          backgroundColor: region.isSelected
                            ? '#B49030'
                            : 'rgba(212, 175, 55, 0.08)',
                          borderColor: region.isSelected
                            ? '#B49030'
                            : '#D4AF37',
                        },
                      }}
                    />
                  </Box>
                  <Popover
                    open={Boolean(anchorEl) && activeRegion === region.id}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      sx: {
                        mt: 1,
                        p: 2,
                        maxWidth: '300px',
                        backgroundColor: '#000000',
                        border: '1px solid',
                        borderColor: 'rgba(212, 175, 55, 0.3)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ color: '#D4AF37' }}>
                        {region.name} Counties:
                      </Typography>
                      <Button
                        size="small"
                        onClick={() => handleRegionSelect(region)}
                        sx={{
                          color: '#D4AF37',
                          fontSize: '0.75rem',
                          '&:hover': {
                            backgroundColor: 'rgba(212, 175, 55, 0.08)',
                          },
                        }}
                      >
                        {region.isSelected ? 'Deselect All' : 'Select All'}
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {region.counties.map((county) => (
                        <Chip
                          key={county.id}
                          label={county.name}
                          onClick={() => handleCountySelect(region, county)}
                          variant={county.isSelected ? 'filled' : 'outlined'}
                          size="small"
                          sx={{
                            borderColor: '#D4AF37',
                            borderWidth: '1px',
                            color: county.isSelected ? '#000000' : '#D4AF37',
                            backgroundColor: county.isSelected
                              ? '#D4AF37'
                              : 'transparent',
                            height: '24px',
                            fontSize: '0.75rem',
                            '&:hover': {
                              backgroundColor: county.isSelected
                                ? '#B49030'
                                : 'rgba(212, 175, 55, 0.08)',
                              borderColor: county.isSelected
                                ? '#B49030'
                                : '#D4AF37',
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Popover>
                </Box>
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LocationFilterSection;
