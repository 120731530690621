import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button,
  Container, 
  Grid, 
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import FavoriteButton from '../../components/favorites/FavoriteButton';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import ErrorMessage from '../../components/common/ErrorMessage';
import NoData from '../../components/common/NoData';
import { AppDispatch, RootState } from '../../store/store';
import { getFavorites, getFavoriteListings } from '../../store/slices/favoritesSlice';
import { ListingCard } from '../ListingsPage/ListingCard';
import Header from '../../components/header';

const FavoritesPage: React.FC = () => {
  useDocumentTitle('My Favorites');
  const dispatch = useDispatch<AppDispatch>();
  
  const { favoriteIds, favoriteListings, isLoading, error } = useSelector((state: RootState) => state.favorites);
  const { categories } = useSelector((state: RootState) => state.categories);
  const { subCategories } = useSelector((state: RootState) => state.subCategories);
  const { subCategoryServices } = useSelector((state: RootState) => state.subCategoryServices);
  
  const [localError, setLocalError] = useState<string | null>(null);

  useEffect(() => {
    const loadFavorites = async () => {
      try {
        // First, fetch the IDs of all favorites
        await dispatch(getFavorites());
      } catch (error) {
        setLocalError('Failed to load your favorites. Please try again.');
      }
    };

    loadFavorites();
  }, [dispatch]);

  useEffect(() => {
    // When we have favorite IDs, fetch the details for each listing
    if (favoriteIds.length > 0) {
      dispatch(getFavoriteListings(favoriteIds));
    }
  }, [favoriteIds, dispatch]);

  const handleRefresh = () => {
    dispatch(getFavorites());
  };

  if (isLoading && favoriteListings.length === 0) {
    return <LoadingSpinner />;
  }

  if (error || localError) {
    return (
      <ErrorMessage
        message={error || localError || 'An error occurred'}
        onRetry={handleRefresh}
      />
    );
  }

  return (
    <Box>
      <Header
        title="My Favorite Listings"
        showBackButton={true}
      />
      <Box
        sx={{
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
            opacity: 0.7,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background:
              'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
          },
        }}
      >
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {favoriteListings.length === 0 ? (
            <NoData
              title="You haven't added any listings to your favorites yet."
              actionButton={
                <Button
                  component={RouterLink}
                  to="/"
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'rgba(212, 175, 55, 0.8)',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#D4AF37',
                    },
                  }}
                >
                  Browse Listings
                </Button>
              }
            />
          ) : (
            <Grid container spacing={3}>
              {favoriteListings.map((listing) => (
                <Grid 
                  item 
                  key={listing.id} 
                  xs={12} 
                  sm={6} 
                  md={4}
                  sx={{ 
                    display: 'flex', 
                    position: 'relative' 
                  }}
                >
                  <Box sx={{ width: '100%', position: 'relative' }}>
                    <ListingCard 
                      listing={listing} 
                      categories={categories} 
                      subCategories={subCategories} 
                      subCategoryServices={subCategoryServices}
                    />
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: 10, 
                        right: 10, 
                        zIndex: 10 
                      }}
                    >
                      <FavoriteButton listingId={listing.id} />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default FavoritesPage;
